import React from 'react';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
function TermsOfUse({ data }) {
    const term = data['Terms Of Use'];
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className='page-container'>
      <div className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /
        <span className="mx-3 cursor-pointer text-sm font-medium text-[#000]">
          {formatMessage({ id: 'terms.use', defaultMessage: 'Terms Of Use' })}
        </span>
      </div>
      <h1 className="page-heading page-heading-margin-bottom">{data.termsofuseSectionTitle}</h1>

      <div className="container !p-0">
        {term.map((termsofuseSectionDescription, index) => (<div key={index} className='page-heading-margin-bottom'>
            <h3>{termsofuseSectionDescription.termsofuseSectionTitle}</h3>
            <p className="text-base">
              {termsofuseSectionDescription.termsofuseSectionDescription}
            </p>
          </div>))}
      </div>
    </div>);
}
export default TermsOfUse;
