import React, { useEffect } from 'react';
import router from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
function PaymentCancel({ data }) {
    const { formatMessage } = useFormat({ name: 'common' });
    useEffect(() => { }, []);
    const paymentCanecl = () => {
        router.push({
            pathname: '/',
        });
    };
    return (<div className="mt-20 text-center">
      <h1 className="text-5xl text-black">
        {formatMessage({ id: 'payment.cancel', defaultMessage: 'Sorry you have cancelled the Payment' })}
      </h1>
      <button className="mt-16 rounded bg-[#74ab7d] p-5 text-3xl text-white" onClick={paymentCanecl}>
        {formatMessage({ id: 'back.home', defaultMessage: 'Go Back To Home' })}
      </button>
    </div>);
}
export default PaymentCancel;
