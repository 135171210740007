import React from 'react';
import Link from 'next/link';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
export const EmptyState = ({ pageTitle, image, title, subtitle, callToAction, callToActionLink, }) => {
    return (<>
      {/* {pageTitle && (
          <h1
            className="dark:text-light-100 hidden pb-12 text-center text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            style={{ marginTop: '100px' }}
          >
            {pageTitle}
          </h1>
        )} */}

      <Image className='mx-auto lg:w-[580px]' src="/icons/custom/empty-cart.svg" alt='empty cart'/>

      {/* {title && (
          <div className="mt-4 text-center">
            <p className="text-3xl font-bold text-gray-600 dark:text-light-100">{title}</p>
          </div>
        )} */}
      <div className='mx-auto mt-15 lg:mt-24 w-fit'>
        {subtitle && (<h1 className="mb-5 lg:mb-10 w-auto text-center">{subtitle}</h1>)}

        {callToActionLink ? (
        // <div className="w-full text-right">
        <ReferenceLink className='flex w-full items-center justify-end gap-x-3' target={callToActionLink}>
            <Image src="/icons/custom/arrow-left-back.svg" alt=''/>
            <h4 className='underline'>{callToAction}</h4>
          </ReferenceLink>
        // </div>
        ) : (<Link href={'/'}>
            <div className='flex w-full cursor-pointer items-center justify-end gap-x-3'>
              <Image src="/icons/custom/arrow-left-back.svg" alt='back to homepage'/>
              <h4 className='underline hover:no-underline'>{callToAction}</h4>
            </div>
          </Link>)}
      </div>

    </>);
};
