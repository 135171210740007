import classNames from 'classnames';
import Checkbox from 'components/form/checkbox';
import InputForm from 'components/form/inputForm';
import SelectForm from 'components/form/selectForm';
import { useFormat } from 'helpers/hooks/useFormat';
import { ReferenceLink } from 'helpers/reference';
import { COUNTRY_CODE } from 'helpers/constants/envVariables';
const DeliveryAddress = ({ shippingAddressForm, availableCountries, isHomeAddress, dhlPackstationCTA, taxRegions }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { register, formState, setValue, getValues } = shippingAddressForm;
    return (<section className="rounded-box-container bg-gray-light-75">
      <div className="flex flex-wrap flex-col items-start justify-between gap-5 lg:flex-row lg:items-center">
        <h2>{formatCommonMessage({ id: 'delivery.address' })}</h2>
        {isHomeAddress ? (<div className="shrink-0">
            <Checkbox label={formatCommonMessage({
                id: 'default.shipping',
                defaultMessage: 'Set as Default Delivery Address',
            })} name="isDefaultShippingAddress" formRegister={register}/>
          </div>) : (<ReferenceLink className="h3 shrink-0 underline hover:no-underline" target={dhlPackstationCTA?.ctaLink}>
            {dhlPackstationCTA?.ctaLabel}
          </ReferenceLink>)}
      </div>
      <div className="form-wrapper lg:mt-15 mt-10">
        <div className="row-2-field">
          <InputForm label={formatCommonMessage({ id: 'Recipient.firstName', defaultMessage: 'First name' })} name={'firstName'} hookForm={shippingAddressForm} inputType="text" validationType={'required'}/>
          <InputForm label={formatCommonMessage({ id: 'Recipient.lastName', defaultMessage: 'Last Name' })} name={'lastName'} hookForm={shippingAddressForm} inputType="text" validationType={'required'}/>
        </div>
        <div className={classNames("row-2-field", {
            "!hidden": !isHomeAddress
        })}>
          <InputForm label={formatCommonMessage({ id: 'street.name', defaultMessage: 'Street' })} name={'shippingStreetName'} hookForm={shippingAddressForm} inputType="text" validationType={isHomeAddress ? 'streetName' : null}/>
          <InputForm label={formatCommonMessage({ id: 'street.number' })} name={'shippingStreetNumber'} hookForm={shippingAddressForm} inputType="text" validationType={isHomeAddress ? 'streetNumber' : null}/>
        </div>
        <div className={classNames({
            '!hidden': !isHomeAddress
        })}>
          <InputForm label={formatCommonMessage({ id: 'addresss.line' })} name={'shippingAdditionalStreetInfo'} hookForm={shippingAddressForm} inputType="text"/>
        </div>
        <div className={classNames("row-2-field", {
            "!hidden": isHomeAddress
        })}>
          <InputForm label={formatCommonMessage({ id: 'dhl.post.number' })} name={'dhlPostNumber'} hookForm={shippingAddressForm} inputType="text" validationType={isHomeAddress ? null : "dhlPostNumber"}/>
          <InputForm label={formatCommonMessage({ id: 'dhl.packing.station.number' })} name={'dhlPackstationNumber'} hookForm={shippingAddressForm} inputType="text" validationType={isHomeAddress ? null : "dhlPackstationNumber"}/>
        </div>
        <div className="row-2-field">
          <InputForm label={formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })} name={'shippingPostalCode'} hookForm={shippingAddressForm} inputType="text" validationType={'postalCode'}/>
          <InputForm label={formatCommonMessage({ id: 'city', defaultMessage: 'City' })} name={'shippingCity'} hookForm={shippingAddressForm} inputType="text" validationType={'city'}/>
        </div>
        <div className="row-2-field">
          {COUNTRY_CODE === 'ES' && taxRegions?.length && (<SelectForm label={formatCommonMessage({ id: 'tax.region', defaultMessage: 'Tax region' })} name={'shippingState'} 
        // formRegister={register}
        formSetValue={setValue} 
        // formState={formState}
        options={taxRegions} defaultValue={getValues('shippingState')} isCountry={false}/>)}
          {availableCountries && (<SelectForm label={formatCommonMessage({ id: 'country', defaultMessage: 'Country' })} name={'shippingCountry'} 
        // formRegister={register}
        formSetValue={setValue} 
        // formState={formState}
        options={availableCountries} defaultValue={getValues('shippingCountry')}/>)}

        </div>
      </div>
    </section>);
};
export default DeliveryAddress;
