import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import axios from 'axios';
import Loader from 'components/commercetools-ui/loader';
import { BEARERTOKEN_BASE_URL, BEARERTOKEN_PASSWORD, BEARERTOKEN_USERNAME } from 'helpers/constants/envVariables';
import { STATIC_ORDER_CANCELLATION, STATIC_ORDER_CANCELLATION_REASONS } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useCart } from 'frontastic';
function CancelOrder() {
    const [order, setAccountOrders] = useState();
    const [loading, setLoading] = useState(true);
    // const [cancelRequestSuccess, setCancelRequestSuccess] = useState<boolean>(false);
    const [cancelReasons, setCancelReasons] = useState(null);
    const [cancelReasonSelected, setCancelReasonSelected] = useState(null);
    const [accessToken, setAccessToken] = useState('');
    const router = useRouter();
    const orderId = router?.query?.['orderId'];
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { orderHistory } = useCart();
    useEffect(() => {
        if (orderHistory && orderId) {
            orderHistory().then((data) => {
                setAccountOrders(data.filter((el) => el.orderId == orderId)[0]);
            });
        }
    }, [orderHistory, orderId]);
    const cancelRequestSuccess = async () => {
        // setLoading(true);
        const reqBody = {
            orderId: order?.cartId,
            orderNumber: order?.orderId,
            orderVersion: order?.orderVersion,
            status: 'order-cancellation-requested',
            orderCancelReason: cancelReasonSelected,
        };
        try {
            const response = await backendAPIClient.post(STATIC_ORDER_CANCELLATION, reqBody);
            if (response.status >= 200 && response.status < 300) {
                const jsonResponse = await response.data;
                console.log('API Response:', jsonResponse);
                router.push({
                    pathname: '/cancel-success',
                    query: { orderId: orderId },
                });
            }
            else {
                console.log('API Error:', response.status);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    const getApi = async () => {
        try {
            const response = await commercetoolsAPIClient.get(STATIC_ORDER_CANCELLATION_REASONS, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response) {
                const cancelReasonsData = response.data;
                const cancelReasons = cancelReasonsData?.results[0]?.value['en-GB'];
                setCancelReasons(cancelReasons);
                setCancelReasonSelected(cancelReasons[Object.keys(cancelReasons)[0]]);
                setLoading(false);
            }
            else {
                setLoading(false);
                console.error('Failed to fetch cancel reasons:', response.status, response.statusText);
            }
        }
        catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };
    const getAccessToken = () => {
        axios
            .post(BEARERTOKEN_BASE_URL, {}, {
            auth: {
                username: BEARERTOKEN_USERNAME,
                password: BEARERTOKEN_PASSWORD,
            },
        })
            .then((response) => {
            setAccessToken(response.data['access_token']);
        });
    };
    useEffect(() => {
        getAccessToken();
    }, []);
    useEffect(() => {
        if (accessToken) {
            getApi();
        }
    }, [accessToken]);
    return (<>
      {loading && <Loader />}
      <main className="mx-auto max-w-2xl px-2 pb-24 sm:px-4 sm:pt-20 md:px-8 md:pt-8 lg:max-w-7xl">
        <div className="breadcrumb">
          <Link href="/">
            <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
              {formatMessage({ id: 'home', defaultMessage: 'Home' })}
            </span>
          </Link>
          /{' '}
          <Link href="/account#orders">
            <span className="mr-3 ml-2 cursor-pointer text-sm font-light text-gray-400">
              {formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' })}
            </span>
          </Link>
          /{' '}
          <span className="mr-3 ml-2 cursor-pointer text-sm font-medium text-[#000]">
            {formatMessage({ id: 'order.cancel', defaultMessage: 'Cancel Order' })}
          </span>
        </div>
        <h1 className="cancel-order-title">Cancel Order</h1>
        <div className="flex flex-col justify-center gap-14 md:flex-row md:gap-20">
          <div className="mt-4 flex-1 md:mt-0">
            <div className="mb-5 flex flex-col rounded border bg-[#E7EDE8] px-4 py-10 sm:mt-5 md:flex-row" key={order?.orderId}>
              {/* Left side (Image and Title) */}
              {order?.lineItems.map((product) => (<div className="flex flex-row " key={product.productId}>
                  <div className="flex shrink-0">
                    <img src={product.variant.images[0]} alt={product?.name} className="w-16 lg:w-28"/>
                  </div>
                  <div className="ml-5 mt-2">
                    <h2 className="text-lg font-semibold">{product?.name}</h2>
                    <p>
                      <span className="mr-1">{product.count}</span>
                      {formatProductMessage({
                id: 'unit',
                defaultMessage: 'Unit',
            })}
                      <span className="mx-1">|</span>
                      <span>
                        {formatProductMessage({
                id: 'order.date',
                defaultMessage: 'Order Date:',
            })}
                        <span className="ml-1">{order.createdAt.toString().substring(0, 10)} </span>
                      </span>
                    </p>
                  </div>
                </div>))}
            </div>
            <p className="order-cancel-policy">
              {formatMessage({
            id: 'policy.cancel',
            defaultMessage: 'Your refund will be credited to the actual payment method with in 7 working days, once the item reaches back to us.',
        })}
            </p>
            <div className="mt-2 flex justify-end">
              <button className="back-to-login-btn">
                <Link href={'/cancellation-policy'}>VIEW CANCELLATION POLICIES</Link>
              </button>
            </div>
          </div>
          {/* {!cancelRequestSuccess ? ( */}
          <div className="mt-5 flex-1">
            <label className="text-sm leading-tight text-black" htmlFor="shipping-country">
              <span>{formatMessage({ id: 'reason.cancel', defaultMessage: 'Select reason for cancellation' })}</span> *
            </label>
            <select className="mt-3 w-full appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none" onChange={(e) => setCancelReasonSelected(e.target.value)}>
              {cancelReasons &&
            Object.keys(cancelReasons).map((reason, index) => (<option key={index} className="text-sm" value={cancelReasons[reason]}>
                    {formatMessage({ id: reason, defaultMessage: cancelReasons[reason] })}
                  </option>))}
            </select>
            <button type="submit" onClick={() => cancelRequestSuccess()} className="login-submit focus:ring-accent-400 mt-6 flex w-full justify-center rounded-sm border border-transparent p-3 text-lg font-bold text-white shadow-sm transition-colors duration-200 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-200 md:mt-10">
              {formatMessage({ id: 'request.cancel', defaultMessage: 'Request Cancellation' })}
            </button>
          </div>
          {/* ) : ( */}
          {/* <div className="flex-1">
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatMessage({ id: 'refund.mode', defaultMessage: 'Refund Mode' })}
              </p>
              <p className="order-cancel-subheading">
                {formatMessage({ id: 'payment.mode', defaultMessage: 'Paypal' })}
              </p>
              <p className="order-cancel-subheading">XXXXX X5413</p>
            </div>
            <div className="divider"></div>
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatAccountMessage({ id: 'deliveryAddress', defaultMessage: 'Delivery Address' })}
              </p>
              <p className="order-cancel-subheading">
                {order?.shippingAddress?.firstName + ' ' + order?.shippingAddress?.lastName}
              </p>
              <p className="order-cancel-subheading">
                {order?.shippingAddress?.streetName}, {order?.shippingAddress?.additionalStreetInfo},{' '}
                {order?.shippingAddress?.city}, {order?.shippingAddress?.country}
              </p>
            </div>
            <div className="divider"></div>
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatAccountMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
              </p>
              <p className="order-cancel-subheading">
                {order?.billingAddress?.firstName + ' ' + order?.billingAddress?.lastName}
              </p>
              <p className="order-cancel-subheading">
                {order?.billingAddress?.streetName}, {order?.billingAddress?.additionalStreetInfo},{' '}
                {order?.billingAddress?.city}, {order?.billingAddress?.country}
              </p>
            </div>
            <div className="mt-2 flex justify-end">
              <button className="back-to-login-btn">
                <Link href="/account#orders">GO TO ORDERS</Link>
              </button>
            </div>
          </div>
        )} */}
        </div>
      </main>
    </>);
}
export default CancelOrder;
