import React from 'react';
import { useRouter } from 'next/router';
export const normalize = (content, locale) => {
    return (typeof content === 'string' ? content : content[locale]) ?? '';
};
const Typography = ({ children }) => {
    //next/router
    const router = useRouter();
    //locale
    const locale = router?.locale || router?.defaultLocale;
    //normalize content
    const normalized = children && normalize(children, locale);
    return <>{normalized}</>;
};
export default Typography;
