import React from 'react';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { getReferenceTarget, getTargetProps } from 'helpers/reference';
import PatientDetails from './patient-details';
import PrescriptionUpload from './prescription-upload';
const UploadPrescription = ({ tasticData, patientDetails, onSubmit, prescription, handleSetPrescription }) => {
    if (!patientDetails) {
        return null;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prescriptionUploadForm = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: patientDetails
    });
    const { handleSubmit, formState } = prescriptionUploadForm;
    const { isDirty } = formState;
    const submitPrescriptionData = (data) => {
        onSubmit(data, isDirty);
    };
    const prescriptionPageTarget = tasticData?.prescriptionRef ? getReferenceTarget(tasticData?.prescriptionRef) : null;
    const isPrescriptionTargetValid = prescriptionPageTarget !== '/' && prescriptionPageTarget;
    return (<>
      <div className="heading-wrapper flex flex-col items-center justify-start">
        <h1 className="checkout-heading">
          {tasticData?.prescriptionStepMainTitle}
        </h1>
        {isPrescriptionTargetValid && (<div className='mb-4 lg:mb-7 flex w-full items-center justify-start lg:text-xl'>
              <span>{tasticData?.question}</span>
              &nbsp;
              <span className='text-green-dark font-bold underline'>
                <Link href={prescriptionPageTarget ?? '/'} {...getTargetProps(tasticData?.prescriptionRef)}>{tasticData?.ctaLabel ?? ''}</Link>
              </span>
            </div>)}

      </div>
      <form className='container--prescription container-2-col container-2-col--6-6' onSubmit={handleSubmit(submitPrescriptionData)}>
        <div className='col col--left'>
          <PatientDetails patientDetailsTitle={tasticData?.patientDetailsTitle} orderForSomeoneElseLabel={tasticData?.orderForSomeoneElseLabel} countryPhoneCodes={tasticData?.countryCodes} prescriptionUploadForm={prescriptionUploadForm} legalReason={tasticData?.legalReason}/>
        </div>
        <div className='col col--right'>
          <PrescriptionUpload prescriptionUploadTitle={tasticData?.prescriptionUploadTitle} validationTitle={tasticData?.validationTitle} prescriptionRules={tasticData?.prescriptionRules} prescription={prescription} handleSetPrescription={handleSetPrescription}/>
        </div>
      </form>
    </>);
};
export default UploadPrescription;
