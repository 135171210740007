import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { MAXIMUM_ORDER_CAPACITY } from 'helpers/constants/cart';
import Image from 'frontastic/lib/image';
import Price from '../price';
import EstimatedDeliverDate from './estimatedDeliverDate';
import { useFormat } from 'helpers/hooks/useFormat';
import { LANGUAGE_MODAL_TRIGGER_ID } from 'helpers/constants/languageModal';
import useOutsideClick from 'helpers/hooks/useOutsideClick';
const Item = ({ lineItem, shippingSettings, goToProductPage, editItemQuantity, removeItem, packageDescription, isCartPage = true, shippingCountryMessage, shippingCountryTooltip }) => {
    const [count, setCount] = useState(lineItem.count);
    const [languageModalTrigger, setLanguageModalTrigger] = useState(null);
    const [openShippingTooltip, setOpenShippingTooltip] = useState(false);
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const shippingTooltipRef = useRef();
    useOutsideClick(shippingTooltipRef, () => {
        setOpenShippingTooltip(false);
    });
    // Debounced function for API call
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateApi = useCallback(debounce((newCount) => {
        editItemQuantity(lineItem?.lineItemId, newCount).then(() => {
            // Do something
        }).catch(() => {
            // Catch something
        });
    }, 600), []);
    const reduceTheNumberOfItems = () => {
        if (count === 1) {
            return;
        }
        const newCount = count - 1;
        setCount(newCount);
        debouncedUpdateApi(newCount);
    };
    const increaseTheNumberOfItems = () => {
        if (count === MAXIMUM_ORDER_CAPACITY) {
            return;
        }
        const newCount = count + 1;
        setCount(newCount);
        debouncedUpdateApi(newCount);
    };
    const removeItemWrapper = () => {
        removeItem(lineItem?.lineItemId).then(() => {
        }).catch(() => { });
    };
    const getLanguageModalTriggerButton = () => {
        const element = document.getElementById(LANGUAGE_MODAL_TRIGGER_ID);
        if (element) {
            setLanguageModalTrigger(element);
        }
        return element;
    };
    useEffect(() => {
        getLanguageModalTriggerButton;
    }, []);
    const showRegionLanguageModal = () => {
        if (languageModalTrigger) {
            languageModalTrigger.click();
            return;
        }
        const button = getLanguageModalTriggerButton();
        if (button) {
            button.click();
        }
    };
    const onClickShippingTooltip = () => {
        setOpenShippingTooltip(true);
    };
    return (<div className="cart-tile bg-green-light-50 rounded-box-container flex flex-col">
      <div className="flex flex-row items-start gap-4 lg:gap-8">
        <div className="aspect-product-image relative w-[31%] shrink-0 lg:w-[21%]">
          <Image src={lineItem.variant.images[0]} alt={lineItem.name} className="h-full w-full object-contain"/>
        </div>
        <div className="flex flex-auto flex-col gap-2 lg:gap-3">
          <div className="flex flex-row justify-between">
            <h2>{lineItem.name}</h2>
            <Price price={lineItem.price} className="h2"/>
          </div>
          <p className='text-gray-text text-xs lg:text-xl'>
            {lineItem.variant.attributes['long-description']}
          </p>
          <EstimatedDeliverDate {...shippingSettings}/>
          <div className='flex items-center gap-x-2'>
            <p className='text-gray-text text-xs lg:text-xl'>
              {shippingCountryMessage}
            </p>
            <div onClick={onClickShippingTooltip} className={classNames("tooltip", {
            "show": openShippingTooltip
        })}>
              <div className="tooltip-icon"></div>
              <div className="tooltip-content !z-tooltip-below-modal">
                <div ref={shippingTooltipRef}>
                  <div className='text-base text-green-dark font-medium'>{shippingCountryTooltip?.[0]?.tooltipLabel}</div>
                  <button className='mt-1 text-base text-green-dark font-medium underline hover:no-underline' type='button' onClick={showRegionLanguageModal}>{shippingCountryTooltip?.[0]?.tooltipCTALabel}</button>
                  <p className='mt-3'>
                    We also deliver to:
                    <br />Germany
                    <br />Spain
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isCartPage && (<div className="mt-4 lg:mt-6 flex flex-row items-center gap-3">
                <div className="bg-green-medium-27 flex h-full flex-row items-center gap-2 rounded-xl py-1 px-4 lg:gap-3">
                  <Image src="/icons/tailwind-icons/icon-minus-circle.svg" alt="minus" layout="fill" loading="lazy" className={classNames("h-4 w-4 cursor-pointer lg:h-5 lg:w-5 object-contain", {
                'opacity-25 cursor-default': lineItem?.count === 1 || count === 1
            })} onClick={reduceTheNumberOfItems}/>
                  <h4 className='w-6 text-center font-medium'>{count}</h4>
                  <Image src="/icons/tailwind-icons/icon-plus-circle.svg" alt="add" layout="fill" loading="lazy" className={classNames("h-4 w-4 cursor-pointer lg:h-5 lg:w-5 object-contain", {
                'opacity-25 cursor-default': lineItem?.count === MAXIMUM_ORDER_CAPACITY || count === MAXIMUM_ORDER_CAPACITY
            })} onClick={increaseTheNumberOfItems}/>
                </div>

                <span className="border-secondary-light h-9 lg:h-10 border-l border-solid"></span>

                <Image src="/icons/custom/trash-delete-bin.svg" alt="delete" layout="fill" loading="lazy" className="h-4 w-4 cursor-pointer object-contain lg:h-6 lg:w-6" onClick={removeItemWrapper}/>
              </div>)}

          {!isCartPage && (<div className="quantity-wrapper mt-4 lg:mt-6">
                <span>{formatCartMessage({ id: 'unit.total', defaultMessage: 'Unit' })}:</span>
                <span>{lineItem?.count}</span>
              </div>)}
        </div>
      </div>
    </div>);
};
export default Item;
