import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Cart from 'components/commercetools-ui/cart';
import Loader from 'components/commercetools-ui/loader';
import { getValidatedCheckoutStep } from 'helpers/checkoutStepHelpers';
import { CHECKOUT_STEP_PARAM, HOME } from 'helpers/constants/statisURLs';
import { extractCustomerPhoneNumber, shouldUpdateCustomerDetails } from 'helpers/customerInfoHelpers';
import { deletePrescription, getPrescriptionFromIDB, uploadPrescriptionToIDB } from 'helpers/idbHelpers';
import { isObjectEmpty } from 'helpers/objectHelpers';
import Redirect from 'helpers/redirect';
import { getShippingMethod } from 'helpers/utils/getShippingMethod';
import { getStepFromURLParams, getURLParamByName, updateURLParams } from 'helpers/utils/updateURLParams';
import { PaymentHandler } from 'nerivio-ui/checkout/utils/PaymentHandler';
import { STEPS_REQUIRED_LOGIN, STEP_IDS, ShippingOptions, } from 'types/checkout';
import { useAccount, useCart } from 'frontastic';
import { updateCustomerDetails } from 'frontastic/actions/account';
import { updatePatientDetails } from 'frontastic/actions/cart';
import CheckoutSteps from './checkout-steps';
import { checkDefaultShippingAddressChange, mapAccountAddressToFormStructure, mapToCartStructure, mapToFormStructure, } from './mapFormData';
import PaymentSection from './panels/payment';
import UploadPrescription from './panels/prescription/prescription-details';
import Address from './panels/shipping/address';
import { COUNTRY_CODE } from 'helpers/constants/envVariables';
const Checkout = ({ checkoutTasticData }) => {
    const { data: cartList, isValidating: isCartLoading, updateCart, setShippingMethod, updateItem, removeItem, getShippingMethods, getShippingSettings } = useCart();
    const { addAddress, account, loggedIn, isValidating } = useAccount();
    const router = useRouter();
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [currentShippingMethod, setCurrentShippingMethod] = useState();
    const [loading, setLoading] = useState(false);
    const [currentDefaultShippingAddress, setCurrentDefaultShippingAddress] = useState(null);
    const [patientDetails, setPatientDetails] = useState(null);
    const [shippingData, setShippingData] = useState(null);
    const [shippingSettings, setShippingSettings] = useState(null);
    const { getPaymentMethods } = useCart();
    const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
    useEffect(() => {
        getShippingSettings().then((shippingSettings) => {
            setShippingSettings(shippingSettings);
        });
        return () => {
            setShippingSettings(null);
        };
    }, []);
    useEffect(() => {
        if (!loggedIn) {
            return;
        }
        if (!cartList?.cartId) {
            return;
        }
        if (!PaymentHandler.cartRequiresPayment(cartList)) {
            setPaymentMethodOptions([]);
            return;
        }
        getPaymentMethods().then((paymentMethods) => {
            setPaymentMethodOptions(paymentMethods.map((paymentMethod) => ({
                id: paymentMethod.paymentMethodId,
                value: paymentMethod.paymentMethodId,
                image: paymentMethod.image,
                default: false,
            })));
        });
    }, [loggedIn, cartList?.sum?.centAmount]);
    const goToTopOfPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const goToNextStep = (target) => {
        const stepIndex = getStepFromURLParams(CHECKOUT_STEP_PARAM, STEP_IDS);
        const nextStep = target !== undefined ? Number(target) : stepIndex + (steps[stepIndex]?.id === STEP_IDS[0] ? 2 : 1);
        const newUrlMatchWithCheckoutStep = updateURLParams([
            {
                key: CHECKOUT_STEP_PARAM,
                value: steps?.[nextStep]?.id,
            },
        ]);
        router.replace(newUrlMatchWithCheckoutStep);
    };
    const updateShippingData = (data) => {
        const tempData = { ...data, email: account?.email };
        setShippingData(tempData);
    };
    const updateCartShippingData = async (data, isTouched) => {
        if (isTouched || isObjectEmpty(cartList?.billingAddress) || isObjectEmpty(cartList?.shippingAddress)) {
            setLoading(true);
            if (data.addressId === 'dhl-address') {
                data.shippingStreetName = 'Packstation';
                data.shippingStreetNumber = data.dhlPackstationNumber;
                data.shippingAdditionalStreetInfo = data.dhlPostNumber;
            }
            setShippingData(data);
            // setLoading(true)
            const updatedData = mapToCartStructure(data);
            // Need to check this to prevent from creating many addresses
            const isDefautShippingAddressChanged = checkDefaultShippingAddressChange(data, currentDefaultShippingAddress);
            if (data.isDefaultShippingAddress && account?.accountId && isDefautShippingAddressChanged) {
                await addAddress({
                    isDefaultBillingAddress: false,
                    isDefaultShippingAddress: data?.isDefaultShippingAddress,
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    streetName: data?.shippingStreetName,
                    streetNumber: data?.shippingStreetNumber,
                    additionalAddressInfo: data?.shippingAdditionalAddressInfo,
                    additionalStreetInfo: data?.shippingAdditionalStreetInfo,
                    postalCode: data?.shippingPostalCode,
                    city: data?.shippingCity,
                    state: data?.shippingState,
                    country: data?.shippingCountry,
                });
            }
            updateCart(updatedData)
                .then(() => {
                getShippingMethods().then((data) => {
                    const shippingMethod = getShippingMethod(data, updatedData?.shipping?.country);
                    updateCurrentShippingMethod(shippingMethod);
                });
            })
                .then(() => {
                setLoading(false);
                goToNextStep();
            })
                .catch((err) => {
                setLoading(false);
            });
        }
        else {
            goToNextStep();
        }
    };
    const updateCurrentShippingMethod = (shippingMethod) => {
        if (shippingMethod?.shippingMethodId) {
            setCurrentShippingMethod(shippingMethod);
            setShippingMethod(shippingMethod.shippingMethodId);
        }
    };
    /*Prescription */
    const [prescription, setPrescription] = useState(null);
    const [loadingPrescription, setLoadingPrescription] = useState(true);
    // Make sure we don't upload same files multiple times
    const handleProceedToPayment = async () => {
        // setLoading(true)
        // try {
        //   if (cartList?.directDiscountCodes.length > 0) {
        //     addCoupon(
        //       cartList?.custom?.fields?.discount_codes[0],
        //       'absolute',
        //       cartList?.lineItems[0]?.discounts[0]?.discountedAmount?.centAmount,
        //     )
        //   }
        // } catch (error) {
        //   // Do something here
        // }
        // setLoading(false)
    };
    const handleSetPrescription = (prescription) => {
        if (prescription) {
            prescription['cartId'] = cartList?.cartId;
        }
        else {
            deletePrescription();
        }
        setPrescription(prescription);
    };
    const handlePrescriptionSubmit = async (data, isPatientDetailsTouched) => {
        setPatientDetails(data);
        const prescriptionIDB = `${cartList?.cartId}|${prescription?.name}|${prescription?.content}`;
        await uploadPrescriptionToIDB(prescriptionIDB);
        if (data && isPatientDetailsTouched) {
            setLoading(true);
            if (data.forSomeone || !data.forSomeone && cartList?.custom?.fields && 'PatientFirstName' in cartList?.custom?.fields) {
                updatePatientDetails(cartList, data);
            }
            if (!data.forSomeone && shouldUpdateCustomerDetails(account, data)) {
                updateCustomerDetails(data);
            }
            setLoading(false);
        }
        goToNextStep();
    };
    const steps = useMemo(() => [
        {
            number: 0,
            id: STEP_IDS[0],
            label: checkoutTasticData?.checkoutSteps?.[0]?.label,
            component: (<Cart shippingSettings={shippingSettings} cart={cartList} removeItem={removeItem} editItemQuantity={updateItem} pageTitle={checkoutTasticData.cartPageTitle} returnRefundLabel={checkoutTasticData.returnRefundLabel} returnRefundContent={checkoutTasticData.returnRefundContent} packageDescription={checkoutTasticData.packageDescription} goToNextStep={goToNextStep} paymentImage={checkoutTasticData.paymentImage} shippingCountryMessage={checkoutTasticData.shippingCountryMessage} shippingCountryTooltip={checkoutTasticData.shippingCountryTooltip}/>),
        },
        {
            number: 1,
            id: STEP_IDS[1],
            notClickableStep: true,
            label: checkoutTasticData?.checkoutSteps?.[1]?.label,
            component: null,
        },
        {
            number: 2,
            id: STEP_IDS[2],
            label: checkoutTasticData?.checkoutSteps?.[2]?.label,
            component: (<UploadPrescription tasticData={checkoutTasticData} prescription={prescription} patientDetails={patientDetails} handleSetPrescription={handleSetPrescription} onSubmit={handlePrescriptionSubmit}/>),
        },
        {
            number: 3,
            id: STEP_IDS[3],
            label: checkoutTasticData?.checkoutSteps?.[3]?.label,
            component: (<Address pageTitle={checkoutTasticData?.shippingPageTitle} homeAddressTitle={checkoutTasticData?.homeAddressTitle} dhlPackstationTitle={checkoutTasticData?.dhlPackstationTitle} dhlPackstationCTA={checkoutTasticData?.dhlPackstationCTA} shippingData={shippingData} onSubmit={updateCartShippingData}/>),
        },
        {
            number: 4,
            id: STEP_IDS[4],
            label: checkoutTasticData?.checkoutSteps?.[4]?.label,
            component: (<PaymentSection pageTitle={checkoutTasticData?.paymentPageTitle} paymentMethodOptions={paymentMethodOptions} cart={cartList} accountId={account?.accountId} onSubmit={handleProceedToPayment}/>),
        },
    ], [shippingSettings, patientDetails, prescription, shippingData, currentShippingMethod, cartList, account, paymentMethodOptions]);
    useEffect(() => {
        let isUpdated = false;
        // Get shipping address from Cart
        const defaultData = mapToFormStructure(cartList);
        if (defaultData) {
            updateShippingData(defaultData);
            isUpdated = true;
        }
        // Get default shipping address from Account
        if (loggedIn && !isValidating && !isUpdated) {
            const selectedShippingAddress = account?.addresses.find((address) => address.isDefaultShippingAddress === true);
            if (selectedShippingAddress) {
                const currentDefaultShippingAddress = mapAccountAddressToFormStructure(selectedShippingAddress, account?.email);
                setCurrentDefaultShippingAddress(currentDefaultShippingAddress);
                updateShippingData(currentDefaultShippingAddress);
                isUpdated = true;
            }
        }
        // hotfix if no address is available in cart and account
        if (loggedIn && !isValidating && !isCartLoading && !isUpdated) {
            updateShippingData({
                firstName: '',
                lastName: '',
                email: '',
                addressId: ShippingOptions.HOME_ADDRESS,
                shippingStreetName: '',
                shippingStreetNumber: '',
                shippingCity: '',
                shippingPostalCode: '',
                shippingCountry: COUNTRY_CODE ?? 'DE',
                billingFirstName: '',
                billingLastName: '',
                billingStreetName: '',
                billingStreetNumber: '',
                billingCity: '',
                billingPostalCode: '',
                billingCountry: COUNTRY_CODE ?? 'DE',
                shippingAdditionalAddressInfo: '',
                billingAdditionalAddressInfo: '',
                shippingAdditionalStreetInfo: '',
                billingAdditionalStreetInfo: '',
                isDefaultShippingAddress: true,
                isBillingSameAsShipping: true,
                dhlPostNumber: '',
                dhlPackstationNumber: ''
            });
        }
        // Handle patient details
        if (loggedIn && !isValidating && !isCartLoading) {
            if (!patientDetails) {
                // Get initial patient data
                const initialPatientDetails = {
                    forSomeone: !!cartList?.custom?.fields?.PatientPhone,
                    patientFirstName: cartList?.custom?.fields?.PatientFirstName || account?.firstName || '',
                    patientLastName: cartList?.custom?.fields?.PatientLastName || account?.lastName || '',
                    patientCountryPhoneCode: '+49',
                    patientPhone: '',
                };
                const patientPhone = cartList?.custom?.fields?.PatientPhone ?? account?.custom?.fields?.customerPhone ?? '';
                const [countryCode, phoneNumber] = extractCustomerPhoneNumber(patientPhone);
                initialPatientDetails.patientCountryPhoneCode = countryCode;
                initialPatientDetails.patientPhone = phoneNumber;
                setPatientDetails(initialPatientDetails);
            }
        }
        // Handle initial prescription
        if (!prescription && !isCartLoading) {
            getPrescriptionFromIDB().then((prescription) => {
                if (prescription?.cartId === cartList?.cartId) {
                    setPrescription(prescription);
                }
                else {
                    deletePrescription();
                }
            }).finally(() => {
                setLoadingPrescription(false);
            });
        }
    }, [cartList, loggedIn, isValidating, isCartLoading]);
    useEffect(() => {
        if (!currentShippingMethod && cartList?.availableShippingMethods) {
            if (cartList?.shippingInfo) {
                const currentShippingMethod = cartList.availableShippingMethods.find(({ shippingMethodId }) => shippingMethodId == cartList.shippingInfo.shippingMethodId);
                setCurrentShippingMethod(currentShippingMethod);
            }
            else {
                setCurrentShippingMethod(cartList?.availableShippingMethods?.[0]);
            }
        }
    }, [cartList?.availableShippingMethods]);
    const stepParam = getURLParamByName(CHECKOUT_STEP_PARAM);
    useEffect(() => {
        if (isCartLoading || loadingPrescription) {
            return;
        }
        /**
         * Handle cases:
         * 1. Invalid param
         * 2. Cart is empty
         * 3. Profile step should be skipped
         */
        if (!stepParam || !STEP_IDS.includes(stepParam) || stepParam === STEP_IDS[1] || !cartList?.lineItems?.length) {
            const newUrlMatchWithCheckoutStep = updateURLParams([
                {
                    key: CHECKOUT_STEP_PARAM,
                    value: stepParam === STEP_IDS[1] && cartList?.lineItems?.length > 0
                        ? STEP_IDS[2]
                        : STEP_IDS[0],
                },
            ]);
            router.replace(newUrlMatchWithCheckoutStep);
            return;
        }
        /**
         * Handle cases:
         * 1. Prescription not submitted and customers are in different steps other than cart and prescription
         */
        if (!loadingPrescription && !prescription && ![STEP_IDS[0], STEP_IDS[2]].includes(stepParam)) {
            router.replace(updateURLParams([{ key: CHECKOUT_STEP_PARAM, value: STEP_IDS[2] }]));
            return;
        }
        /**
         * Mostly handle shipping and payment steps
         */
        const targetStep = getValidatedCheckoutStep(cartList, stepParam);
        if (targetStep !== stepParam) {
            const newUrlMatchWithCheckoutStep = updateURLParams([
                {
                    key: CHECKOUT_STEP_PARAM,
                    value: targetStep
                },
            ]);
            router.replace(newUrlMatchWithCheckoutStep);
            return;
        }
        const stepIndex = steps.findIndex((step) => step.id === targetStep);
        if (stepIndex !== currentStepIndex) {
            setCurrentStepIndex(stepIndex === 1 ? stepIndex + 1 : stepIndex);
        }
        goToTopOfPage();
    }, [stepParam, isCartLoading, loadingPrescription]);
    if (STEPS_REQUIRED_LOGIN.includes(stepParam) && !loggedIn && !isValidating) {
        return <Redirect target={`${HOME}`}/>;
    }
    return (<>
      {(loading || isCartLoading || loadingPrescription) && <Loader />}
      {cartList?.lineItems && cartList?.lineItems?.length > 0 && (<CheckoutSteps steps={steps} currentStep={steps[currentStepIndex]} goToNextStep={goToNextStep}/>)}
      {isValidating ? (<Loader />) : (<>
          {steps[currentStepIndex]?.component}
          {/* TODO: should we add Back button? */}
          {/* <button
              type='button'
              className='flex w-fit items-center justify-start gap-x-3'
            >
              <Image
                src="/icons/custom/arrow-left-back.svg"
                alt=''
              />
              <h4 className='underline'>{formatMessage({ id: 'back' })}</h4>
            </button> */}
        </>)}
    </>);
};
export default Checkout;
