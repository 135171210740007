import { MONEY_PATTERN } from "components/constants/patterns";
import Image from "frontastic/lib/image";
const ProductInformation = ({ productImage, productTitle, productSubTitle, productCTALabel, productTextUnderneath, CTA }) => {
    const handleMarkdown = (text) => {
        if (!text)
            return '';
        const matches = text.match(MONEY_PATTERN);
        if (matches === null)
            return text;
        let newText = text;
        matches.forEach((match) => {
            newText = newText.replace(match, `<b>${match}</b>`);
        });
        return newText;
    };
    const handleOnClick = () => {
        if (CTA) {
            CTA();
        }
    };
    return (<>
      <section className="bg-gradient-100 flex w-full flex-col items-center">
        <div className="lg:gap-space-col-lg container flex flex-col-reverse lg:grid lg:grid-cols-2">
          <div className="flex w-full flex-col justify-center">
            <h2 className="h1 mt-10 lg:mt-0">{productTitle}</h2>
            <h3 className="text-gray-dark mt-2.5 whitespace-pre-line font-medium lg:mt-6" dangerouslySetInnerHTML={{ __html: handleMarkdown(productSubTitle) }}/>
            <button data-cy='buyNowButton' onClick={handleOnClick} className=" btn btn-primary mt-10 w-full lg:mt-44 lg:w-[298px]">{productCTALabel}</button>
          </div>
          <div>
            <Image media={productImage?.media} objectFit="contain" alt="" priority loading={'eager'}/>
            <p className="mt-4 text-xs text-[#64666B]" dangerouslySetInnerHTML={{ __html: productTextUnderneath }}/>
          </div>
        </div>
      </section>
    </>);
};
export default ProductInformation;
