
import Homepage from 'components/homepage'
import { HomepageProps } from 'types/home'

interface ProductInformationProps {
  data: HomepageProps
}

const ProductInformation: React.FC<ProductInformationProps> = ({ data }) => {

  return (
    <div className="fixed-screen-width flex flex-col items-center">
      <Homepage {...data} />
    </div>
  )

}

export default ProductInformation
