import React from 'react';
import CloseIcon from 'components/icons/icon-x';
import Image from 'frontastic/lib/image';
export default function Modal({ children, onClose, type }) {
    const color = type === 'info' ? 'invert(19%) sepia(69%) saturate(1351%) hue-rotate(145deg) brightness(91%) contrast(101%)' : '';
    return (<>
      <div className="fixed right-0 top-0 z-50 h-full w-full bg-gray-900 bg-opacity-75">
        <div className="flex min-h-screen items-center px-4 py-8">
          <div className="relative mx-auto w-full  max-w-md rounded-md bg-white p-6 shadow-lg">
            <div className="flex justify-end">
              <button className="cursor-pointer" onClick={onClose}>
                <CloseIcon className="h-6 w-5 fill-neutral-700 dark:fill-light-100"/>
              </button>
            </div>
            <div className="flex flex-col items-center justify-center gap-2">
              <div className="h-[56px] w-[56px] object-contain">
                <Image src={'/icons/custom/warning-sign.png'} alt={`${type}-sign`} width={100} height={100} objectFit="contain" style={{
            filter: color,
            width: '56px',
            height: 'auto',
        }}/>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>);
}
