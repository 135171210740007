import React from 'react'
import OrderSummary from 'components/commercetools-ui/order-summary'
import { OrderOverviewTasticProps } from 'types/order-overview';
import AuthFrontastic from 'components/auth-wrapper';

interface Props {
  data: OrderOverviewTasticProps
}

const OrderSummaryTastic: React.FC<Props> = ({ data }) => {
  return (
    <AuthFrontastic>
      <OrderSummary tasticData={data} />
    </AuthFrontastic>
  )
}

export default OrderSummaryTastic
