import Input from "./form"

const StyleGuide = () => {
  return (
    <div className="w-11/12 mx-auto">
      {/* Headline */} <h3 className="text-[red] mt-10">Headline</h3><hr />
      <h1>h1</h1>
      <h2>h2</h2>
      <h3>h3</h3>
      <h4>h4</h4>
      <h5>h5</h5>
      <h6>h6</h6>

      {/* Buttons */} <h3 className="text-[red] mt-10">Buttons</h3><hr />
      <div className="">
        <button>button - default</button><br /><br />
        <a>a - default</a><br /><br />

        <button className="btn">button.btn</button><br /><br />
        <a className="btn">a.btn</a><br /><br />
        <span className="btn">span.btn</span><br /><br />

        <button className="btn btn-primary" disabled>button.btn.btn-primary</button><br /><br />
        <button className="btn btn-primary">button.btn.btn-primary</button><br /><br />
        <a className="btn btn-primary">a.btn.btn-primary</a><br /><br />
        <span className="btn btn-primary">span.btn.btn-primary</span><br /><br />

        <button className="btn btn-secondary">button.btn.btn-secondary</button><br /><br />
        <a className="btn btn-secondary">a.btn.btn-secondary</a><br /><br />
        <span className="btn btn-secondary">span.btn.btn-secondary</span><br /><br />

        <button className="btn btn-tertiary">button.btn.btn-tertiary</button><br /><br />
        <a className="btn btn-tertiary">a.btn.btn-tertiary</a><br /><br />
        <span className="btn btn-tertiary">span.btn.btn-tertiary</span><br /><br />

        {/* Form */} <h3 className="text-[red] mt-10">Form</h3><hr /><br />
        {/* <textarea placeholder='textarea' /><br /> */}
        {/* <textarea placeholder='textare - disabled' disabled /><br /> */}
      </div>

      <Input />

      {/* Icon */}
      <h3 className="text-[red] mt-10">Icons</h3><hr />
      <div className='flex flex-row'>
        icons here
      </div>
    </div>
  )
}

export default StyleGuide
