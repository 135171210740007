import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { PRODUCT_ADDED_TO_CART } from '../../helpers/constants/events';
const useTrack = () => {
    const { query } = useRouter();
    const lastProductId = useRef();
    // const trackView = useCallback(async () => {
    //   if (product && product.productId !== lastProductId.current) {
    //     gtag('event', query.sr ? PDP_VIEWED_AFTER_SEARCH : PDP_VIEWED, { product });
    //     lastProductId.current = product.productId;
    //   }
    // }, [product, query]);
    const trackAddToCart = useCallback(async () => {
        const eventName = query.sr ? PRODUCT_ADDED_TO_CART : 'PRODUCT_ADDED_TO_CART';
        gtag('event', eventName, query);
    }, [query]);
    return { trackAddToCart };
};
export default useTrack;
