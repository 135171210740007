import React, { useCallback, useMemo, useState } from 'react';
const Field = ({ id, label, value: text, onChange, ...input }) => {
    //input value
    const [value, setValue] = useState(input.defaultValue || text);
    //input change handler
    const handleChange = useCallback((e) => {
        const newValue = e.target.value;
        setValue(newValue);
        onChange?.(newValue);
    }, [onChange]);
    //returns an appropriate input element
    const InputComponent = useMemo(() => {
        //given input type or fallback to default if not supplied
        const type = input.type || 'text';
        //return the proper input based on that type
        switch (type) {
            case 'select':
                const options = input.options ?? [];
                return (<select {...input} id={id} className="focus:border-accent-400 focus:ring-accent-400 mt-1 block w-full rounded-sm border-neutral-400 py-3 pr-10 pl-3 text-base text-neutral-600 transition duration-150 ease-out focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm" defaultValue={value} onChange={handleChange}>
            {options.map((option) => (<option key={option.name} value={option.value}>
                {option.name}
              </option>))}
          </select>);
            default:
                delete input.defaultValue;
                return (<input {...input} id={id} aria-label={label} value={value} className="input-form focus:border-accent-400 focus:ring-accent-400 block w-full rounded-sm border-neutral-400 p-3 text-neutral-600 shadow-sm transition duration-150 ease-out disabled:cursor-not-allowed disabled:bg-[#CACACA] disabled:text-black sm:text-sm" onChange={handleChange}/>);
        }
    }, [input, handleChange, value]);
    return (<div>
      <label htmlFor={id} className="mb-3 block text-sm font-normal capitalize text-black">
        {label}
      </label>
      {InputComponent}
    </div>);
};
export default Field;
