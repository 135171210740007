import React from 'react';
import { GLOBAL_SITE } from 'helpers/constants/envVariables';
import Image from 'frontastic/lib/image';
import ContactEmail from './contact-email';
import FooterLinks from './footer-links';
import classNames from 'classnames';
const Footer = ({ contactLabel, contactMail, footerLinks, btmRightLogo, globalFooterLinks }) => {
    return (<footer className={classNames('footer-nerivio bg-gradient-footer p-space-inside-header-footer-sm lg:px-space-inside-header-footer-lg flex flex-col justify-end lg:py-7', {
            'min-h-[224px]': GLOBAL_SITE === 'true',
        })} aria-label="footer">
      <div className='flex flex-col justify-between gap-4 lg:gap-20'>
        {GLOBAL_SITE !== 'true' ? (<>
            <ContactEmail contactLabel={contactLabel} contactMail={contactMail}/>
            <div className='flex w-full items-center justify-between md:w-full lg:mt-0'>
              <FooterLinks footerLinks={footerLinks}/>
              <div className='mt-auto h-8 w-[174px]'>
                <Image media={btmRightLogo} alt='' objectFit='contain' priority loading={'eager'}/>
              </div>
            </div>
          </>) : (<div className='flex w-full items-center justify-between md:w-full lg:mt-0'>
            <FooterLinks footerLinks={globalFooterLinks}/>
          </div>)}
      </div>
    </footer>);
};
export default Footer;
