import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import Loader from '../loader';
import Price from '../price';
function CancelSuccess() {
    const [loading, setLoading] = useState(true);
    const [order, setAccountOrders] = useState();
    const { orderHistory } = useCart();
    const router = useRouter();
    const orderId = router?.query?.['orderId'];
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'checkout' });
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    useEffect(() => {
        if (orderHistory && orderId) {
            orderHistory().then((data) => {
                setAccountOrders(data.filter((el) => el.orderId == orderId)[0]);
                setLoading(false);
            });
        }
    }, [orderHistory, orderId]);
    return (<>
      {loading && <Loader />}
      <main className="mx-auto max-w-2xl px-2 pb-24 sm:px-4 sm:pt-20 md:px-8 md:pt-8 lg:max-w-7xl">
        <h1 className="cancel-order-title">Cancel Order</h1>
        <p className="bg-[#71799D] py-4 text-center text-lg font-normal text-[#FFFFFF]">
          Cancel request is successful!
        </p>
        <div className="flex flex-col justify-center gap-14 md:flex-row md:gap-20">
          <div className="mt-4 flex-1 md:mt-0">
            <div className="mb-5 flex flex-col rounded border bg-[#E7EDE8] px-4 py-10 sm:mt-5 md:flex-row" key={order?.orderId}>
              {/* Left side (Image and Title) */}
              {order?.lineItems.map((product) => (<div className="div" key={product.productId}>
                  <div className="flex flex-row ">
                    <div className="flex shrink-0">
                      <img src={product.variant.images[0]} alt={product?.name} className="w-16 lg:w-28"/>
                    </div>

                    <div className="ml-5 mt-2">
                      <h2 className="text-lg font-semibold">{product?.name}</h2>
                      <p>
                        <span className="mr-1">{product.count}</span>
                        {formatProductMessage({
                id: 'unit',
                defaultMessage: 'Unit',
            })}
                        <span className="mx-1">|</span>
                        <span>
                          {formatProductMessage({
                id: 'order.date',
                defaultMessage: 'Order Date:',
            })}
                          <span className="ml-1">{order.createdAt.toString().substring(0, 10)} </span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 flex justify-between lg:ml-3">
                    <p className="text-black text-base font-bold">
                      {formatCartMessage({ id: 'estimated.total', defaultMessage: 'Estimated total' })}
                    </p>
                    <p className="">
                      <Price price={{
                centAmount: order?.sum?.centAmount,
                currencyCode: order?.sum?.currencyCode,
            }} className="text-black text-base font-bold"/>
                    </p>
                  </div>
                </div>))}
            </div>
            <p className="order-cancel-policy">
              {formatMessage({
            id: 'policy.cancel',
            defaultMessage: 'Your refund will be credited to the actual payment method with in 7 working days, once the item reaches back to us.',
        })}
            </p>
            <div className="mt-2 flex justify-end">
              <button className="back-to-login-btn">
                <Link href={'/cancellation-policy'}>VIEW CANCELLATION POLICIES</Link>
              </button>
            </div>
          </div>
          <div className="flex-1">
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatMessage({ id: 'refund.mode', defaultMessage: 'Refund Mode' })}
              </p>
              <p className="order-cancel-subheading">
                {formatMessage({ id: 'payment.mode', defaultMessage: 'Paypal' })}
              </p>
            </div>
            <div className="divider"></div>
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatAccountMessage({ id: 'deliveryAddress', defaultMessage: 'Delivery Address' })}
              </p>
              <p className="order-cancel-subheading">
                {order?.shippingAddress?.firstName + ' ' + order?.shippingAddress?.lastName}
              </p>
              <p className="order-cancel-subheading">
                {order?.shippingAddress?.streetName}, {order?.shippingAddress?.additionalStreetInfo},{' '}
                {order?.shippingAddress?.city},{' '}
                {order?.shippingAddress?.country === 'DE' ? 'Germany' : order?.shippingAddress?.country}
              </p>
            </div>
            <div className="divider"></div>
            <div className="mb-6">
              <p className="order-cancel-heading">
                {formatAccountMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}
              </p>
              <p className="order-cancel-subheading">
                {order?.billingAddress?.firstName + ' ' + order?.billingAddress?.lastName}
              </p>
              <p className="order-cancel-subheading">
                {order?.billingAddress?.streetName}, {order?.billingAddress?.additionalStreetInfo},{' '}
                {order?.billingAddress?.city},{' '}
                {order?.billingAddress?.country === 'DE' ? 'Germany' : order?.billingAddress?.country}
              </p>
            </div>
            <div className="mt-2 flex justify-end">
              <button className="back-to-login-btn">
                <Link href="/account#orders">GO TO ORDERS</Link>
              </button>
            </div>
          </div>
          {/* )} */}
        </div>
      </main>
    </>);
}
export default CancelSuccess;
