import { useFormat } from "./hooks/useFormat";
export const useCustomerName = (cart, isBilling) => {
    const data = isBilling ? cart?.billingAddress : cart?.shippingAddress;
    const customerName = [data?.firstName ?? '', data?.lastName ?? ''].filter(Boolean).join(' ');
    return customerName;
};
export const useShippingAddress = (cart, isBilling, singleLine) => {
    const { formatMessage: formatCheckout } = useFormat({ name: 'checkout' });
    const data = isBilling ? cart?.billingAddress : cart?.shippingAddress;
    const additionalAddressInfo = data?.additionalAddressInfo;
    const streetData = [data?.additionalStreetInfo ?? '', data?.streetName ?? '', data?.streetNumber ?? ''].filter(Boolean).join(' ');
    const cityData = [data?.postalCode ?? '', data?.city ?? ''].filter(Boolean).join(' ');
    const countryData = data?.country === 'DE' ? 'Germany' : (data?.country ?? formatCheckout({ id: 'country.default', defaultMessage: 'Germany' }));
    const address = [additionalAddressInfo, streetData, cityData, countryData].filter(Boolean).join(singleLine ? ', ' : '\n');
    return address;
};
