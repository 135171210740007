import React from 'react';
import CustomAccordion from 'components/commercetools-ui/accordion/customAccordion';

function FrequentlyAskedQuestions({ data }) {
  // return (
  //   <div className="my-10 text-center md:mt-20 md:!min-w-[300px]">
  //     <h1 className="faq-title ">{data.faqSectionTitle}</h1>

  //     <div className="faq-ques mt-5 inline-block text-left">
  //       {data.faqItems.map((question, index) => (
  //         <CustomAccordion
  //           key={index}
  //           openSectionTitle={question.question}
  //           closedSectionTitle={question.question}
  //           className="home-faq"
  //         >
  //           <div className="max-w-3xl p-4">{question.answer}</div>
  //         </CustomAccordion>
  //       ))}
  //     </div>
  //   </div>
  // );

  return null
}

export default FrequentlyAskedQuestions;
