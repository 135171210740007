import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { getReferenceTarget } from 'helpers/reference';
import { useAccount } from 'frontastic';
const ResetPassword = ({ logo, token, accountLink }) => {
    //i18n messages
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    //next/router
    const router = useRouter();
    //account actions
    const { resetPassword } = useAccount();
    //reset password data data
    const [data, setData] = useState({ password: '', confirmPassword: '' });
    //error
    const [error, setError] = useState('');
    //processing...
    const [loading, setLoading] = useState(false);
    //handle text input change
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    //data validation
    const validate = () => {
        //validation schema
        const passwordsMatch = data.password === data.confirmPassword;
        //UI error messages
        if (!passwordsMatch)
            setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
        //return a boolean representing the data validity
        return passwordsMatch;
    };
    //form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        //validate data
        if (!validate())
            return;
        //processing starts
        setLoading(true);
        //try registering the user with given credentials
        try {
            const response = await resetPassword(token, data.password);
            if (!response.accountId) {
                setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
            }
            else {
                setError('');
                router?.push(getReferenceTarget(accountLink));
            }
        }
        catch (err) {
            setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
        }
        //processing ends
        setLoading(false);
    };
    return (<div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8">
        <div className="mx-auto w-full max-w-[500px] rounded bg-[#E7EDE8] px-6 pb-32 pt-16 dark:bg-primary-200 lg:px-12">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h2 className="text-black text-lg font-extrabold">
                {formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })}
              </h2>
              {/* <h3 className="text-md mt-6 text-neutral-600">
          {formatAccountMessage({
            id: 'password.reset.desc',
            defaultMessage: 'Fill the fields below to complete your password reset',
          })}
        </h3> */}
            </div>
            {error && <p className="text-sm text-accent-400">{error}</p>}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-black">
                {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
              </label>
              <div className="relative mt-2">
                <input id="password" name="password" type="password" autoComplete="new-password" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" required className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm" onChange={handleChange}/>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-black">
                {formatAccountMessage({ id: 'password.confirm', defaultMessage: 'Confirm Password' })}
              </label>
              <div className="relative mt-2">
                <input id="confirm-password" name="confirmPassword" type="password" autoComplete="new-password" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" required className="block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm" onChange={handleChange}/>
              </div>
            </div>
            <div>
              <button type="submit" className="flex w-full justify-center rounded border border-transparent bg-[#6F9E82] py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200" disabled={loading}>
                {formatAccountMessage({ id: 'password.reset', defaultMessage: 'Reset password' })}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>);
};
export default ResetPassword;
