/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
const SelectForm = ({ onBeforeChange, onChange = () => { }, onFocus, onBlur, name, formRegister, formSetValue, formSetFocus, formGetValues, formWatch, formState, inputType = 'number', validationType = 'tel', min, max, label, containerClassName, inputClassName, value, disabled, defaultErrorMessage, defaultValidation = false, selectClassName, options, defaultValue, isCountry = true }) => {
    const isUseHookForm = !!formRegister;
    const [errorMessage, setErrorMessage] = useState(null);
    const [validInput, setValidInput] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultValue ?? options?.[0]);
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    useEffect(() => {
        setErrorMessage(defaultErrorMessage);
        setValidInput(defaultValidation);
        if (options?.length && !options.includes(defaultValue)) {
            setSelectedOption(options?.[0]);
            if (formSetValue) {
                formSetValue(name, options?.[0], { shouldDirty: true });
            }
        }
    }, []);
    const handleOnChange = (e) => {
        if (onBeforeChange) {
            onBeforeChange(e);
        }
        const { name: inputName, value: newValue } = e.target;
        setSelectedOption(newValue);
        if (onChange) {
            onChange(inputName, newValue);
        }
        if (formSetValue) {
            formSetValue(name, newValue, { shouldDirty: true });
        }
    };
    return (<div className={classNames('field-wrapper', {
            'required-field': validationType
        })}>
      {label && (<div className={classNames('field-label', {
                'field-label--disabled': disabled,
            })}>{label}:</div>)}
      <div className={classNames("field-item", containerClassName, {
            'valid-init': !!!errorMessage && !validInput,
            'valid-success': !!!errorMessage && validInput,
            'valid-error': !!errorMessage,
        })}>
        <select className={classNames('field-input', selectClassName, {
            'input-text-hidden': disabled
        })} disabled={disabled} 
    // aria-label="country-select"
    value={selectedOption} onChange={handleOnChange}>
          {options?.map((code) => (<option key={code} value={code}>
              {formatCommonMessage({ id: isCountry ? `country.${code}` : `state.${code}` })}
            </option>))}
        </select>
      </div>
    </div>);
};
export default SelectForm;
