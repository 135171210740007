import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import Typography from 'components/commercetools-ui/typography';
import { GLOBAL_SITE } from 'helpers/constants/envVariables';
import { headerNavigation } from 'helpers/mocks/mockData';
import { isLiveReference, ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import LanguageSwitcher from '../language-switcher';
import Loader from '../loader';
import AccountButton from './account-button';
import CartButton from './cart-button';
import HeaderMenu from './header-menu';
const Header = ({ tagline, links, cartItemCount, logo, rightLogo, logoLink, account, accountLink, cartLink, previewId, regionLanguageSelectorTitle, regionAndLanguageGroup, regionLanguageGlobalSelectorJson }) => {
    const [open, setOpen] = useState(false);
    const [isLogging, setIsLogging] = useState(false);
    return (<>
      {isLogging && <Loader />}
      {/* Mobile menu */}
      <HeaderMenu open={open} setOpen={setOpen} links={links} navigation={headerNavigation} previewId={previewId}/>

      {/* Desktop menu */}
      <header className="header-nerivio bg-gradient-header">
        {tagline && (<p className="bg-primary-400 flex items-center justify-center px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
            <Typography>{tagline}</Typography>
          </p>)}

        <nav aria-label="Top" className="main_homeheader px-space-inside-header-footer-sm lg:px-space-inside-header-footer-lg mx-auto max-w-full border-b border-gray-200">
          {/* Secondary navigation */}
          <div className="h-full">
            <div className="h-header-height-lg flex items-center justify-between">
              {/* Logo */}
              <div className="flex items-center lg:hidden">
                <button type="button" className="hamburger-menu h-10 w-10 bg-none text-white" onClick={() => setOpen(!open)}>
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-10 w-10" aria-hidden="true"/>
                </button>
              </div>
              <ReferenceLink target={logoLink} className="hidden lg:flex">
                <span className="sr-only">Logo</span>
                <div className="relative h-[56.21px] w-[185px]">
                  {/*TODO: optimize logo's size*/}
                  <Image media={logo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" 
    // loading="lazy"
    loading={'eager'} priority/>
                </div>
              </ReferenceLink>

              {/* Mega menus */}
              {GLOBAL_SITE !== 'true' && (<>
                    <Popover.Group className="mega-menu hidden lg:ml-auto lg:block">
                      {links
                .filter((link) => previewId || isLiveReference(link.reference))
                .map((link, id) => (<ReferenceLink key={id} target={link.reference} className="menu-item">
                            <Typography>{link.name}</Typography>
                          </ReferenceLink>))}
                    </Popover.Group>

                    <div className="flex items-center justify-between gap-10">
                      <LanguageSwitcher className="p-0 md:px-0" regionLanguageSelectorTitle={regionLanguageSelectorTitle} regionAndLanguageGroup={regionAndLanguageGroup}/>
                      <AccountButton account={account} accountLink={accountLink}/>
                      <CartButton cartItemCount={cartItemCount} cartLink={cartLink}/>
                      <div className="relative hidden h-[24.89px] w-[113.15px] lg:flex">
                        {/*TODO: optimize logo's size*/}
                        <Image media={rightLogo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" loading="lazy"/>
                      </div>
                    </div>
                  </>)}
            </div>
          </div>
        </nav>
      </header>
      {GLOBAL_SITE === 'true' && (<div className='global-region-selector h-full'>
            <LanguageSwitcher className="h-full w-full p-0 md:px-0" regionLanguageSelectorTitle={regionLanguageSelectorTitle} regionAndLanguageGroup={regionAndLanguageGroup} regionLanguageGlobalSelectorJson={regionLanguageGlobalSelectorJson} alwaysDisplay/>
          </div>)}
    </>);
};
export default Header;
