import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CHECKOUT_CLICKED } from 'helpers/constants/events';
import { useAccount } from 'frontastic';
import { redirectLoginUrl } from '../../../helpers/auth0Helper';
import Loader from '../loader';
import CartSummary from './cartSummary';
import EmptyCart from './emptyCart';
import ItemList from './itemList';
const Cart = ({ cart, shippingSettings, editItemQuantity, removeItem, pageTitle, returnRefundLabel, returnRefundContent, packageDescription, goToNextStep, paymentImage, shippingCountryMessage, shippingCountryTooltip }) => {
    const [loading, setLoading] = useState(true);
    const { account, isValidating } = useAccount();
    const router = useRouter();
    const onCheckout = (e) => {
        e.preventDefault();
        if (account) {
            gtag('event', CHECKOUT_CLICKED, '');
            goToNextStep();
        }
        else {
            router.push(redirectLoginUrl(router.asPath, router.locale), undefined, { locale: false });
        }
    };
    const goToProductPage = (_url) => router.push(_url);
    useEffect(() => {
        if (cart?.lineItems) {
            setLoading(false);
        }
    }, [cart]);
    if (!isValidating && ((!loading && !cart?.lineItems) || cart?.lineItems?.length < 1)) {
        return <EmptyCart />;
    }
    const shouldLoadingPage = loading || isValidating;
    return (<>
      {shouldLoadingPage && <Loader />}
      <h1 className="checkout-heading">{pageTitle}</h1>

      {!isValidating && cart?.lineItems && (<form className="container--cart container-2-col container-2-col--7-5">
          <ItemList packageDescription={packageDescription} shippingSettings={shippingSettings} cart={cart} editItemQuantity={editItemQuantity} goToProductPage={goToProductPage} removeItem={(lineItemId) => removeItem(lineItemId)} shippingCountryMessage={shippingCountryMessage} shippingCountryTooltip={shippingCountryTooltip}/>
          <CartSummary cart={cart} onSubmit={onCheckout} showDiscountsForm={false} accountId={account?.accountId} returnRefundLabel={returnRefundLabel} returnRefundContent={returnRefundContent} paymentImage={paymentImage}/>
        </form>)}
    </>);
};
export default Cart;
