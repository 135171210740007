import isEqual from 'lodash/isEqual';
import { isObjectEmpty } from 'helpers/objectHelpers';
import { ShippingOptions } from 'types/checkout';
export const mapToCartStructure = (data) => {
    const mappedData = {
        account: {
            email: data.email,
        },
        shipping: {
            firstName: data.firstName,
            lastName: data.lastName,
            addressId: data?.addressId ?? ShippingOptions.HOME_ADDRESS,
            streetName: data.shippingStreetName,
            streetNumber: data.shippingStreetNumber,
            postalCode: data.shippingPostalCode,
            additionalAddressInfo: data?.shippingAdditionalAddressInfo,
            additionalStreetInfo: data?.shippingAdditionalStreetInfo,
            city: data.shippingCity,
            state: data?.shippingState ?? '',
            country: data.shippingCountry,
        },
    };
    if (!data?.isBillingSameAsShipping || data.addressId === ShippingOptions.DHL_ADDRESS) {
        mappedData.billing = {
            firstName: data.billingFirstName,
            lastName: data.billingLastName,
            streetName: data.billingStreetName,
            streetNumber: data.billingStreetNumber,
            postalCode: data.billingPostalCode,
            additionalAddressInfo: data?.billingAdditionalAddressInfo,
            additionalStreetInfo: data?.billingAdditionalStreetInfo,
            city: data.billingCity,
            country: data.billingCountry,
        };
    }
    return mappedData;
};
export const mapToFormStructure = (data) => {
    if (!data?.shippingAddress || isObjectEmpty(data?.shippingAddress)) {
        return null;
    }
    const isHomeAddress = data?.shippingAddress?.addressId === ShippingOptions.HOME_ADDRESS;
    const mappedData = {
        firstName: data.shippingAddress.firstName,
        lastName: data.shippingAddress.lastName,
        email: data.email,
        addressId: data.shippingAddress?.addressId || ShippingOptions.HOME_ADDRESS,
        shippingStreetName: isHomeAddress ? data.shippingAddress.streetName : '',
        shippingStreetNumber: isHomeAddress ? data.shippingAddress.streetNumber : '',
        shippingCity: data.shippingAddress.city,
        shippingState: data.shippingAddress?.state ?? '',
        shippingPostalCode: data.shippingAddress.postalCode,
        shippingCountry: data.shippingAddress.country,
        shippingAdditionalAddressInfo: data.shippingAddress?.additionalAddressInfo,
        shippingAdditionalStreetInfo: isHomeAddress ? data.shippingAddress?.additionalStreetInfo : '',
        billingFirstName: data.billingAddress.firstName,
        billingLastName: data.billingAddress.lastName,
        billingStreetName: data.billingAddress.streetName,
        billingStreetNumber: data.billingAddress.streetNumber,
        billingCity: data.billingAddress.city,
        billingPostalCode: data.billingAddress.postalCode,
        billingCountry: data.billingAddress.country,
        billingAdditionalAddressInfo: data.billingAddress?.additionalAddressInfo,
        billingAdditionalStreetInfo: data.billingAddress?.additionalStreetInfo,
        isDefaultShippingAddress: false,
        isBillingSameAsShipping: false,
        dhlPostNumber: !isHomeAddress ? data.shippingAddress?.additionalStreetInfo : '',
        dhlPackstationNumber: !isHomeAddress ? data.shippingAddress?.streetNumber : '',
    };
    if (isEqual(data.shippingAddress, data.billingAddress)) {
        mappedData.isBillingSameAsShipping = true;
    }
    return mappedData;
};
export const mapAccountAddressToFormStructure = (address, email) => {
    const mappedData = {
        firstName: address.firstName,
        lastName: address.lastName,
        email: email,
        addressId: ShippingOptions.HOME_ADDRESS,
        shippingStreetName: address.streetName,
        shippingStreetNumber: address.streetNumber,
        shippingCity: address.city,
        shippingPostalCode: address.postalCode,
        shippingCountry: address.country,
        shippingState: address?.state ?? '',
        shippingAdditionalAddressInfo: address?.additionalAddressInfo,
        shippingAdditionalStreetInfo: address?.additionalStreetInfo,
        billingFirstName: address.firstName,
        billingLastName: address.lastName,
        billingStreetName: address.streetName,
        billingStreetNumber: address.streetNumber,
        billingCity: address.city,
        billingPostalCode: address.postalCode,
        billingCountry: address.country,
        billingAdditionalAddressInfo: address?.additionalAddressInfo,
        billingAdditionalStreetInfo: address?.additionalStreetInfo,
        isDefaultShippingAddress: address.isDefaultShippingAddress,
        isBillingSameAsShipping: address.isDefaultShippingAddress,
    };
    return mappedData;
};
export const checkDefaultShippingAddressChange = (data, defaultData) => {
    const defaultKeysForShippingAddress = [
        'firstName',
        'lastName',
        'shippingStreetName',
        'shippingStreetNumber',
        'shippingCity',
        'shippingPostalCode',
        'shippingCountry',
        'shippingState',
        'shippingAdditionalAddressInfo',
        'shippingAdditionalStreetInfo'
    ];
    if (!defaultData) {
        return true;
    }
    let isChanged = false;
    defaultKeysForShippingAddress.forEach((key) => {
        // undefined or empty are considered as the same
        if (data?.[key] !== defaultData?.[key] && (data?.[key] || defaultData?.[key])) {
            isChanged = true;
        }
    });
    return isChanged;
};
