import { useForm } from "react-hook-form"
import Checkbox from "components/form/checkbox"
import InputForm from "components/form/inputForm"
import PhoneNumberForm from 'components/form/phoneNumberForm'
import RadioForm from "components/form/radioForm"
import { useFormat } from "helpers/hooks/useFormat"
import StyleGuide from './index'
import classNames from "classnames"

const RADIO_BUTTONS = [
  {
    label: 'Value 0',
    value: 0
  },
  {
    label: 'Value 1',
    value: 1
  }
]
const Input = () => {

  const { formatMessage } = useFormat({ name: 'checkout' })
  const prescriptionUploadForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      isChecked: false,
      selfOrder: RADIO_BUTTONS[0].value,
      yourName: ''
    },
  })
  const {
    register, handleSubmit, formState, setValue, setFocus, watch
  } = prescriptionUploadForm

  const submitForInputText = (data) => {

    console.log(data)
  }
  return (
    <div className="lg:w-1/2">
      <form className='form-wrapper' onSubmit={handleSubmit(submitForInputText)}>
        <p>add <b>.form-wrapper</b> class for field container (can be a form/div) to apply the same gap between fields</p>
        <InputForm
          label='Normal input with no validation'
          name='nothing'
          inputType="text"
          placeholder="Input anything"
        />
        <InputForm
          label={formatMessage({ id: 'backToShop' })}
          name='yourName'
          inputType="text"
          validationType="email"
          placeholder="Input your name"
          hookForm={prescriptionUploadForm}
        />
        <InputForm
          label=""
          placeholder="disabled field"
          name="name"
          inputType="text"
          disabled
        />
        <InputForm
          label=""
          placeholder="success validation"
          name="success"
          inputType="text"
          defaultValidation
        />
        <InputForm
          label=""
          placeholder="error validation"
          name="error"
          inputType="text"
          defaultErrorMessage='error'
        />

        <PhoneNumberForm
          containerClassName="w-full"
          selectClassName={classNames(
            {
              'editable': true
            })}
          inputClassName={classNames(
            {
              'editable': true
            }
          )}
          label='Mobile number'
          name='patientPhone'
          countryPhoneCodeName='patientCountryPhoneCode'
          hookForm={prescriptionUploadForm}
          disabled={false}
          phoneMapping={
            [
              {
                countryCode: "+49",
                countryPhonePlaceholder: "1522 999999"
              },
              {
                countryCode: "+123",
                countryPhonePlaceholder: "123 123 123"
              }
            ]
          }
        />
        <PhoneNumberForm
          containerClassName="w-full"
          selectClassName={classNames(
            {
              'uneditable': true
            })}
          inputClassName={classNames(
            {
              'uneditable': true
            }
          )}
          label='Mobile number - disabled'
          name='patientPhone'
          countryPhoneCodeName='patientCountryPhoneCode'
          hookForm={prescriptionUploadForm}
          disabled={true}
          phoneMapping={
            [
              {
                countryCode: "+49",
                countryPhonePlaceholder: "1522 999999"
              },
              {
                countryCode: "+123",
                countryPhonePlaceholder: "123 123 123"
              }
            ]
          }
        />

        <div className='mt-6 flex gap-x-6'>
          {RADIO_BUTTONS.map((radio, index) => (
            <RadioForm
              key={`${radio.label}-${radio.value}`}
              name='selfOrder'
              label={radio.label}
              value={radio.value}
              defaultChecked={index === 0}
              formRegister={register}
            />
          ))}
        </div>

        <Checkbox
          label="Something you can choose"
          name="isChecked"
          formRegister={register}
        />
        <Checkbox
          label="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
          name="isChecked"
          defaultChecked={true}
          formRegister={register}
        />

        <button type='submit' className="btn btn-primary mt-auto h-fit">Submit</button>
      </form>
    </div>
  )
}

export default Input
