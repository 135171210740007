import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { redirectLogoutUrl } from 'helpers/auth0Helper';
import { useFormat } from 'helpers/hooks/useFormat';
import { GeneralSection, OrdersHistorySection } from './sections/exporter';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
const AccountDetails = () => {
    //i18n messages
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage } = useFormat({ name: 'common' });
    //current window hash
    // const hash = useHash()
    const hash = window.location.hash || '#';
    const router = useRouter();
    //tabs
    const tabs = [
        { name: formatAccountMessage({ id: 'orders.history', defaultMessage: 'Order history' }), href: '#orders' },
        { name: formatAccountMessage({ id: 'patient.details', defaultMessage: 'Patient' }), href: '#' },
        // { name: formatAccountMessage({ id: 'my.addresses', defaultMessage: 'My addresses' }), href: '#addresses' },
        // { name: formatAccountMessage({ id: 'change.password', defaultMessage: 'Change Password' }), href: '#security' },
        // { name: formatAccountMessage({ id: 'Logout', defaultMessage: 'Logout' }) },
    ];
    //tabs change (mobile only)
    const handleTabChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === '#logout') {
            router.push(redirectLogoutUrl());
        }
        else {
            changeURLWithHash(selectedValue);
        }
    };
    //tabs-content mapping
    const mapping = {
        '#orders': OrdersHistorySection,
        '#': GeneralSection,
        // '#addresses': AddressesSection,
        // '#security': SecuritySection,
    };
    //current rendered content
    const Content = mapping[hash];
    const onSelectTab = (e) => {
        changeURLWithHash(e.target.id);
    };
    const changeURLWithHash = (hash) => {
        const currentUrl = new URL(window.location.href);
        currentUrl.hash = hash;
        router.push(currentUrl.href);
    };
    return (<div className="page-container">
      <h1 className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /{' '}
        <span className="mr-3 ml-2 cursor-pointer text-sm font-medium text-black-400  hover:text-black-600">
          {formatAccountMessage({ id: 'my.profile', defaultMessage: 'My Profile' })}
        </span>
      </h1>
      <div className="main-content">
        <div className="lg:flex lg:gap-space-nav-content">
          {/* Mobile Navigation */}
          <div className="menu_box--mobile w-full lg:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Select a tab
            </label>
            <select id="selected-tab" name="selected-tab" className="input-form select-accent border-black text-black focus:border-black focus:ring-black mt-1 block w-full rounded-sm py-2 pr-10 pl-3 text-base focus:outline-none sm:text-sm" value={tabs.find((tab) => tab.href === hash).href} onChange={handleTabChange}>
              {tabs.map((tab) => (<option key={tab.name} value={tab.href} className="text-gray-600">
                  {tab.name}
                </option>))}
            </select>
          </div>

          <div className="menu_box hidden h-fit w-nav-width rounded-xl bg-green-light-50 lg:block">
            <div className="">
              <nav className="flex flex-col space-y-2">
                {tabs.map((tab) => (<button type="button" key={tab.name} id={tab.href} className={classNames(tab.href === hash ? 'text-black text-left font-semibold ' : ' text-black', 'whitespace-nowrap border-b border-gray-light py-4 px-3 text-left text-sm')} onClick={onSelectTab}>
                    {tab.name}
                  </button>))}

                <Link href={redirectLogoutUrl()} locale={false}>
                  <a className="left flex py-4 px-3 text-secondary-dark">
                    <span className="mr-2 hover:underline">
                      {formatAccountMessage({ id: 'my.logout', defaultMessage: 'Logout' })}
                    </span>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Logout" clipPath="url(#clip0_304_479)">
                        <path id="Vector" d="M18.0909 20.043H4.14087C3.8575 20.0428 3.58579 19.9302 3.38543 19.7298C3.18506 19.5295 3.07242 19.2578 3.07227 18.9744V5.025C3.07227 4.4358 3.55167 3.9564 4.14087 3.9564H18.0903C18.6795 3.9564 19.1589 4.4352 19.1589 5.025V8.4C19.1589 8.47336 19.1297 8.54371 19.0779 8.59558C19.026 8.64746 18.9556 8.6766 18.8823 8.6766C18.8089 8.6766 18.7386 8.64746 18.6867 8.59558C18.6348 8.54371 18.6057 8.47336 18.6057 8.4V5.025C18.6054 4.88856 18.551 4.7578 18.4545 4.66133C18.3581 4.56485 18.2273 4.51052 18.0909 4.5102H4.14087C4.00443 4.51052 3.87367 4.56485 3.7772 4.66133C3.68072 4.7578 3.62638 4.88856 3.62607 5.025V18.975C3.62638 19.1114 3.68072 19.2422 3.7772 19.3387C3.87367 19.4351 4.00443 19.4895 4.14087 19.4898H18.0903C18.2267 19.4895 18.3575 19.4351 18.4539 19.3387C18.5504 19.2422 18.6047 19.1114 18.6051 18.975V15.6342C18.6051 15.5608 18.6342 15.4905 18.6861 15.4386C18.738 15.3867 18.8083 15.3576 18.8817 15.3576C18.955 15.3576 19.0254 15.3867 19.0773 15.4386C19.1291 15.4905 19.1583 15.5608 19.1583 15.6342V18.975C19.1583 19.2581 19.0458 19.5297 18.8457 19.73C18.6455 19.9302 18.374 20.0428 18.0909 20.043ZM20.7033 16.212C20.6488 16.2124 20.5954 16.1967 20.5499 16.1669C20.5043 16.137 20.4686 16.0944 20.4473 16.0443C20.4259 15.9942 20.4198 15.9389 20.4298 15.8853C20.4398 15.8318 20.4655 15.7824 20.5035 15.7434L24.1215 11.9916L20.5185 8.2566C20.4933 8.2304 20.4734 8.19949 20.4602 8.16564C20.4469 8.13178 20.4404 8.09565 20.4411 8.0593C20.4418 8.02294 20.4497 7.98708 20.4642 7.95376C20.4788 7.92044 20.4998 7.89031 20.526 7.8651C20.5789 7.81418 20.6498 7.78636 20.7233 7.78777C20.7596 7.78846 20.7955 7.79631 20.8288 7.81087C20.8621 7.82542 20.8923 7.8464 20.9175 7.8726L24.7053 11.7996C24.7552 11.851 24.7832 11.9198 24.7833 11.9915C24.7834 12.0631 24.7556 12.132 24.7059 12.1836L20.9025 16.1274C20.8767 16.1542 20.8457 16.1755 20.8115 16.19C20.7773 16.2046 20.7405 16.212 20.7033 16.212Z" fill="#004A45"/>
                        <path id="Vector_2" d="M24.5053 12.2682H11.1145C11.0411 12.2682 10.9708 12.2391 10.9189 12.1872C10.867 12.1353 10.8379 12.065 10.8379 11.9916C10.8379 11.9182 10.867 11.8479 10.9189 11.796C10.9708 11.7441 11.0411 11.715 11.1145 11.715H24.5053C24.5786 11.715 24.649 11.7441 24.7009 11.796C24.7528 11.8479 24.7819 11.9182 24.7819 11.9916C24.7819 12.065 24.7528 12.1353 24.7009 12.1872C24.649 12.2391 24.5786 12.2682 24.5053 12.2682Z" fill="#004A45"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_304_479">
                          <rect width="24" height="24" fill="white" transform="translate(0.783203)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </Link>
              </nav>
            </div>
          </div>

          {Content && (<div className="grow">
              <Content />
            </div>)}
        </div>
      </div>
    </div>);
};
export default AccountDetails;
