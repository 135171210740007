import React from 'react';
import classnames from 'classnames';
const RadioForm = ({ name, label, dataCy, disabled = false, value, containerClassName, inputClassName, labelClassName, selected, defaultChecked, formRegister, formInputOptions = {}, onClick, }) => {
    const isUseHookForm = !!(formRegister);
    return (<div data-cy={dataCy} className={classnames(containerClassName, 'checkbox-radio-wrapper', 'bg-secondary-light text-green-dark rounded-xl py-2 px-4')}>
      <input className={classnames(inputClassName, 'radio')} {...(isUseHookForm && formRegister(name, formInputOptions))} id={`${name}_${value}`} name={name} type="radio" disabled={disabled} value={value} checked={selected} defaultChecked={defaultChecked} onClick={onClick}/>
      <label htmlFor={`${name}_${value}`} className={classnames(labelClassName)}>{label}</label>
    </div>);
};
export default RadioForm;
