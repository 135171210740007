import { useEffect } from 'react';
import router from 'next/router';
import Loader from 'components/commercetools-ui/loader';
import { redirectLoginUrl } from 'helpers/auth0Helper';
const LoginWithOkta = () => {
    useEffect(() => {
        router.push(redirectLoginUrl('/', router.locale), undefined, { locale: false });
    }, []);
    return <Loader />;
};
export default LoginWithOkta;
