import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { TwoThumbInputRange } from 'react-two-thumb-input-range';
import Price from '../price';
const RangeFilter = ({ facet, onChange, currency }) => {
    const router = useRouter();
    const widthRef = useRef(null);
    const [inputWidth, setInputWidth] = useState(0);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [values, setValues] = useState([minPrice, maxPrice]);
    const updateValues = (updatedValues) => {
        if (updatedValues[1] <= updatedValues[0])
            return;
        setValues(updatedValues);
    };
    const convertCents = (amountInCents) => Math.trunc(amountInCents / 100);
    useEffect(() => {
        if (facet) {
            const { min, max, minSelected, maxSelected } = facet;
            const minConverted = convertCents(min);
            const maxConverted = convertCents(max);
            setMinPrice(minConverted);
            setMaxPrice(maxConverted);
            // Setting default values
            if (minSelected && maxSelected) {
                const minSelectedConverted = convertCents(minSelected);
                const maxSelectedConverted = convertCents(maxSelected);
                updateValues([minSelectedConverted, maxSelectedConverted]);
            }
            else
                updateValues([minConverted, maxConverted]);
        }
        setInputWidth(widthRef.current.clientWidth);
    }, [router, facet]);
    useEffect(() => {
        onChange([values[0] * 100, values[1] * 100]);
    }, [values]);
    return (<div className="grid w-full gap-4">
      <div className="flex justify-between" ref={widthRef}>
        <>
          <Price className="text-gray-500" price={{ fractionDigits: 0, centAmount: values[0], currencyCode: currency }}/>
          <Price className="text-gray-500" price={{ fractionDigits: 0, centAmount: values[1], currencyCode: currency }}/>
        </>
      </div>
      {minPrice && (<TwoThumbInputRange thumbStyle={{ borderRadius: '0', transform: 'rotate(45deg)' }} inputStyle={{ width: inputWidth }} trackColor="#E91E63" railColor="#C4C4C4" thumbColor="#E91E63" showLabels={false} onChange={updateValues} values={values} min={minPrice} max={maxPrice}/>)}
    </div>);
};
export default RangeFilter;
