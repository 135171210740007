import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Field from '../field';
const PatientAddress = () => {
    //i18n messages
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    //next/router
    const router = useRouter();
    //account data
    const { account, update, updateAddress } = useAccount();
    //Form data
    const [data, setData] = useState({
        addressId: '',
        firstName: account?.firstName ?? '',
        lastName: account?.lastName ?? '',
        email: account?.email ?? '',
        streetName: '',
        streetNumber: '',
        postalCode: '',
        additionalAddressInfo: '',
        city: 'DE',
        country: '',
        locale: router.locale || router.defaultLocale,
    });
    let defaultAddress = account?.addresses.find((address) => address.isDefaultShippingAddress);
    if (defaultAddress === undefined || defaultAddress === null) {
        defaultAddress = account?.addresses[0];
    }
    useEffect(() => {
        if (account) {
            setData({
                addressId: defaultAddress?.addressId ?? '',
                firstName: account?.firstName ?? '',
                lastName: account?.lastName ?? '',
                email: account?.email ?? '',
                streetName: defaultAddress?.streetName ?? '',
                streetNumber: defaultAddress?.streetNumber ?? '',
                postalCode: defaultAddress?.postalCode ?? '',
                additionalAddressInfo: defaultAddress?.additionalAddressInfo ?? '',
                city: defaultAddress?.city ?? '',
                country: defaultAddress?.country ?? 'DE',
                locale: router.locale || router.defaultLocale,
            });
        }
    }, [account]);
    //sections in edit mode
    const [inEdit, setInEdit] = useState([]);
    const startEdit = useCallback((index) => {
        setInEdit([...inEdit, index]);
    }, [inEdit]);
    const stopEdit = useCallback((index) => {
        setInEdit(inEdit.filter((i) => i !== index));
    }, [inEdit]);
    const sections = useMemo(() => [
        {
            headline: formatAccountMessage({ id: 'address.myAddresses', defaultMessage: 'Address' }),
            fields: [
                {
                    id: 'firstname',
                    name: 'firstName',
                    label: formatAccountMessage({ id: 'first.name', defaultMessage: 'First Name' }),
                    value: account?.firstName ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'lastname',
                    name: 'lastName',
                    label: formatAccountMessage({ id: 'last.name', defaultMessage: 'Last Name' }),
                    value: account?.lastName ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'email',
                    name: 'email',
                    label: formatAccountMessage({ id: 'email', defaultMessage: 'E-mail' }),
                    value: account?.email ?? '',
                    type: 'email',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: false,
                },
                {
                    id: 'streetname',
                    name: 'streetName',
                    label: formatMessage({ id: 'street.name', defaultMessage: 'Street Name' }),
                    value: defaultAddress?.streetName ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'streetNumber',
                    name: 'streetNumber',
                    label: formatMessage({ id: 'street.number', defaultMessage: 'Street Number' }),
                    value: defaultAddress?.streetNumber ?? '',
                    type: 'number',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'additionalAddressInfo',
                    name: 'additionalAddressInfo',
                    label: formatAccountMessage({ id: 'Addresss.line11A', defaultMessage: 'Address line 1' }),
                    value: defaultAddress?.additionalAddressInfo ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'postalcode',
                    name: 'postalCode',
                    label: formatAccountMessage({ id: 'postal.codeeA', defaultMessage: 'Postal Code' }),
                    value: defaultAddress?.postalCode ?? '',
                    type: 'number',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'city',
                    name: 'city',
                    label: formatMessage({ id: 'city', defaultMessage: 'City' }),
                    value: defaultAddress?.city ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'country',
                    name: 'country',
                    label: formatMessage({ id: 'country', defaultMessage: 'Country' }),
                    value: defaultAddress?.country ?? 'DE',
                    type: 'text',
                    options: [],
                    defaultValue: 'DE',
                    required: true,
                    editable: false,
                },
            ],
            onSubmit: async () => {
                await updateAddress(data);
            },
        },
    ], [formatAccountMessage, router, data, account]);
    //Put all sections in edit mode
    const startEditAll = useCallback(() => {
        setInEdit(sections.map((_, index) => index));
    }, [sections]);
    //Stop editting all sections
    const stopEditAll = useCallback(() => {
        setInEdit([]);
    }, []);
    //Input change
    const handleChange = useCallback((name, value) => {
        setData({ ...data, [name]: value });
    }, [data]);
    //Handle submission
    const handleSubmit = useCallback(async (index) => {
        sections[index].onSubmit();
        stopEdit(index);
    }, [inEdit, stopEdit, sections]);
    //Submit all sections
    const handleSubmitAll = useCallback(async () => {
        stopEditAll();
        for (const section of sections)
            await section.onSubmit();
    }, [sections, stopEditAll]);
    return (<div className="flex flex-col gap-0 lg:gap-12">
      {sections.map((section, index) => (<div className="rounded-sm border-gray-200 lg:pb-0" key={index}>
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold leading-6 text-black">{section.headline}</h2>
            </div>
            {/* <p className="max-w-2xl py-4 text-sm text-gray-500">{section.subline}</p> */}
          </div>
          <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-1">
            {section.fields.map((field, fieldIndex) => (<Field key={fieldIndex} id={field.id} label={field.label} value={field.value} type={field.type} options={field.options} defaultValue={field.defaultValue} required={field.required} disabled={!field.editable || !inEdit.includes(index)} onChange={(val) => handleChange(field.name, val)}/>))}
            <div className="w-full">
              {inEdit.includes(index) ? (<div className="flex justify-between text-sm">
                  <button type="submit" className="btn btn-primary w-5/12" onClick={() => handleSubmit(index)}>
                    {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                  </button>
                  <button type="button" className="btn btn-primary w-5/12" onClick={() => stopEdit(index)}>
                    {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                  </button>
                </div>) : (<button type="button" className="btn btn-primary w-full" onClick={() => startEdit(index)}>
                  {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                </button>)}
            </div>
          </div>
        </div>))}
    </div>);
};
export default PatientAddress;
