import React from 'react';
import NextLink from 'next/link';
export const getReferenceTarget = (target) => {
    switch (target.type) {
        case 'link':
            return target.link || target.target;
        case 'page-folder':
            return target.pageFolder._url;
        default:
            //Log.warn('Reference ', target, ' is not valid reference')
            return '/';
    }
};
export function getTargetProps(target) {
    if (target?.openInNewWindow) {
        return {
            target: '_blank',
            rel: 'noopener',
        };
    }
    return {};
}
export function isLiveReference(reference) {
    return reference.type !== 'page-folder' || (reference.type === 'page-folder' && reference.pageFolder?.hasLivePage);
}
export const ReferenceLink = ({ target, className, ariaLabel, children }) => {
    //no valid target for next/link
    if (!target)
        return (<NextLink href="#">
        <a aria-label={ariaLabel} className={className}>
          {children}
        </a>
      </NextLink>);
    return (<NextLink href={getReferenceTarget(target)}>
      <a aria-label={ariaLabel} className={className} {...getTargetProps(target)}>
        {children}
      </a>
    </NextLink>);
};
