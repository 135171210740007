export const updateURLParams = (params) => {
    const url = new URL(window.location.href);
    const nextURLParams = new URLSearchParams();
    params.map(({ key, value }) => {
        nextURLParams.set(key, value);
    });
    const updatedURL = `${url.pathname}?${nextURLParams.toString()}`;
    return updatedURL;
};
export const getURLParamByName = (paramName, urlOrigin) => {
    try {
        const url = urlOrigin ? new URL(urlOrigin, window?.location?.origin) : new URL(window?.location?.href);
        const currentURLParams = new URLSearchParams(url.search);
        const theURLParamByName = currentURLParams.get(paramName);
        return theURLParamByName || '';
    }
    catch {
        return null;
    }
};
export const getStepFromURLParams = (key, data) => {
    const currentStep = getURLParamByName(key);
    const stepIndex = data.findIndex((step) => step === currentStep);
    return stepIndex > -1 ? stepIndex : 0;
};
