import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import LoginWithOkta from 'components/login-okta'
import { OktaSettings } from 'helpers/constants/oktaSettings'

const LoginOktaTastic = ({ data }) => {
  const router = useRouter()
  const config = {
    domain: OktaSettings.oktaDomain,
    clientId: OktaSettings.oktaClientId,
    authorizationParams: {
      redirect_uri: `${OktaSettings.hostDomain}`,
      ui_locales: router.locale,
    },
  }

  return (
    <Auth0Provider {...config}>
      <LoginWithOkta {...data} />
    </Auth0Provider>
  )
}

export default LoginOktaTastic
