import { COUNTRY_CODE } from 'helpers/constants/envVariables';
export const getTaxedCountries = (shippingMethods, projectSettingsCountries) => {
    const taxedCountries = [];
    if (COUNTRY_CODE) {
        projectSettingsCountries = [COUNTRY_CODE];
    }
    shippingMethods?.forEach((shippingMethod) => {
        shippingMethod?.rates?.forEach((rate) => {
            rate?.locations?.forEach((location) => {
                if (projectSettingsCountries.includes(location.country) && !taxedCountries.includes(location.country)) {
                    taxedCountries.push(location.country);
                }
            });
        });
    });
    return taxedCountries.sort();
};
