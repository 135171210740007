/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import useValidationOption from './useValidation';
const InputForm = ({ onBeforeChange, onChange = () => { }, onFocus, onBlur, name, inputType = 'text', validationType, min, max, placeholder, label, containerClassName, inputClassName, value, disabled, errorSuffixIcon = 'X', defaultErrorMessage, defaultValidation = false, hookForm }) => {
    const isUseHookForm = !!hookForm;
    const [errorMessage, setErrorMessage] = useState(null);
    const [validInput, setValidInput] = useState(false);
    const { register: formRegister, setValue: formSetValue, formState } = isUseHookForm
        ? hookForm
        : { register: null, setValue: null, formState: null };
    useEffect(() => {
        setErrorMessage(defaultErrorMessage);
        setValidInput(defaultValidation);
    }, []);
    const handleOnChange = (e) => {
        if (onBeforeChange) {
            onBeforeChange(e);
        }
        if (onChange) {
            const { name: inputName, value: newValue } = e.target;
            onChange(inputName, newValue);
        }
        if (formSetValue) {
            const { name: inputName, value: newValue } = e.target;
            formSetValue(inputName, newValue);
        }
    };
    const onFocusCapture = (e) => {
        if (onFocus) {
            onFocus(e);
        }
    };
    const onBlurCapture = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };
    const inputValidation = useValidationOption(validationType);
    useEffect(() => {
        if (isUseHookForm && formState) {
            const { isSubmitted, errors } = formState;
            const currentInputError = getErrorMessageByFormState(errors, name);
            const validInput = isSubmitted && !currentInputError;
            setErrorMessage(getErrorMessageByFormState(errors, name));
            setValidInput(validInput);
        }
    }, [formState]);
    return (<div className={classNames('field-wrapper', {
            'required-field': validationType,
        })}>
      {label && (<div className={classNames('field-label', {
                'field-label--disabled': disabled,
            })}>
          {label}:
        </div>)}
      <div className={classNames('field-item', containerClassName, {
            'valid-init': (!!!errorMessage && !validInput) || !validationType,
            'valid-success': !!!errorMessage && validInput && validationType && validationType !== 'label-required',
            'valid-error': !!errorMessage,
        })}>
        <input id={name} type={inputType} autoComplete={inputType === 'password' ? 'off' : 'off'} min={min} max={max} placeholder={placeholder} className={classNames('field-input', inputClassName)} aria-label="input-field" value={value} onFocusCapture={onFocusCapture} onBlurCapture={onBlurCapture} disabled={disabled} {...(isUseHookForm && formRegister(name, inputValidation))} name={name} onChange={handleOnChange}/>

        {errorMessage && validationType && validationType !== 'label-required' && (<div className="field-message">{errorMessage}</div>)}
      </div>
    </div>);
};
export default InputForm;
// eslint-disable-next-line import/prefer-default-export
export const getErrorMessageByFormState = (errors, nameOfInput) => {
    if (errors && errors[nameOfInput]) {
        return errors[nameOfInput]?.message ?? null;
    }
    return null;
};
