import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
const CartButton = ({ cartItemCount, cartLink }) => {
    //i18n messages
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    return (<div className="flow-root">
      <ReferenceLink target={cartLink} className="group relative flex items-center">
        <Image src="/icons/custom/cart.svg" alt="cart" width="40" height="40" loading="lazy"/>
        {cartItemCount > 0 && (<>
            <span className="item-number">
              <span className="flex h-full w-full items-center justify-center text-xs text-white">
                {cartItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatCartMessage({
                id: 'cart.items.in.view',
                defaultMessage: 'items in cart, view cart',
            })}
            </span>
          </>)}
      </ReferenceLink>
    </div>);
};
export default CartButton;
