import { STEP_IDS } from "types/checkout";
import { isObjectEmpty } from "./objectHelpers";
export const getValidatedCheckoutStep = (cart, currentStepParam) => {
    const isEmptyCart = !cart?.lineItems?.length;
    if (isEmptyCart) {
        return STEP_IDS[0];
    }
    let checkoutStep = currentStepParam;
    switch (currentStepParam) {
        // in payment step
        case STEP_IDS[4]:
            if (!isValidShippingDetails(cart)) {
                checkoutStep = STEP_IDS[3];
            }
            break;
        // for order-summary page
        case 'order-summary':
            if (!isValidShippingDetails(cart)) {
                checkoutStep = STEP_IDS[3];
            }
            else if (!isValidPaymentMethod(cart)) {
                checkoutStep = STEP_IDS[4];
            }
            else {
                checkoutStep = null;
            }
            break;
        default:
            break;
    }
    return checkoutStep;
};
const isValidShippingDetails = (cart) => {
    return !isObjectEmpty(cart.shippingAddress) && !isObjectEmpty(cart.billingAddress);
};
const isValidPaymentMethod = (cart) => {
    return cart?.payments && !!cart?.payments?.[0]?.paymentMethod;
};
