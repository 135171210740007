import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { format, utcToZonedTime } from 'date-fns-tz';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import { getPrescriptionFromIDB, deletePrescription } from 'helpers/idbHelpers';
import { useCustomerName, useShippingAddress } from 'helpers/orderSummary';
import Image from 'frontastic/lib/image';
import { useFormat } from '../../../helpers/hooks/useFormat';
const ThankYouItem = ({ orderDetails, handleLoading }) => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage } = useFormat({ name: 'common' });
    const [transactionDate, setTransactionDate] = useState(null);
    const router = useRouter();
    const [prescription, setPrescription] = useState(null);
    useEffect(() => {
        getPrescriptionFromIDB().then((prescription) => {
            setPrescription(prescription);
            deletePrescription();
        });
    }, []);
    const convertDatetimeToLocale = (utcTime) => {
        const date = new Date(utcTime);
        const timeZone = "Europe/Berlin";
        const zonedDate = utcToZonedTime(date, timeZone);
        // Format the date
        const formattedDate = format(zonedDate, "dd MMM yyyy, HH:mm:ss OOOO", { timeZone, locale: router?.locale === 'en' ? enGB : de });
        setTransactionDate(formattedDate);
    };
    useEffect(() => {
        if (!orderDetails?.payments || orderDetails?.payments?.length === 0) {
            return;
        }
        const lastPayment = orderDetails.payments.at(-1);
        if (!lastPayment?.debug) {
            return;
        }
        const utcTime = JSON.parse(lastPayment.debug)?.lastModifiedAt;
        if (!utcTime) {
            return;
        }
        convertDatetimeToLocale(utcTime);
    }, [orderDetails?.orderId]);
    return (<div className='flex flex-col gap-10'>
      <div className="info-box">
        <h3>{formatCheckoutMessage({ id: 'payment.details', defaultMessage: 'Payment Details' })}</h3>
        <p>{formatCheckoutMessage({ id: `paymentMethod.${orderDetails?.payments?.at(-1)?.paymentMethod}`, defaultMessage: '' })}</p>
        <p>
          {formatCheckoutMessage({ id: 'referenceId', defaultMessage: 'Reference ID:' })}
          &nbsp;
          {orderDetails?.payments?.[0]?.paymentId}
        </p>
        <p>{transactionDate}</p>
      </div>
      <div className="info-box">
        <h3>{formatMessage({ id: 'delivery.address' })}</h3>
        <p>{useCustomerName(orderDetails)}</p>
        <p>{useShippingAddress(orderDetails, false, true)}</p>
      </div>
      <div className="info-box">
        <h3>{formatMessage({ id: 'billing.address' })}</h3>
        <p>{useCustomerName(orderDetails, true)}</p>
        <p>{useShippingAddress(orderDetails, true, true)}</p>
      </div>

      <div className="info-box">
        {prescription && !!orderDetails?.orderId && (<>
            <h3>{formatCheckoutMessage({ id: 'prescription' })}</h3>
            <div className="text-gray-light-75 font-semibold">
              <div className="border-1 mt-5 flex items-center justify-start gap-3 rounded border-[#BEB2B2] bg-[#FFFFFF] p-1 text-center text-sm font-normal">
                {prescription?.name?.slice(-4) === '.pdf' ? (<div className='flex w-full items-center gap-x-1'>
                    <div className='w-[38px]'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
                        <path d="M28.0534 2.39575L34.4868 9.102V34.6043H10.4541V34.6875H34.5689V9.18641L28.0534 2.39575Z" fill="#909090"/>
                        <path d="M27.9737 2.3125H10.3721V34.6043H34.4868V9.10316L27.9737 2.3125Z" fill="white"/>
                        <path d="M10.1951 4.04688H2.80664V11.9406H26.0473V4.04688H10.1951Z" fill="#7A7B7C"/>
                        <path d="M26.1711 11.8065H2.95703V3.90698H26.1711V11.8065Z" fill="#DD2025"/>
                        <path d="M10.6538 5.24245H9.14258V10.7925H10.3312V8.92048L10.5937 8.93551C10.8487 8.93113 11.1013 8.88544 11.3418 8.80023C11.5526 8.72773 11.7465 8.61327 11.9118 8.46376C12.08 8.32134 12.2127 8.14158 12.2991 7.93883C12.4151 7.60178 12.4565 7.24357 12.4205 6.88895C12.4133 6.63563 12.3689 6.38474 12.2887 6.14433C12.2157 5.97078 12.1074 5.81433 11.9707 5.68494C11.8339 5.55555 11.6717 5.45604 11.4944 5.39277C11.3411 5.33726 11.1827 5.29699 11.0215 5.27251C10.8994 5.25368 10.7761 5.24363 10.6526 5.24245M10.4341 7.89489H10.3312V6.18364H10.5544C10.6528 6.17654 10.7517 6.19166 10.8435 6.22788C10.9354 6.2641 11.0179 6.32051 11.0851 6.39292C11.2242 6.57909 11.2985 6.80571 11.2967 7.03811C11.2967 7.32255 11.2967 7.58039 11.04 7.76192C10.8551 7.86363 10.6446 7.91058 10.4341 7.89489ZM14.6787 5.22742C14.5504 5.22742 14.4255 5.23667 14.3376 5.24014L14.0624 5.24708H13.1605V10.7971H14.222C14.6276 10.8082 15.0315 10.7394 15.4106 10.5947C15.7158 10.4737 15.986 10.2786 16.1969 10.027C16.4019 9.77317 16.5491 9.47761 16.6281 9.16098C16.719 8.80239 16.7633 8.4336 16.76 8.0637C16.7824 7.62682 16.7486 7.18887 16.6594 6.76061C16.5747 6.44537 16.4162 6.15484 16.1969 5.91308C16.0248 5.71786 15.8142 5.56039 15.5783 5.45058C15.3757 5.35683 15.1625 5.28773 14.9435 5.24476C14.8564 5.23037 14.7681 5.2238 14.6799 5.22511M14.4694 9.77727H14.3538V6.23452H14.3688C14.6072 6.20709 14.8484 6.2501 15.0626 6.35823C15.2194 6.48349 15.3473 6.64128 15.4372 6.82073C15.5343 7.00959 15.5902 7.21687 15.6014 7.42892C15.6118 7.6833 15.6014 7.89142 15.6014 8.0637C15.6061 8.26216 15.5933 8.46064 15.5632 8.65686C15.5276 8.85831 15.4617 9.05322 15.3678 9.23498C15.2616 9.40398 15.118 9.54637 14.9481 9.65123C14.8055 9.7435 14.6365 9.78654 14.4671 9.7738M20.3409 5.24708H17.5312V10.7971H18.7198V8.59558H20.2229V7.5642H18.7198V6.27845H20.3385V5.24708" fill="#464648"/>
                        <path d="M25.3718 23.4198C25.3718 23.4198 29.0579 22.7515 29.0579 24.0107C29.0579 25.2698 26.7743 24.7576 25.3718 23.4198ZM22.6465 23.5158C22.0609 23.6452 21.4901 23.8347 20.9434 24.0812L21.4059 23.0406C21.8684 22 22.3482 20.5812 22.3482 20.5812C22.9001 21.5101 23.5422 22.3823 24.2653 23.1851C23.72 23.2664 23.1796 23.3776 22.6465 23.5181V23.5158ZM21.1873 16.0002C21.1873 14.9029 21.5423 14.6034 21.8186 14.6034C22.095 14.6034 22.406 14.7364 22.4164 15.6892C22.3264 16.6472 22.1258 17.5916 21.8186 18.5035C21.3979 17.7378 21.1802 16.8772 21.1862 16.0037L21.1873 16.0002ZM15.8119 28.1593C14.6811 27.4829 18.1834 25.4005 18.8182 25.3334C18.8147 25.3346 16.9959 28.8669 15.8119 28.1593ZM30.1344 24.1598C30.1228 24.0442 30.0188 22.7642 27.7409 22.8186C26.7915 22.8033 25.8425 22.8702 24.9047 23.0186C23.9962 22.1033 23.2138 21.0709 22.5783 19.9488C22.9787 18.7919 23.221 17.5862 23.2986 16.3644C23.2651 14.9769 22.9333 14.1814 21.8695 14.193C20.8058 14.2045 20.6508 15.1353 20.7907 16.5205C20.9278 17.4513 21.1863 18.3602 21.5596 19.2238C21.5596 19.2238 21.0682 20.7535 20.4184 22.2752C19.7686 23.7968 19.3246 24.5946 19.3246 24.5946C18.1946 24.9624 17.1309 25.5089 16.1738 26.2133C15.2211 27.1002 14.8337 27.7812 15.3355 28.4622C15.768 29.0496 17.2815 29.1826 18.6343 27.4101C19.3531 26.4946 20.0098 25.5319 20.5999 24.5287C20.5999 24.5287 22.6627 23.9633 23.3044 23.8083C23.9461 23.6534 24.722 23.5308 24.722 23.5308C24.722 23.5308 26.6055 25.4259 28.422 25.3589C30.2384 25.2918 30.1506 24.2732 30.139 24.1622" fill="#DD2025"/>
                        <path d="M27.8848 2.40152V9.19218H34.3979L27.8848 2.40152Z" fill="#909090"/>
                        <path d="M27.9736 2.3125V9.10316H34.4868L27.9736 2.3125Z" fill="#F4F4F4"/>
                        <path d="M10.5649 5.1534H9.05371V10.7034H10.247V8.83259L10.5106 8.84762C10.7656 8.84323 11.0183 8.79755 11.2587 8.71234C11.4695 8.63981 11.6634 8.52535 11.8287 8.37587C11.9957 8.23306 12.1271 8.05334 12.2126 7.85093C12.3286 7.51388 12.37 7.15568 12.334 6.80106C12.3268 6.54773 12.2824 6.29684 12.2022 6.05643C12.1292 5.88289 12.0209 5.72644 11.8841 5.59705C11.7474 5.46765 11.5852 5.36815 11.4078 5.30487C11.2538 5.24883 11.0946 5.20816 10.9326 5.18346C10.8105 5.16463 10.6873 5.15458 10.5638 5.1534M10.3452 7.80584H10.2423V6.09459H10.4666C10.5651 6.08749 10.664 6.10261 10.7558 6.13883C10.8477 6.17506 10.9302 6.23146 10.9974 6.30387C11.1365 6.49004 11.2108 6.71666 11.209 6.94906C11.209 7.2335 11.209 7.49134 10.9523 7.67287C10.7674 7.77458 10.5569 7.82037 10.3464 7.80468M14.5898 5.13837C14.4615 5.13837 14.3366 5.14762 14.2487 5.15109L13.977 5.15803H13.0751V10.708H14.1366C14.5422 10.7191 14.9461 10.6504 15.3252 10.5057C15.6304 10.3846 15.9006 10.1895 16.1115 9.93796C16.3165 9.68412 16.4637 9.38856 16.5427 9.07193C16.6336 8.71334 16.6779 8.34455 16.6746 7.97465C16.697 7.53777 16.6632 7.09982 16.574 6.67156C16.4893 6.35632 16.3308 6.06579 16.1115 5.82403C15.9394 5.62881 15.7288 5.47134 15.4929 5.36153C15.2903 5.26778 15.0772 5.19868 14.8581 5.15572C14.771 5.14132 14.6827 5.13475 14.5945 5.13606M14.384 9.68821H14.2684V6.14546H14.2834C14.5218 6.11804 14.763 6.16105 14.9772 6.26918C15.134 6.39444 15.2619 6.55223 15.3518 6.73168C15.4489 6.92054 15.5048 7.12782 15.516 7.33987C15.5264 7.59425 15.516 7.80237 15.516 7.97465C15.5207 8.17311 15.5079 8.37159 15.4778 8.56781C15.4422 8.76926 15.3763 8.96417 15.2824 9.14593C15.1762 9.31493 15.0326 9.45732 14.8627 9.56218C14.7201 9.65445 14.5511 9.69749 14.3817 9.68475M20.252 5.15803H17.4423V10.708H18.6309V8.50653H20.1341V7.47515H18.6309V6.1894H20.2497V5.15803" fill="white"/>
                      </svg>
                    </div>
                    <p className='overflow-hidden text-ellipsis'>{prescription?.name}</p>
                  </div>) : (<Image src={`${prescription?.content}`} alt="img" className='object-contain'/>)}
              </div>
            </div>
          </>)}
      </div>
    </div>);
};
export default ThankYouItem;
