import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from 'helpers/hooks/useI18n';
import Image from 'frontastic/lib/image';
const EstimatedDeliverDate = (shippingSettings) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { t } = useI18n();
    return (<>
      {shippingSettings?.minDays > 0 && (<div className='flex flex-row items-center gap-1 lg:gap-2'>
          {shippingSettings?.icon && (<Image src={shippingSettings?.icon?.media?.file} alt={t(shippingSettings?.icon?.title ?? '')} loading="lazy" className="h-auto w-[33px] lg:w-[55px] aspect-shipping-logo object-contain"/>)}

          {shippingSettings?.maxDays <= 0 && (<span className='text-xs lg:text-xl text-gray-text'>
              {formatCartMessage({ id: 'estimate.delivery.label', defaultMessage: 'Voraussichtliche Lieferung in' })}
              {' '}
              <b>{formatCartMessage({ id: 'estimate.delivery.onlyMinDays', defaultMessage: '{min} werktagen.', values: { min: shippingSettings.minDays } })}</b>
            </span>)}

          {shippingSettings?.maxDays > 0 && (<span className='text-xs lg:text-xl text-gray-text'>
              {formatCartMessage({ id: 'estimate.delivery.label', defaultMessage: 'Voraussichtliche Lieferung in' })}
              {' '}
              <b>{formatCartMessage({ id: 'estimate.delivery.days', defaultMessage: '{min} - {max} werktagen.', values: { min: shippingSettings.minDays, max: shippingSettings.maxDays } })}</b>
            </span>)}
        </div>)}
    </>);
};
export default EstimatedDeliverDate;
