import classNames from "classnames";
import Checkbox from "components/form/checkbox";
import InputForm from "components/form/inputForm";
import SelectForm from "components/form/selectForm";
import { useFormat } from "helpers/hooks/useFormat";
const BillingAddress = ({ shippingAddressForm, availableCountries, disabled, isHomeAddress }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { register, formState } = shippingAddressForm;
    return (<section className={classNames("rounded-box-container bg-gray-light-75 input-hidden", {
            'input-hidden': disabled,
        })}>
      <div className="flex flex-wrap flex-col items-start justify-between gap-5 lg:flex-row lg:items-center">
        <h2>{formatCommonMessage({ id: 'billing.address' })}</h2>
        {isHomeAddress && (<div className="shrink-0">
              <Checkbox label={formatCommonMessage({
                id: 'billing.same.as.delivery',
                defaultMessage: 'Same as shipping address',
            })} name="isBillingSameAsShipping" formRegister={register}/>
            </div>)}
      </div>
      <div className="form-wrapper lg:mt-15 mt-10">
        <div className="row-2-field">
          <InputForm label={formatCommonMessage({ id: 'Recipient.firstName', defaultMessage: 'First name' })} name={'billingFirstName'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'required' : 'label-required'} disabled={disabled}/>
          <InputForm label={formatCommonMessage({ id: 'Recipient.lastName', defaultMessage: 'Last Name' })} name={'billingLastName'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'required' : 'label-required'} disabled={disabled}/>
        </div>
        <div className="row-2-field">
          <InputForm label={formatCommonMessage({ id: 'street.name', defaultMessage: 'Street name' })} name={'billingStreetName'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'streetName' : 'label-required'} disabled={disabled}/>
          <InputForm label={formatCommonMessage({ id: 'street.number', defaultMessage: 'Street Number' })} name={'billingStreetNumber'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'streetNumber' : 'label-required'} disabled={disabled}/>
        </div>
        <InputForm label={formatCommonMessage({ id: 'addresss.line' })} name={'billingAdditionalStreetInfo'} hookForm={shippingAddressForm} inputType="text" disabled={disabled}/>
        <div className="row-2-field">
          <InputForm label={formatCommonMessage({ id: 'zipCode', defaultMessage: 'Postal code' })} name={'billingPostalCode'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'postalCode' : 'label-required'} disabled={disabled}/>
          <InputForm label={formatCommonMessage({ id: 'city', defaultMessage: 'City' })} name={'billingCity'} hookForm={shippingAddressForm} inputType="text" validationType={!disabled ? 'city' : 'label-required'} disabled={disabled}/>
        </div>
        <div className="row-2-field">
          <SelectForm label={formatCommonMessage({ id: 'country', defaultMessage: 'Country' })} name={'shippingCountry'} formRegister={register} formState={formState} options={availableCountries} validationType={!disabled ? 'required' : 'label-required'} disabled={disabled}/>
        </div>
      </div>
    </section>);
};
export default BillingAddress;
