import React from 'react';
import { useRouter } from 'next/router';
import Dropdown from 'components/commercetools-ui/dropdown';
import { formatLocaleName } from 'helpers/utils/formatLocaleName';
const LanguageSwitcher = ({ className, regionLanguageSelectorTitle, regionAndLanguageGroup, alwaysDisplay, regionLanguageGlobalSelectorJson }) => {
    const router = useRouter();
    const items = router.locales.map((locale) => {
        return { label: formatLocaleName(locale), value: locale };
    });
    const onSelectLanguage = (selectedValue) => {
        router.push(router.asPath, router.asPath, { locale: selectedValue });
    };
    return (<div className={`relative inline-block text-left ${className}`}>
      <Dropdown regionLanguageSelectorTitle={regionLanguageSelectorTitle} regionAndLanguageGroup={regionAndLanguageGroup} defaultValue={router?.locale} items={items} onChange={onSelectLanguage} regionLanguageGlobalSelectorJson={regionLanguageGlobalSelectorJson} alwaysDisplay={alwaysDisplay}/>
    </div>);
};
export default LanguageSwitcher;
