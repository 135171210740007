import { COUNTRY_PHONE_CODE_REGEX } from "components/constants/patterns";
/**
 * Extract phone number into country code and actual phone number
 *
 * @param fullPhoneNumber // (+49) 123456789
 * @returns
 */
export const extractCustomerPhoneNumber = (fullPhoneNumber) => {
    if (!fullPhoneNumber) {
        return ['+49', ''];
    }
    const countryPhoneCode = fullPhoneNumber.match(COUNTRY_PHONE_CODE_REGEX);
    const patientPhoneNumber = fullPhoneNumber.split(' ')?.[1];
    return [countryPhoneCode?.[1] || '+49', patientPhoneNumber || ''];
};
export const shouldUpdateCustomerDetails = (account, patientDetails) => {
    return account.firstName !== patientDetails.patientFirstName
        || account.lastName !== patientDetails.patientLastName
        || account?.custom?.fields?.customerPhone !== `(${patientDetails.patientCountryPhoneCode}) ${patientDetails.patientPhone}`;
};
