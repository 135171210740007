import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import RadioForm from 'components/form/radioForm';
import { LANGUAGE_CODE_LENGTH, LANGUAGE_MODAL_TRIGGER_ID } from 'helpers/constants/languageModal';
import { useFormat } from 'helpers/hooks/useFormat';
import { formatLocaleName } from 'helpers/utils/formatLocaleName';
import Image from 'frontastic/lib/image';
/**
 * We will switch from dropdown to modal as new design
 *
 * @returns
 */
const Dropdown = ({ regionLanguageSelectorTitle, className, label, items, onChange, value, defaultValue, regionAndLanguageGroup, alwaysDisplay, regionLanguageGlobalSelectorJson }) => {
    const [openModal, setOpenModal] = useState(false);
    const [browserLanguage, setBrowserLanguage] = useState('en-US');
    const { formatMessage } = useFormat({ name: 'common' });
    const languageForm = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            language: defaultValue,
        }
    });
    const router = useRouter();
    const { register, handleSubmit, reset } = languageForm;
    const onClickLabel = () => {
        setOpenModal((prev) => !prev);
    };
    const changeLanguage = (data) => {
        onChange(data.language);
        setOpenModal(false);
    };
    const onCancel = () => {
        setOpenModal(false);
        reset();
    };
    const onClickLanguage = (e) => {
        const targetDomain = e.currentTarget.id;
        if (targetDomain.length === LANGUAGE_CODE_LENGTH) {
            onChange(targetDomain);
            return;
        }
        const cleanedDomain = targetDomain.endsWith('/') ? targetDomain.slice(0, -1) : targetDomain;
        const targetPath = router.asPath;
        const cleanedPath = targetPath.startsWith('/') ? targetPath.slice(1) : targetPath;
        const target = cleanedDomain + '/';
        router.push(target);
    };
    const getMappedTranslation = useMemo(() => {
        if (!alwaysDisplay) {
            return null;
        }
        const jsonData = JSON.parse(regionLanguageGlobalSelectorJson);
        const language = browserLanguage.substring(0, 2);
        return jsonData?.[language] ?? jsonData?.['en'];
    }, [browserLanguage]);
    useEffect(() => {
        setBrowserLanguage(navigator.language);
    }, []);
    return (<>
      {!alwaysDisplay && (<button id={LANGUAGE_MODAL_TRIGGER_ID} data-cy='openLanguageModal' className={classNames(className, 'language-selector-CTA flex items-center')} onClick={onClickLabel}>
          {formatLocaleName(defaultValue).toLocaleUpperCase()}
        </button>)}

      <div id='language-modal' className={classNames("language-selector", {
            "language-selector--modal modal": !alwaysDisplay,
            "language-selector--global": alwaysDisplay,
            "show": openModal || alwaysDisplay
        })}>
        <div className={classNames({
            'modal-dialog': !alwaysDisplay
        })}>
          <div className='modal-content'>
            {!alwaysDisplay && (<div className='modal-header'>
                <h3 className='modal-title'>{regionLanguageSelectorTitle}</h3>
                <button className='modal-close' data-cy='closeLanguageModalIcon' type='button' onClick={onCancel}/>
              </div>)}
            <div className='modal-body !bg-none'>
              {alwaysDisplay && (<h1 className='mb-10'>{getMappedTranslation?.title}</h1>)}
              {regionAndLanguageGroup ? (<div className='country-list'>
                  {regionAndLanguageGroup.map((group) => (<div key={group.regionName} className={classNames('country-item', {
                    'country--selected': group.isDefaultRegion && !alwaysDisplay
                })}>
                      <div className="country-wrapper">
                        <div className='country-flag'>
                          <Image media={group?.regionFlagImage} alt='' objectFit='contain'/>
                        </div>
                        <div className='country-name'>{alwaysDisplay
                    ? getMappedTranslation?.regionName?.[group.countryCodeInAlpha2]
                    : group?.regionName}</div>
                      </div>

                      <div className='language-list'>
                        {group?.availableLanguages.map((language) => (<button type='button' key={`${language?.languageCode}_${group.regionName}`} className={classNames('language-item', {
                        'language--selected': language?.languageCode === router.locale && group.isDefaultRegion && !alwaysDisplay,
                        // 'xxx': language?.languageCode !== router.locale || !group.isDefaultRegion
                    })} id={group.isDefaultRegion && !alwaysDisplay ? language?.languageCode : language?.languageDomain} onClick={onClickLanguage}>
                            {alwaysDisplay
                        ? getMappedTranslation?.language?.[language?.languageCode]
                        : language?.languageLabel}
                          </button>))}
                      </div>
                    </div>))}
                </div>) : (<form onSubmit={handleSubmit(changeLanguage)}>
                  {items.map(({ label, value }) => (<RadioForm containerClassName='!bg-transparent !px-0 !py-2 lg:!py-5' labelClassName='h2' key={value} name='language' value={value} label={formatMessage({ id: `language.${label.toLowerCase()}` })} formRegister={register} defaultChecked={defaultValue === value}/>))}

                  <div className='modal-CTA'>
                    <button data-cy='closeLanguageModalButton' type='button' className='btn btn-secondary' onClick={onCancel}>{formatMessage({ id: 'cancel' })}</button>
                    <button data-cy='changeLanguage' type='submit' className='btn btn-primary'>{formatMessage({ id: 'save' })}</button>
                  </div>
                </form>)}
            </div>
          </div>
        </div>
      </div>
    </>);
};
export default Dropdown;
