import React, { useEffect } from 'react'
import Header from 'components/commercetools-ui/header'
import { calculateCartCount } from 'helpers/utils/calculateCartCount'
import { useCart, useWishlist, useAccount } from 'frontastic/provider'

const HeaderTastic = ({ data, previewId }) => {
  const { data: cart } = useCart()
  const { data: wishlist } = useWishlist()
  const { account } = useAccount()

  return (
    <Header
      tagline={data.tagline}
      links={data.links}
      cartItemCount={calculateCartCount(cart?.lineItems) || 0}
      wishlistItemCount={wishlist?.lineItems?.length || 0}
      logo={data.logo}
      mobileLogo={data.mobileLogo}
      rightLogo={data.rightLogo}
      logoLink={data.logoLink}
      account={account}
      accountLink={data.accountLink}
      wishlistLink={data.wishlistLink}
      cartLink={data.cartLink}
      previewId={previewId}
      regionLanguageSelectorTitle={data.regionLanguageSelectorTitle}
      regionAndLanguageGroup={data.regionAndLanguageGroup}
      regionLanguageGlobalSelectorJson={data.regionLanguageGlobalSelectorJson}
    />
  )
}

export default HeaderTastic
