import { useState } from 'react';
import VoucherForm from 'components/voucher';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import Loader from '../loader';
import DetailsSummary from '../order-summary/details-summary';
const CartSummary = ({ cart, onSubmit, showSubmitButton = true, showDiscountsForm = true, submitButtonLabel, disableSubmitButton, termsLink, cancellationLink, privacyLink, accountId, returnRefundLabel, returnRefundContent, paymentImage, }) => {
    //i18n messages
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const [loading, setLoading] = useState(false);
    return (<>
      {loading && <Loader />}
      <section className="col col--right cart--right-box gap-10">
        <DetailsSummary cart={cart}/>
        {showSubmitButton && (<div className="section--CTA">
            <button data-cy="cartProceedButton" type="submit" onClick={onSubmit} className="btn btn-primary w-full">
              {submitButtonLabel || formatCommonMessage({ id: 'confirm' })}
            </button>
          </div>)}
        <VoucherForm cart={cart} setLoading={setLoading} accountId={accountId}/>

        <div className="section--accept-payement">
          <h3 className="mb-3 text-gray-dark">
            {formatCartMessage({ id: 'payment.accept', defaultMessage: 'We Accept' })}
          </h3>
          <Image media={paymentImage} alt="" objectFit="contain" priority loading={'eager'}/>
        </div>

        <div className="section--policy">
          <h3 className="mb-2 text-gray-dark">{returnRefundLabel}</h3>
          <p className="text-base lg:text-2xl">{returnRefundContent}</p>
        </div>
      </section>
    </>);
};
export default CartSummary;
