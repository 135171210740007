import Loader from "components/commercetools-ui/loader";
import { HOME } from "helpers/constants/statisURLs";
import Redirect from "helpers/redirect";
import { useAccount } from "frontastic";
const AuthFrontastic = ({ children }) => {
    const { loggedIn, isValidating } = useAccount();
    if (!loggedIn && !isValidating) {
        return <Redirect target={`${HOME}`}/>;
    }
    return (<>
      {isValidating && <Loader />}
      {!isValidating && loggedIn && children}
    </>);
};
export default AuthFrontastic;
