export const getSubtotalPrice = (data, isCart = true) => data?.lineItems?.reduce((previousValue, item) => {
    if (item.discountedPrice) {
        return previousValue + item.discountedPrice.centAmount * item.count;
    }
    return previousValue + item.price.centAmount * item.count;
}, 0);
export const getTotalDiscounts = (data) => data?.lineItems?.reduce((previousValue, item) => {
    if (item.discountedPrice) {
        return previousValue + (item.price.centAmount - item.discountedPrice.centAmount) * item.count;
    }
    let totalDiscount = 0;
    item?.discounts?.forEach((discount) => {
        totalDiscount += discount?.discountedAmount?.centAmount ?? 0;
    });
    return previousValue + totalDiscount * item.count;
}, 0);
