import React from 'react';
import classnames from 'classnames';
const Checkbox = ({ name, label, dataCy, disabled = false, value, containerClassName, inputClassName, labelClassName, selected, defaultChecked, formRegister, formInputOptions = {}, onClick, }) => {
    const isUseHookForm = !!(formRegister);
    return (<div data-cy={dataCy} className={classnames(containerClassName, 'checkbox-radio-wrapper')}>
      <input className={classnames(inputClassName, 'checkbox')} {...(isUseHookForm && formRegister(name, formInputOptions))} id={`${name}_${value}`} name={name} type="checkbox" disabled={disabled} value={value} checked={selected} defaultChecked={defaultChecked} onClick={onClick}/>
      <label htmlFor={`${name}_${value}`} className={classnames(labelClassName)}>
        <p>{label}</p>
      </label>
    </div>);
};
export default Checkbox;
