import React, { useEffect, useState } from 'react';
import router from 'next/router';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { redirectLoginUrl } from 'helpers/auth0Helper';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart, useVoucherify } from 'frontastic';
const VoucherForm = ({ cart, setLoading, accountId }) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const [voucherCode, setVoucherCode] = useState(cart?.custom?.fields?.discount_codes ? cart?.custom?.fields?.discount_codes[0] : '');
    const [voucherAmount, setVoucherAmount] = useState();
    const { addCoupon } = useCart();
    const { validateVoucher } = useVoucherify();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [voucherVerified, setVoucherVerified] = useState(false);
    const handleVoucherCodeChange = (e) => {
        setErrorMessage('');
        setSuccessMessage('');
        setVoucherCode(e.target.value);
    };
    const handleVoucherValidate = () => {
        setLoading(true);
        validateVoucher(voucherCode, accountId)
            .then((result) => {
            if (result.valid) {
                setErrorMessage('');
                setVoucherVerified(true);
                setVoucherAmount(result.discount);
            }
            else {
                setErrorMessage(formatErrorMessage({ id: 'voucherr.msg' }));
                setLoading(false);
            }
        })
            .catch((error) => {
            setLoading(false);
            setErrorMessage(formatErrorMessage({ id: 'voucherr.msg' }));
        });
    };
    useEffect(() => {
        if (voucherVerified && voucherAmount) {
            voucherAmount.type === 'AMOUNT'
                ? addCoupon(voucherCode, 'absolute', voucherAmount.amount_off, cart?.custom?.fields['prescription-documents-1'] || '')
                    .then(() => {
                    setSuccessMessage(formatErrorMessage({ id: 'voucherr.sucess' }));
                    setLoading(false);
                })
                    .catch(() => {
                    setErrorMessage(formatErrorMessage({ id: 'voucherr.msg' }));
                    setLoading(false);
                })
                : addCoupon(voucherCode, 'relative', voucherAmount.percent_off, cart?.custom?.fields['prescription-documents-1'] || '')
                    .then(() => {
                    setSuccessMessage(formatErrorMessage({ id: 'voucherr.sucess' }));
                    setLoading(false);
                })
                    .catch(() => {
                    setErrorMessage(formatErrorMessage({ id: 'voucherr.msg' }));
                    setLoading(false);
                });
        }
    }, [voucherAmount, voucherVerified]);
    const onClickVoucherCTA = () => {
        if (accountId) {
            handleVoucherValidate();
        }
        else {
            router.push(redirectLoginUrl(router?.asPath, router.locale), undefined, { locale: false });
        }
    };
    return (<div className="section--voucher">
      <div className="relative">
        <Disclosure>
          {({ open }) => (<>
              <Disclosure.Button className="w-full">
                <div className="flex justify-between">
                  <h3 className="underline transition"> {formatCartMessage({ id: 'voucher.optional' })}</h3>
                  <ChevronDownIcon className={`${open ? 'rotate-180 transform' : ''} h-7 w-7 text-green-dark transition`}/>
                </div>
              </Disclosure.Button>
              <Transition enter="transition duration-150 ease-out" enterFrom="transform scale-y-95 opacity-0" enterTo="transform scale-y-100 opacity-100" leave="transition duration-100 ease-out" leaveFrom="transform scale-y-100 opacity-100" leaveTo="transform scale-y-95 opacity-0">
                <Disclosure.Panel className="text-gray-500">
                  <div className="relative my-3 flex items-center justify-between gap-5 lg:my-5">
                    <input id="Voucher" name="email" type="text" value={voucherCode} onChange={handleVoucherCodeChange} disabled={!accountId} placeholder={formatCartMessage({ id: 'voucher.enter', defaultMessage: 'Enter Voucher Code' })} className="input-form block w-4/6 appearance-none px-3 py-2 shadow-sm placeholder:text-gray-dark disabled:bg-gray-light disabled:hover:cursor-not-allowed"/>
                    <button data-cy="voucherSubmit" type="button" onClick={onClickVoucherCTA} className="btn btn-primary">
                      {`${accountId
                ? formatCartMessage({ id: 'voucher.apply' })
                : formatCartMessage({ id: 'voucher.login' })}
                      `}
                    </button>
                    {errorMessage && (<span className="absolute top-full left-0 py-2 text-xs text-system-error">{errorMessage}</span>)}
                    {successMessage && (<span className="absolute top-full left-0 py-2 text-xs text-system-success">
                        {successMessage}
                      </span>)}
                  </div>
                  {!accountId && (<p className="absolute top-full left-0 py-2 text-xs text-system-error">
                      {formatCartMessage({
                    id: 'voucher.remind.login',
                    defaultMessage: 'Please log in to add a Voucher',
                })}
                    </p>)}
                </Disclosure.Panel>
              </Transition>
            </>)}
        </Disclosure>
      </div>
    </div>);
};
export default VoucherForm;
