/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useFormat } from 'helpers/hooks/useFormat';
import { MAXIMUM_FILE_UPLOAD, MAXIMUM_NUMBER_OF_FILES } from "types/checkout";
import Image from 'frontastic/lib/image';
const PrescriptionUpload = ({ prescriptionUploadTitle, validationTitle, prescriptionRules, prescription, handleSetPrescription }) => {
    const [fileError, setFileError] = useState(null);
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    // Convert heic file to jpeg
    const handleHeicFile = async (file) => {
        if (file.type.includes('heic')) {
            const fileName = file.name.replace('.heic', '');
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const heic2any = require('heic2any');
            const convertedFile = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.5,
            })
                .then((conversionResult) => {
                conversionResult['name'] = `${fileName}.jpeg`;
                return conversionResult;
            })
                .catch((e) => {
                //Handle error
            });
            return convertedFile;
        }
        return file;
    };
    // const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    //   let binary = ''
    //   const bytes = new Uint8Array(buffer)
    //   const len = bytes.byteLength
    //   for (let i = 0; i < len; i += 1) {
    //     binary += String.fromCharCode(bytes[i])
    //   }
    //   return Buffer.from(binary, 'utf8').toString('base64')
    // }
    const readFile = (uploadedFile) => {
        const fileReader = new FileReader();
        fileReader.onload = function onload() {
            const fileContent = fileReader.result;
            uploadedFile['content'] = fileContent;
            handleSetPrescription(uploadedFile);
        };
        fileReader.readAsDataURL(uploadedFile);
    };
    // Process uploaded file
    const handleFileChange = async (files) => {
        setFileError(null);
        const selectedFile = files?.[0];
        // We only allow one file to be uploaded
        if (selectedFile) {
            const convertedFile = await handleHeicFile(selectedFile);
            readFile(convertedFile);
        }
    };
    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles);
    }, []);
    const onDropRejected = useCallback((rejectedFiles) => {
        const rejectedFile = rejectedFiles?.[0];
        switch (true) {
            case rejectedFile?.errors?.[0]?.code === 'file-too-large':
                setFileError(formatCheckoutMessage({ id: "prescription.upload.exceed", defaultMessage: "File size exceeds the 10MB limit." }));
                break;
            default:
                setFileError(formatCheckoutMessage({ id: "prescription.upload.invalid", defaultMessage: "Invalid file format." }));
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            // 'image/*': ['.jpeg', '.jpg', '.png', '.heic'],
            'image/jpeg': [],
            'image/png': [],
            'image/heic': [],
            'image/jpg': [],
            'application/pdf': ['.pdf']
        },
        maxSize: MAXIMUM_FILE_UPLOAD,
        maxFiles: MAXIMUM_NUMBER_OF_FILES,
        onDrop,
        onDropRejected
    });
    // Remove prescription
    const handleRemove = () => {
        handleSetPrescription(null);
    };
    const router = useRouter();
    const locale = router?.locale;
    return (<div className="flex flex-col gap-5 lg:mt-5">
      <h2 className='lg:mb-5'>{prescriptionUploadTitle}</h2>
      <div className='rounded-box-container border-primary order-2 border lg:order-1'>
        <h3 className='text-gray-dark font-medium'>{validationTitle}</h3>
        <ul className='list-li text-gray-dark mt-3'>
          {prescriptionRules?.map((rule, index) => (<React.Fragment key={`prescription-rule-${index}`}>
              <li>{rule?.rule}</li>
            </React.Fragment>))}
        </ul>
      </div>
      <div className={classNames("order-1 lg:order-2 relative rounded-box-container border border-dashed border-gray-dark flex h-52", {
            "hidden": prescription
        })} {...getRootProps()}>
        <label htmlFor="prescriptionUpload" className="flex h-full w-full cursor-pointer items-center justify-center">
          <div className='hidden lg:block'>
            <Image src={'/icons/custom/save.svg'} alt=''/>
          </div>
          <div className='block lg:hidden'>
            <Image src={'/icons/custom/camera.svg'} alt=''/>
          </div>
          &nbsp;
          {isDragActive ? (<p className='text-xl'>{formatCheckoutMessage({ id: 'prescription.upload.drop.here', defaultMessage: 'Drop the file here' })}</p>) : (<>
                <div className={classNames('hidden lg:flex', {
                'flex-row-reverse': locale === 'de'
            })}>
                  <p className='text-xl'>{formatCheckoutMessage({ id: 'prescription.upload.drag.drop', defaultMessage: 'Drag and drop' })}</p>
                  &nbsp;
                  <p className='text-xl'>{formatMessage({ id: 'or', defaultMessage: 'or' })}</p>
                  &nbsp;
                  <p className='text-green-dark text-xl font-bold underline'>{formatCheckoutMessage({ id: 'prescription.upload.browse', defaultMessage: 'Browse file' })}</p>
                </div>
                <p className='text-green-dark text-xl font-bold underline lg:hidden'>{formatCheckoutMessage({ id: 'prescription.upload.browse', defaultMessage: 'Browse file' })}</p>
              </>)}
        </label>
        <input {...getInputProps()}/>
        {fileError && <p className='text-system-error absolute top-full left-0 mt-3 text-sm font-bold'>{fileError}</p>}
      </div>
      {prescription &&
            (<div className="rounded-box-container bg-green-light-20 order-1 flex items-center lg:order-2">
            {!prescription?.name.includes('pdf') && (<div className='w-24'>
                <Image src={`${prescription?.content}`} layout="fill" alt="img"/>
              </div>)}
            <p className={classNames('max-w-1/2 truncate', {
                    'mx-5': !prescription?.name.includes('pdf'),
                })}>
              {prescription.name}
            </p>
            <button type="button" name={prescription.name} className="text-green-dark ml-auto text-2xl" onClick={handleRemove}>
              &#10005;
            </button>
          </div>)}
      <button className={classNames([
            'order-3 mt-5 btn btn-primary min-w-fit w-full lg:w-1/2'
        ])} data-cy='prescriptionSubmit' type="submit" disabled={!prescription}>
        {formatMessage({ id: 'confirm', defaultMessage: 'Confirm' })}
      </button>
    </div>);
};
export default PrescriptionUpload;
