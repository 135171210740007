import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import AccountDetails, { AccountDetailsProps } from 'components/commercetools-ui/account/details'
import Loader from 'components/commercetools-ui/loader'
import { OktaSettings } from 'helpers/constants/oktaSettings'
import { useFormat } from 'helpers/hooks/useFormat'
import Redirect from 'helpers/redirect'
import { useAccount } from 'frontastic'

export interface Props {
  data: AccountDetailsProps
}

const AccountDetailsTastic: React.FC<Props> = ({ data }) => {
  //I18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' })

  //account data
  const { loggedIn, account, isValidating } = useAccount()

  const router = useRouter()
  const config = {
    domain: OktaSettings.oktaDomain,
    clientId: OktaSettings.oktaClientId,
    authorizationParams: {
      redirect_uri: `${OktaSettings.hostDomain}`,
      ui_locales: router.locale,
    },
  }

  //user not logged in
  if (!loggedIn && !isValidating) return <Redirect target={'/'} />

  return (
    <>
      {isValidating ? (
        <Loader />
      ) : (
        <Auth0Provider {...config}>
          <AccountDetails {...data} />
        </Auth0Provider>
      )}
    </>
  )
}

export default AccountDetailsTastic
