/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import useValidationOption from './useValidation';
const PhoneNumberForm = ({ onBeforeChange, onChange = () => { }, onFocus, onBlur, name, 
// formRegister,
// formSetValue,
// formSetFocus,
// formGetValues,
// formWatch,
// formState,
inputType = 'number', validationType = 'tel', min, max, label, containerClassName, inputClassName, value, disabled, defaultErrorMessage, defaultValidation = false, phoneMapping, countryPhoneCodeName, selectClassName, hookForm }) => {
    const isUseHookForm = !!hookForm;
    const [errorMessage, setErrorMessage] = useState(null);
    const [validInput, setValidInput] = useState(false);
    const [countryPhoneCode, setCountryPhoneCode] = useState(phoneMapping[0]);
    const { register: formRegister, setValue: formSetValue, formState, watch: formWatch } = isUseHookForm
        ? hookForm
        : { register: null, setValue: null, formState: null, watch: null };
    useEffect(() => {
        setErrorMessage(defaultErrorMessage);
        setValidInput(defaultValidation);
    }, []);
    const handleOnChange = (e) => {
        if (onBeforeChange) {
            onBeforeChange(e);
        }
        if (onChange) {
            const { name: inputName, value: newValue } = e.target;
            onChange(inputName, newValue);
        }
        if (formSetValue) {
            const { name: inputName, value: newValue } = e.target;
            formSetValue(inputName, newValue);
        }
    };
    const onFocusCapture = (e) => {
        if (onFocus) {
            onFocus(e);
        }
    };
    const onBlurCapture = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };
    const watchField = formWatch(countryPhoneCodeName);
    useEffect(() => {
        const countryPhoneCode = phoneMapping.find((phoneCode) => phoneCode?.countryCode === watchField);
        setCountryPhoneCode(countryPhoneCode);
    }, [watchField]);
    useEffect(() => {
        if (isUseHookForm && formState) {
            const { isSubmitted, errors, touchedFields } = formState;
            const currentInputError = getErrorMessageByFormState(errors, name);
            const validInput = isSubmitted && !currentInputError;
            setErrorMessage(getErrorMessageByFormState(errors, name));
            setValidInput(validInput);
        }
    }, [formState]);
    const inputValidation = useValidationOption(validationType);
    return (<div className={classNames('field-wrapper', {
            'required-field': validationType
        })}>
      {label && (<div className='field-label'>{label}:</div>)}
      <div className={classNames("field-item field-item--phone", containerClassName, {
            'valid-init': !!!errorMessage && !validInput,
            'valid-success': !!!errorMessage && validInput,
            'valid-error': !!errorMessage,
        })}>
        <select className={classNames('field-select', selectClassName)} disabled={disabled} aria-label="country-code-select" 
    // value={countryPhoneCode}
    {...(isUseHookForm && formRegister(countryPhoneCodeName))}>
          {phoneMapping.map((phoneCode) => (<option key={phoneCode?.countryCode} value={phoneCode?.countryCode}>{phoneCode?.countryCode}</option>))}
        </select>
        <input id={name} type='text' autoComplete={inputType === 'password' ? 'off' : 'off'} min={min} max={max} placeholder={countryPhoneCode?.countryPhonePlaceholder} className={classNames('field-input', inputClassName)} value={value} onFocusCapture={onFocusCapture} onBlurCapture={onBlurCapture} disabled={disabled} {...(isUseHookForm && formRegister(name, inputValidation))} name={name} onChange={handleOnChange}/>
        {errorMessage && (<div className='field-message'>{errorMessage}</div>)}
      </div>
    </div>);
};
export default PhoneNumberForm;
// eslint-disable-next-line import/prefer-default-export
export const getErrorMessageByFormState = (errors, nameOfInput) => {
    if (errors && errors[nameOfInput]) {
        return errors[nameOfInput]?.message ?? null;
    }
    return null;
};
