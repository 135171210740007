import { DEFAULT_LOCALE } from 'helpers/constants/envVariables';
export const getLocaleUri = (locale) => {
    if (locale === DEFAULT_LOCALE) {
        return '';
    }
    return `/${locale}`;
};
export const redirectLoginUrl = (returnToUri, locale) => {
    if (returnToUri) {
        return `/api/auth/login?returnTo=${getLocaleUri(locale)}${returnToUri}&locale=${locale}`;
    }
    return `/api/auth/login?locale=${locale}`;
};
export const redirectLogoutUrl = (locale) => {
    return `/api/auth/logout?locale=${locale}`;
};
