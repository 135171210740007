import React from 'react'
import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import Checkout from 'components/checkout'
import { OktaSettings } from 'helpers/constants/oktaSettings'
import { ICheckout } from 'types/checkout'

const CheckoutTastic: React.FC<ICheckout> = ({ data }) => {
  const router = useRouter()
  const currentStep = router.query.step

  const config = {
    domain: OktaSettings.oktaDomain,
    clientId: OktaSettings.oktaClientId,
    authorizationParams: {
      redirect_uri: `${OktaSettings.hostDomain}`,
      ui_locales: router.locale,
    },
  }

  return (
    <Auth0Provider {...config}>
      <div className={`page-container checkout-page step--${currentStep}`}>
        <Checkout checkoutTasticData={data} />
      </div>
    </Auth0Provider>
  )
}

export default CheckoutTastic