export const getShippingMethod = (shippingMethods, shippingCountry) => {
    const suitableShippingMethods = [];
    shippingMethods?.forEach((shippingMethod) => {
        shippingMethod?.rates?.forEach((rate) => {
            rate?.locations?.forEach((location) => {
                if (shippingCountry === location.country) {
                    suitableShippingMethods.push(shippingMethod);
                }
            });
        });
    });
    return suitableShippingMethods[0];
};
