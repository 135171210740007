import React, { useCallback, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import Field from '../field';
import PatientAddress from './patient-address';
const General = () => {
    //i18n messages
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    //next/router
    const router = useRouter();
    //account data
    const { account, update } = useAccount();
    //Form data
    const [data, setData] = useState({
        firstName: account?.firstName ?? '',
        lastName: account?.lastName ?? '',
        email: account?.email ?? '',
        locale: router.locale || router.defaultLocale,
    });
    //sections in edit mode
    const [inEdit, setInEdit] = useState([]);
    const startEdit = useCallback((index) => {
        setInEdit([...inEdit, index]);
    }, [inEdit]);
    const stopEdit = useCallback((index) => {
        setInEdit(inEdit.filter((i) => i !== index));
    }, [inEdit]);
    const sections = useMemo(() => [
        {
            headline: formatAccountMessage({ id: 'personal.details', defaultMessage: 'Personal Details' }),
            fields: [
                {
                    id: 'firstname',
                    name: 'firstName',
                    label: formatAccountMessage({ id: 'first.name', defaultMessage: 'First Name' }),
                    value: account?.firstName ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'lastname',
                    name: 'lastName',
                    label: formatAccountMessage({ id: 'last.name', defaultMessage: 'Last Name' }),
                    value: account?.lastName ?? '',
                    type: 'text',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: true,
                },
                {
                    id: 'email',
                    name: 'email',
                    label: formatMessage({ id: 'email', defaultMessage: 'Email' }),
                    value: account?.email ?? '',
                    type: 'email',
                    options: [],
                    defaultValue: '',
                    required: true,
                    editable: false,
                },
            ],
            onSubmit: async () => {
                await update({ firstName: data.firstName, lastName: data.lastName });
            },
        },
    ], [formatAccountMessage, router, data, account]);
    //Put all sections in edit mode
    const startEditAll = useCallback(() => {
        setInEdit(sections.map((_, index) => index));
    }, [sections]);
    //Stop editting all sections
    const stopEditAll = useCallback(() => {
        setInEdit([]);
    }, []);
    //Input change
    const handleChange = useCallback((name, value) => {
        setData({ ...data, [name]: value });
    }, [data]);
    //Handle submission
    const handleSubmit = useCallback(async (index) => {
        sections[index].onSubmit();
        stopEdit(index);
    }, [inEdit, stopEdit, sections]);
    //Submit all sections
    const handleSubmitAll = useCallback(async () => {
        stopEditAll();
        for (const section of sections)
            await section.onSubmit();
    }, [sections, stopEditAll]);
    return (<div className="profile_b flex flex-col gap-10 sm:w-3/4 lg:w-4/6 lg:gap-8">
      {sections.map((section, index) => (<div className="rounded-sm border-gray-200 pt-10 lg:py-0" key={index}>
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold leading-6 text-black ">{section.headline}</h2>
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-1">
            {section.fields.map((field, fieldIndex) => (<Field key={fieldIndex} id={field.id} label={field.label} value={field.value} type={field.type} options={field.options} defaultValue={field.defaultValue} required={field.required} disabled={!field.editable || !inEdit.includes(index)} onChange={(val) => handleChange(field.name, val)}/>))}
            <div className="w-full">
              {inEdit.includes(index) ? (<div className="text-sm flex justify-between">
                  <button type="submit" className="btn btn-primary w-5/12 " onClick={() => handleSubmit(index)}>
                    {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                  </button>
                  <button type="button" className="btn btn-primary w-5/12" onClick={() => stopEdit(index)}>
                    {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                  </button>
                </div>) : (<button type="button" className="btn btn-primary w-full" onClick={() => startEdit(index)}>
                  {formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
                </button>)}
            </div>
          </div>
        </div>))}
      <PatientAddress />
    </div>);
};
export default General;
