import axios from 'axios';
import { BACKEND_BASE_URL, COMMERCETOOLS_BASE_URL } from '../../helpers/constants/envVariables';
export const backendAPIClient = axios.create({
    baseURL: BACKEND_BASE_URL, // Set your API base URL here
    // timeout: 5000,
});
export const commercetoolsAPIClient = axios.create({
    baseURL: COMMERCETOOLS_BASE_URL, // Set your API base URL here
    // timeout: 5000,
});
