import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import CheckoutSteps from 'components/checkout/checkout-steps';
import Checkbox from 'components/form/checkbox';
import Modal from 'components/modal';
import { getValidatedCheckoutStep } from 'helpers/checkoutStepHelpers';
import { CHECKOUT, CHECKOUT_STEP_PARAM } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { deletePrescription, getPrescriptionFromIDB } from 'helpers/idbHelpers';
import { STEP_IDS } from 'types/checkout';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { CartError } from '../../../nerivio-ui/cart/errors/CartError';
import { PaymentResponseError } from '../../../nerivio-ui/checkout/errors/PaymentResponseError';
import { PaymentHandler } from '../../../nerivio-ui/checkout/utils/PaymentHandler';
import EmptyCart from '../cart/emptyCart';
import ItemList from '../cart/itemList';
import Loader from '../loader';
import DetailsSummary from './details-summary';
const OrderSummary = ({ tasticData }) => {
    const { data: cart, getPaymentMethods, removeItem, updateItem, getShippingSettings, isValidating: isCartLoading } = useCart();
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activePaymentMethod, setActivePaymentMethod] = useState(null);
    const [checkStatus, setCheckStatus] = useState(true);
    const [refreshRequired, setRefreshRequired] = useState(false);
    const [paymentSyncExecuted, setPaymentSyncExecuted] = useState(false);
    const [returnedFromPsp, setReturnedFromPsp] = useState(false);
    const [lastCheckoutError, setStateLastCheckoutError] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const setLastCheckoutError = useCallback((checkoutError) => setStateLastCheckoutError(checkoutError || false), []);
    const [prescription, setPrescription] = useState(null);
    const [loadingPrescription, setLoadingPrescription] = useState(true);
    const [shippingSettings, setShippingSettings] = useState(null);
    useEffect(() => {
        getShippingSettings().then((shippingSettings) => {
            setShippingSettings(shippingSettings);
        });
        return () => {
            setShippingSettings(null);
        };
    }, []);
    const checkoutAggrement = useForm({
        defaultValues: {
            agreement: false,
        },
    });
    const { register, watch } = checkoutAggrement;
    const watchAgreement = watch('agreement');
    const steps = [
        {
            number: 0,
            id: STEP_IDS[0],
            label: tasticData?.checkoutSteps?.[0]?.label,
        },
        {
            number: 1,
            id: STEP_IDS[1],
            notClickableStep: true,
            label: tasticData?.checkoutSteps?.[1]?.label,
        },
        {
            number: 2,
            id: STEP_IDS[2],
            label: tasticData?.checkoutSteps?.[2]?.label,
        },
        {
            number: 3,
            id: STEP_IDS[3],
            label: tasticData?.checkoutSteps?.[3]?.label,
        },
        {
            number: 4,
            id: STEP_IDS[4],
            label: tasticData?.checkoutSteps?.[4]?.label,
            lastStep: true,
        },
        {
            number: 5,
            label: '',
            id: 'order-summary',
        },
    ];
    const closeModal = () => {
        setStateLastCheckoutError(false);
    };
    const currentPayment = useRef();
    const { isValidating, refreshCart, triggerPayment, syncCartPayment } = useCart();
    useEffect(() => {
        if (refreshRequired) {
            refreshCart().then(() => {
                updatePaymentMethod(null);
                setRefreshRequired(false);
            });
        }
    }, [refreshRequired, refreshCart]);
    useEffect(() => {
        if (!cart?.payments) {
            return;
        }
        const cartPaymentMethod = PaymentHandler.getMolliePayment(cart);
        if (!cartPaymentMethod) {
            return;
        }
        // Notification from mollie may take some time, so if we return from mollie and the payment is still pending we
        // try "refresh" the payment details (hoping mollie sent the required update in a reasonable time frame)
        if (returnedFromPsp && !paymentSyncExecuted && PaymentHandler.isPaymentPending(cartPaymentMethod)) {
            setCheckStatus(true);
            const synchronize = async () => {
                try {
                    await syncCartPayment();
                }
                catch (error) {
                    mutate('/action/cart/getCart');
                    updatePaymentMethod(cartPaymentMethod);
                    setLastCheckoutError(PaymentHandler.getCheckoutResponseError(error));
                }
                finally {
                    setPaymentSyncExecuted(true);
                    setReturnedFromPsp(false);
                }
            };
            synchronize();
            return;
        }
        updatePaymentMethod(cartPaymentMethod);
    }, [cart?.payments]);
    useEffect(() => {
        currentPayment.current = activePaymentMethod;
        switch (true) {
            case activePaymentMethod === null:
                if (PaymentHandler.isRedirectFromMollie(router.query)) {
                    const { method } = router.query;
                    if (PaymentHandler.isNonInteractiveType(method)) {
                        router.push({ pathname: '/thank-you', query: { method: method } });
                        return;
                    }
                }
                setCheckStatus(false);
                break;
            case PaymentHandler.isPaymentSettled(activePaymentMethod):
                setCheckStatus(true);
                router.push('/thank-you');
                break;
            case PaymentHandler.isPaymentPending(activePaymentMethod):
                let isRedirecting = false;
                if (!returnedFromPsp || paymentSyncExecuted) {
                    const redirectUrl = PaymentHandler.getCheckoutUrl(activePaymentMethod);
                    if (!!redirectUrl) {
                        isRedirecting = true;
                        router.push(redirectUrl);
                    }
                }
                setCheckStatus(false);
                // setIsSubmitting(isRedirecting)
                break;
            case PaymentHandler.isPaymentUnresolved(activePaymentMethod):
                setLastCheckoutError(new PaymentResponseError(`Unresolved payment - ${activePaymentMethod.paymentId}`));
                setRefreshRequired(true);
                break;
            case PaymentHandler.isPaymentFailed(activePaymentMethod):
                setLastCheckoutError(new PaymentResponseError(`Failed payment - ${activePaymentMethod.paymentId}`));
                setCheckStatus(false);
                break;
            default:
                setCheckStatus(false);
                break;
        }
    }, [activePaymentMethod]);
    useEffect(() => {
        setReturnedFromPsp(PaymentHandler.isRedirectFromMollie(router.query));
    }, [cart?.cartId]);
    useEffect(() => {
        getPaymentMethods().then((paymentMethods) => {
            if (paymentMethods) {
                setPaymentMethods(paymentMethods?.map((paymentMethod) => ({
                    paymentMethod: paymentMethod.paymentMethodId,
                    image: paymentMethod.image,
                })));
            }
        });
        return () => {
            setPaymentMethods(null);
        };
    }, []);
    const updatePaymentMethod = (paymentMethod) => {
        const refPayment = currentPayment.current || null;
        if (refPayment === null || refPayment.id !== paymentMethod?.id) {
            changePaymentMethod(paymentMethod);
        }
    };
    const changePaymentMethod = (paymentMethod) => {
        if (!isSubmitting) {
            setCheckStatus(true);
            setActivePaymentMethod(paymentMethod);
        }
    };
    const submitOrder = async () => {
        let isRedirecting = false;
        setIsSubmitting(true);
        setLoading(true);
        try {
            const payment = await triggerPayment();
            const redirectUrl = PaymentHandler.getCheckoutUrl(payment);
            if (redirectUrl) {
                if (redirectUrl === router.query['path'] || redirectUrl === router.asPath) {
                    setRefreshRequired(true);
                }
                else {
                    isRedirecting = true;
                    await router.push(redirectUrl);
                    return;
                }
            }
            else {
                setLastCheckoutError(new PaymentResponseError('Failed to initialize payment'));
            }
        }
        catch (error) {
            mutate('/action/cart/getCart');
            setLastCheckoutError(error instanceof CartError ? error : PaymentHandler.getCheckoutResponseError(error));
        }
        finally {
            setLoading(false);
            setIsSubmitting(isRedirecting);
        }
    };
    const getPaymentImage = useCallback(() => {
        if (!paymentMethods || !cart?.payments) {
            return null;
        }
        if (!cart?.payments || cart?.payments?.length === 0) {
            return null;
        }
        const lastPayment = cart.payments.at(-1);
        if (!lastPayment) {
            return null;
        }
        const paymentData = paymentMethods.find((paymentMethod) => paymentMethod.paymentMethod === lastPayment.paymentMethod);
        if (!paymentData) {
            return null;
        }
        return (<>
        <Image src={paymentData?.image} alt=""/>
      </>);
    }, [paymentMethods, cart?.payments?.[0]?.paymentMethod]);
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatCheckout } = useFormat({ name: 'checkout' });
    const checkoutStepNavigation = (target) => {
        const targetStep = steps[target]?.id;
        router.push(`${CHECKOUT}?${CHECKOUT_STEP_PARAM}=${targetStep}`);
    };
    useEffect(() => {
        if (loadingPrescription || isCartLoading || !isCartLoading && !cart?.cartId) {
            return;
        }
        let checkoutStep = null;
        // Check if file is submitted
        if (!prescription) {
            checkoutStep = STEP_IDS[2];
        }
        else {
            // otherwise, check shipping and payment data
            checkoutStep = getValidatedCheckoutStep(cart, 'order-summary');
        }
        if (checkoutStep) {
            router.push(`${CHECKOUT}?${CHECKOUT_STEP_PARAM}=${checkoutStep}`);
        }
    }, [loadingPrescription, isCartLoading]);
    useEffect(() => {
        // Handle initial prescription
        if (!prescription && !isCartLoading) {
            getPrescriptionFromIDB().then((prescription) => {
                if (prescription?.cartId === cart?.cartId) {
                    setPrescription(prescription);
                }
                else {
                    deletePrescription();
                }
            }).finally(() => {
                setLoadingPrescription(false);
            });
        }
    }, [isCartLoading]);
    return (<div className="page-container checkout-page step--order-summary">
      {(loading || isSubmitting) && <Loader />}
      {lastCheckoutError && (<Modal onClose={closeModal} type="failure">
          {/* <p className="text-center">{lastCheckoutError.message}</p> */}
          <p className="text-center">{formatCheckout({ id: 'payment.failed' })}</p>
        </Modal>)}
      {cart?.lineItems && cart.lineItems.length > 0 && (<>
          <CheckoutSteps steps={steps} currentStep={steps[5]} goToNextStep={checkoutStepNavigation}/>
          <h1 className="checkout-heading">{tasticData?.pageTitle}</h1>
        </>)}

      {checkStatus || isValidating || loadingPrescription ? (<div className="flex w-full items-stretch justify-center py-10 px-12">
          <Loader />
        </div>) : cart?.lineItems && cart.lineItems.length > 0 ? (<div className="container--order-summary container-2-col container-2-col--7-5">
          <div className="col col--left">
            {/* <OrderDetails packageDescripton={tasticData?.packageDescription} cart={cart} /> */}
            <ItemList shippingSettings={shippingSettings} packageDescription={tasticData?.packageDescription} cart={cart} editItemQuantity={updateItem} removeItem={(lineItemId) => removeItem(lineItemId)} isCartPage={false}/>
          </div>
          <div className="col col--right flex flex-col gap-10">
            <DetailsSummary cart={cart}/>
            <div className="rounded-box-container bg-green-light-50 flex items-center justify-between">
              <h3>
                {cart?.payments &&
                formatCheckout({ id: `paymentMethod.${cart?.payments?.at(-1)?.paymentMethod}`, defaultMessage: '' })}
              </h3>
              {getPaymentImage()}
            </div>
            <button className="btn btn-primary w-full" data-cy="orderOverviewSubmit" type="button" disabled={!watchAgreement} onClick={submitOrder}>
              {formatMessage({ id: 'submit.order' })}
            </button>
            <div className='flex'>
              <Checkbox containerClassName='!items-start' label='' name={'agreement'} formRegister={register}/>
              <p className="text-xs lg:text-xl" dangerouslySetInnerHTML={{ __html: tasticData?.termsAndConditionsConfirmationText }}/>
            </div>
          </div>
        </div>) : (<EmptyCart />)}
    </div>);
};
export default OrderSummary;
