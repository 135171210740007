import classNames from 'classnames';
import React from 'react';
const CheckoutSteps = ({ steps, currentStep, goToNextStep }) => {
    const onClickStepLabel = (e) => {
        const stepTarget = Number(e.currentTarget.id);
        if (currentStep.number > stepTarget && !steps[stepTarget].notClickableStep) {
            goToNextStep(stepTarget);
        }
    };
    const renderStep = (step) => {
        return (<div className={classNames('step-status', {
                'current': step.number === currentStep.number,
                'passed': step.number < currentStep.number,
                // TODO: Ryan Check
                // 'init': step.number > currentStep.number && step.number !== steps.length - 1,
                // 'current passed XXX checkout-step-validated': currentStep.number >= step.number && step.number !== steps.length - 1 && !step?.lastStep,
                // 'checkout-step-validated--last-step': currentStep.number >= step.number && step.number !== steps.length - 1 && step?.lastStep,
                // 'last-step': step.number === steps.length - 1,
                'unclickable': step.notClickableStep
            })}>
        <button className='step-icon' type='button' id={`${step.number}`} disabled={step.number >= currentStep.number} onClick={onClickStepLabel}/>
        <button className='step-label' type="button" id={`${step.number}`} disabled={step.number >= currentStep.number} onClick={onClickStepLabel}>
          {step.label}
        </button>
      </div>);
    };
    return (<div className="progress-bar">
      {steps.map((step) => {
            if (!step?.label) {
                return null;
            }
            return (<React.Fragment key={step.id}>
            {renderStep(step)}
          </React.Fragment>);
        })}
    </div>);
};
export default CheckoutSteps;
