import router from "next/router";
import { getReferenceTarget } from "helpers/reference";
import Image from "frontastic/lib/image";
const HomeBanner = ({ homepageBanner, bannerTitle, bannerSubTitle, bannerCTALabel, bannerCTALink, CTA }) => {
    const handleOnClick = () => {
        if (CTA) {
            CTA();
        }
        else if (bannerCTALink) {
            router.push(getReferenceTarget(bannerCTALink));
        }
    };
    return (<section className="homepage_banner lg:gap-space-col-lg container flex flex-col-reverse lg:grid lg:grid-cols-2">
      <div className="flex h-full w-full flex-col justify-center">
        <h1>{bannerTitle}</h1>
        <p className="mt-2.5 whitespace-pre-line font-light">{bannerSubTitle}</p>
        <button data-cy='getStartedButton' onClick={handleOnClick} className="btn btn-primary mt-15 w-full lg:w-[298px]">
          {bannerCTALabel}
        </button>
      </div>
      <div className="relative order-1 flex h-full w-full items-center justify-center pb-20 lg:pb-0">
        <div className="w-full max-w-[600px]">
          <Image media={homepageBanner?.media} alt="" loading={'eager'} priority/>
        </div>
      </div>
    </section>);
};
export default HomeBanner;
