import React from 'react'
import AuthFrontastic from 'components/auth-wrapper'
import ThankYou from 'components/commercetools-ui/thank-you'

const ThankYouTastic = () => {
  return (
    <AuthFrontastic>
      <ThankYou />
    </AuthFrontastic>
  )
}

export default ThankYouTastic
