/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useFormat } from 'helpers/hooks/useFormat';
import { getTaxedCountries } from 'helpers/utils/getTaxedCountries';
import { ShippingOptions } from 'types/checkout';
import Image from 'frontastic/lib/image';
import { useCart, useTax } from 'frontastic/provider';
import BillingAddress from './billing-address';
import DeliveryAddress from './delivery-address';
import { COUNTRY_CODE } from 'helpers/constants/envVariables';
const Address = ({ pageTitle, homeAddressTitle, dhlPackstationTitle, dhlPackstationCTA, shippingData, onSubmit, }) => {
    if (!shippingData) {
        return null;
    }
    const [projectSettings, setProjectSettings] = useState(null);
    const [shippingMethods, setShippingMethods] = useState(null);
    const [availableCountryOptions, setAvailableCountryOptions] = useState(null);
    const [taxRegions, setTaxRegions] = useState([]);
    const { getProjectSettings, getShippingMethods } = useCart();
    const { getTaxRates } = useTax();
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const [shippingOption, setShippingOption] = useState(shippingData?.addressId);
    const shippingAddressForm = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: shippingData,
    });
    const { handleSubmit, watch, formState } = shippingAddressForm;
    const { isDirty } = formState;
    const watchSameAsDeliveryAddress = watch('isBillingSameAsShipping');
    const retrieveTaxRates = () => {
        getTaxRates().then((data) => {
            const states = [];
            data.forEach((taxRate) => {
                if (taxRate.country === 'ES' && taxRate?.state && !states.includes(taxRate?.state)) {
                    states.push(taxRate.state);
                }
            });
            setTaxRegions(states);
        });
    };
    useEffect(() => {
        getShippingMethods().then((data) => {
            setShippingMethods(data);
        });
        getProjectSettings().then((data) => {
            setProjectSettings(data);
        });
        if (COUNTRY_CODE === 'ES') {
            retrieveTaxRates();
        }
    }, []);
    useEffect(() => {
        if (shippingMethods && projectSettings) {
            const totalCountries = getTaxedCountries(shippingMethods, projectSettings?.countries);
            setAvailableCountryOptions(totalCountries);
        }
    }, [shippingMethods, projectSettings]);
    useEffect(() => { }, [watchSameAsDeliveryAddress]);
    const onSubmitWrapper = (data) => {
        if (data.isDefaultShippingAddress && shippingOption === ShippingOptions.DHL_ADDRESS) {
            data.isDefaultShippingAddress = false;
        }
        const isShippingOptionChanged = data.addressId !== shippingOption;
        data.addressId = shippingOption;
        onSubmit(data, isDirty || isShippingOptionChanged);
    };
    const onSelectShippingOption = (e) => {
        const { id } = e.currentTarget;
        // Disable billing address same as delivery on DHL packstation
        if (id === ShippingOptions.DHL_ADDRESS) {
            // shippingAddressForm.setValue('isBillingSameAsShipping', false)
        }
        setShippingOption(id);
    };
    return (<section aria-labelledby="cart-heading">
      <h1 className="checkout-heading">{pageTitle}</h1>
      <div className="shipping-selector">
        <button id={ShippingOptions.HOME_ADDRESS} type="button" className={classNames('shipping-option', {
            'shipping-option--active': shippingOption === ShippingOptions.HOME_ADDRESS,
            'shipping-option--inactive': shippingOption !== ShippingOptions.HOME_ADDRESS,
        })} onClick={onSelectShippingOption}>
          <div className="icon-wrapper">
            <Image src="/icons/custom/shipping-my-address.svg" alt="my address" loading="lazy"/>
          </div>
          <h4>{homeAddressTitle}</h4>
        </button>
        <button id={ShippingOptions.DHL_ADDRESS} type="button" className={classNames('shipping-option', {
            'shipping-option--active': shippingOption === ShippingOptions.DHL_ADDRESS,
            'shipping-option--inactive': shippingOption !== ShippingOptions.DHL_ADDRESS,
        })} onClick={onSelectShippingOption}>
          <div className="icon-wrapper">
            <Image src="/icons/custom/shipping-pack-station.svg" alt="my address" loading="lazy"/>
          </div>
          <h4>{dhlPackstationTitle}</h4>
        </button>
      </div>
      <form className="container--shipping grid grid-cols-1 gap-y-10 lg:grid-cols-2 lg:gap-x-6" onSubmit={handleSubmit(onSubmitWrapper)}>
        <DeliveryAddress shippingAddressForm={shippingAddressForm} availableCountries={availableCountryOptions} isHomeAddress={shippingOption === ShippingOptions.HOME_ADDRESS} dhlPackstationCTA={dhlPackstationCTA?.[0]} taxRegions={taxRegions}/>
        <BillingAddress shippingAddressForm={shippingAddressForm} availableCountries={availableCountryOptions} isHomeAddress={shippingOption === ShippingOptions.HOME_ADDRESS} disabled={watchSameAsDeliveryAddress && shippingOption === ShippingOptions.HOME_ADDRESS}/>
        <button data-cy="shippingDetailsSubmit" type="submit" className="btn btn-primary ml-auto w-full lg:col-start-2 lg:w-fit">
          {formatCommonMessage({ id: 'confirm' })}
        </button>
      </form>
    </section>);
};
export default Address;
