import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Auth0Provider } from '@auth0/auth0-react'
import Cart from 'components/commercetools-ui/cart'
import { OktaSettings } from 'helpers/constants/oktaSettings'
import { CartTasticProps } from 'types/cart'
import { useCart } from 'frontastic/provider'
import { CHECKOUT } from 'helpers/constants/statisURLs'

interface Props {
  data: CartTasticProps
}

const CartTastic: React.FC<Props> = ({ data }) => {
  const { data: cartList, removeItem, updateItem } = useCart()
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItem(lineItemId, newQuantity)
  const router = useRouter()

  useEffect(() => {
    router.push(CHECKOUT)
  })

  return null
}

export default CartTastic
