import { useState } from 'react';
import router from 'next/router';
import { toast } from 'react-hot-toast';
import { CHECKOUT } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import useTrack from 'helpers/hooks/useTrack';
import { useCart } from 'frontastic';
import HomeBanner from './banner';
import Guideline from './guideline';
import ProductInformation from './productInformation';
const VARIANT = {
    sku: '1000-1',
};
const Homepage = ({ homepageBanner, bannerTitle, bannerSubTitle, bannerCTALabel, bannerCTALink, productImage, productTitle, productSubTitle, productCTALabel, productTextUnderneath, showGuideline, guidelineTitle, guidelineSteps, }) => {
    /**
     * TODO: @Ryan @Phi refactor tailwinds for these below components when talwinds config is done
     */
    const { data: cart, isValidating: isCartLoading } = useCart();
    const { formatMessage } = useFormat({ name: 'error' });
    const [loading, setLoading] = useState(false);
    const { trackAddToCart } = useTrack();
    const { addItem } = useCart();
    const handleAddToCart = async () => {
        setLoading(true);
        try {
            if (cart?.lineItems?.length === 0) {
                await addItem(VARIANT, 1);
            }
            trackAddToCart();
            router.push(CHECKOUT);
        }
        catch (error) {
            setLoading(false);
            toast.error(formatMessage({ id: 'wentWrong' }));
        }
    };
    return (<>
      {/* Banner */}
      <HomeBanner CTA={handleAddToCart} homepageBanner={homepageBanner} bannerTitle={bannerTitle} bannerSubTitle={bannerSubTitle} bannerCTALabel={bannerCTALabel} bannerCTALink={bannerCTALink}/>
      {/* Product information */}
      <ProductInformation CTA={handleAddToCart} productTextUnderneath={productTextUnderneath} productImage={productImage} productTitle={productTitle} productSubTitle={productSubTitle} productCTALabel={productCTALabel}/>
      {/* How to use */}
      {showGuideline && <Guideline guidelineTitle={guidelineTitle} guidelineSteps={guidelineSteps}/>}
    </>);
};
export default Homepage;
