import React from 'react';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import { getSubtotalPrice, getTotalDiscounts } from 'helpers/priceHelpers';
import Price from '../price';
const DetailsSummary = ({ cart, showQuantity = false, isCart = true }) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    return (<div className='section--order-summary' aria-labelledby="summary-heading">
      <div className='mb-2 flex items-center justify-between'>
        <h2 className={classNames({
            "text-xl lg:text-2xl": showQuantity
        })}>
          {formatCartMessage({ id: 'your.order.overview', defaultMessage: 'Your order overview' })}
        </h2>
        {showQuantity && (<div className="quantity-wrapper">
              <span>{formatCartMessage({ id: 'unit.total', defaultMessage: 'Unit' })}:</span>
              <span>{cart?.lineItems?.[0]?.count}</span>
            </div>)}
      </div>
      <div className="summary-line !border-0">
        <span>{formatCartMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</span>
        <Price price={{
            centAmount: getSubtotalPrice(cart),
            currencyCode: cart?.sum?.currencyCode,
        }} className="text-gray--dark"/>
      </div>
      {/* {!isCart && cart?.lineItems && cart?.lineItems?.[0]?.discounts?.length > 0 && (
          <div className="summary-line">
            <span>{formatCartMessage({ id: 'cart.discount', defaultMessage: 'Discounts' })}</span>
            <div className="flex items-center">
              <p className='text-primary'>-</p>
              &nbsp;
              <Price
                price={
                  {
                    centAmount: cart?.lineItems?.[0]?.discounts?.[0]?.discountedAmount?.centAmount || 0,
                    currencyCode: cart?.sum?.currencyCode,
                    fractionDigits: cart?.sum?.fractionDigits,
                  } || {}
                }
                className="text-primary"
              />
            </div>
          </div>
        )}
        {!isCart && cart?.lineItems[0]?.discountedPricePerQuantity && (
          <div className="summary-line">
            <span>{formatCartMessage({ id: 'cart.discount', defaultMessage: 'Discounts' })}</span>
            <div className="flex items-center">
              <p className='text-primary'>-</p>
              &nbsp;
              <Price
                price={
                  {
                    centAmount: cart?.lineItems[0]?.discountedPricePerQuantity?.[0]?.discountedPrice?.includedDiscounts?.[0]?.discountedAmount?.centAmount || 0,
                    currencyCode: cart?.lineItems[0]?.totalPrice?.currencyCode,
                  } || {}
                }
                className="text-primary"
              />
            </div>
          </div>
        )} */}
      <div className="summary-line">
        <span>{formatCartMessage({ id: 'delivery.estimate', defaultMessage: 'Delivery' })}</span>
        {!!cart?.shippingInfo?.price?.centAmount ? (<Price price={cart?.shippingInfo?.price || {}} className="text-gray-dark"/>) : (<span>{formatCartMessage({ id: 'freeShipping' })}</span>)}
      </div>
      {/* {cart?.taxed?.taxPortions[0]?.amount && (
          <div className="summary-line">
            <span>
              {formatCartMessage({ id: 'vat' })}
            </span>
            <Price
              price={cart?.taxed?.taxPortions[0]?.amount || {}}
              className="text-gray-dark"
            />
          </div>
        )} */}
      {(!!cart?.directDiscountCodes?.length || !!cart?.lineItems?.length && !!cart?.lineItems?.[0].discounts?.length) && (<div className="summary-line">
          <span>{formatCartMessage({ id: 'cart.discount', defaultMessage: 'Discounts' })}</span>
          <div className="flex items-center">
            <span className='text-primary'>-</span>
            &nbsp;
            <Price price={{
                centAmount: getTotalDiscounts(cart) || 0,
                currencyCode: cart?.sum?.currencyCode,
                fractionDigits: cart?.sum?.fractionDigits,
            } || {}} className="text-primary"/>
          </div>
        </div>)}

      <div className="summary-line !border-t-2 !pb-0">
        <h3 className="font-bold">
          {formatCartMessage({ id: 'total', defaultMessage: 'Total' })}
          &nbsp;
          {`(${formatCartMessage({ id: 'inclVat' })})`}
        </h3>
        <Price price={{
            centAmount: cart?.sum?.centAmount,
            currencyCode: cart?.sum?.currencyCode,
            fractionDigits: cart?.sum?.fractionDigits,
        } || {}} className="text-green-dark text-xl font-bold lg:text-4xl"/>
      </div>
    </div>);
};
export default DetailsSummary;
