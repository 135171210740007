import { useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { updateURLParams } from 'helpers/utils/updateURLParams';
import PriceFilterDisclosure from './PriceFilterDisclosure';
import SizeFilterDisclosure from './SizeFilterDisclosure';
import SortingDisclosure from './SortingDisclosure';
const Filters = ({ facets, products }) => {
    const router = useRouter();
    const { formatMessage } = useFormat({ name: 'product' });
    const [priceFilteringParams, setPriceFilteringParams] = useState([]);
    const [sortingParam, setSortingParam] = useState();
    const [sizeFilteringParams, setSizeFilteringParams] = useState([]);
    const updatePriceFilteringParams = (params) => {
        setPriceFilteringParams(params);
    };
    const updateSortingParams = (param) => {
        setSortingParam(param);
    };
    const updateSizeFilteringParams = (params) => {
        setSizeFilteringParams(params);
    };
    const handleFiltersSubmit = (e) => {
        e.preventDefault();
        const params = [
            {
                key: 'cursor',
                value: 'offset:0',
            },
        ];
        if (priceFilteringParams) {
            params.push(...priceFilteringParams);
        }
        if (sortingParam) {
            params.push(sortingParam);
        }
        if (sizeFilteringParams) {
            params.push(...sizeFilteringParams);
        }
        const currentURL = updateURLParams(params);
        router.push(currentURL);
    };
    return (<form onSubmit={handleFiltersSubmit} className="mb-5">
      <SortingDisclosure updateSortingParams={updateSortingParams}/>
      <PriceFilterDisclosure products={products} facets={facets} updatePriceFilteringParams={updatePriceFilteringParams}/>
      <SizeFilterDisclosure products={products} facets={facets} updateSizeFilteringParams={updateSizeFilteringParams}/>
      <div className="mt-8 flex justify-between gap-3">
        <NextLink href={router?.asPath.split('?')[0] || ''}>
          <a className="w-full rounded border border-accent-400 py-2.5 text-center text-accent-400">
            {formatMessage({ id: 'clear', defaultMessage: 'Clear' })}
          </a>
        </NextLink>

        <button type="submit" className="w-full rounded bg-accent-400 py-2.5 text-white hover:bg-accent-500">
          {formatMessage({ id: 'applyFilters', defaultMessage: 'Apply filters' })}
        </button>
      </div>
    </form>);
};
export default Filters;
