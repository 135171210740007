import React, { useState } from 'react';
import router from 'next/router';
import toast from 'react-hot-toast';
import Loader from 'components/commercetools-ui/loader';
import DetailsSummary from 'components/commercetools-ui/order-summary/details-summary';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import VoucherForm from 'components/voucher';
const PaymentSection = ({ pageTitle, paymentMethodOptions, cart, accountId, onSubmit }) => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const [loading, setLoading] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const { setPaymentMethod } = useCart();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const orderSummary = () => {
        onSubmit().then(() => {
            setLoading(true);
            setPaymentMethod(selectedPaymentMethod).then(() => {
                router.push('/order-summary');
            });
        }).catch(() => {
            toast.error(formatErrorMessage({ id: 'went.Wrong' }));
        });
    };
    const onPaymentMethodChange = (e) => {
        if (e.target.value) {
            setDisableSubmitButton(false);
            setSelectedPaymentMethod(e.target.value);
        }
    };
    return (<>
      {loading && <Loader />}
      <h1 className='checkout-heading'>{pageTitle}</h1>
      <div className="container--payment container-2-col container-2-col--7-5">
        <div className='col col--left flex flex-col gap-3 lg:gap-6'>
          {paymentMethodOptions.map((option) => (<div key={option.id} className="checkbox-radio-wrapper rounded-xl p-2.5 lg:p-5 lg:gap-3 bg-green-light-50 flex items-center">
              <input id={option.id} name="paymentMethod" type="radio" defaultChecked={option.default || false} value={option.value} onChange={onPaymentMethodChange}/>
              <label htmlFor={option.id} className='grow'>
                <div className="flex items-center justify-between">
                  <h3>{formatCheckoutMessage({ id: `paymentMethod.${option.value}` })}</h3>
                  {option.image && (<div className="h-full w-auto">
                      <Image src={option.image} alt={formatCheckoutMessage({ id: `paymentMethod.${option.value}` })} className="object-contain" loading="lazy"/>
                    </div>)}
                </div>
              </label>
            </div>))}
        </div>
        <div className='col col--right payment--right-box flex flex-col gap-10'>
          <DetailsSummary cart={cart}/>

          <button className="section--CTA btn btn-primary w-full" data-cy='paymentDetailsSubmit' onClick={orderSummary} disabled={disableSubmitButton}>
            {formatCheckoutMessage({ id: 'proceed.payment' })}
          </button>

          <VoucherForm cart={cart} setLoading={setLoading} accountId={accountId}/>
        </div>
      </div>
    </>);
};
export default PaymentSection;
