import Typography from 'components/commercetools-ui/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
// const Price: React.FC<PriceProps> = ({ price: { currencyCode = 'EUR', ...price }, className }) => {
const Price = ({ price, className }) => {
    const currencyCode = price?.currencyCode || 'EUR';
    return (<div className={className || 'mt-1 text-sm font-medium text-gray-900 dark:text-light-100'} suppressHydrationWarning>
      <Typography>{CurrencyHelpers.formatForCurrency(price)}</Typography>
    </div>);
};
export default Price;
