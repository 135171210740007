import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { ORDER_PLACED, PRESCRIPTION_UPLOADED } from 'helpers/constants/events';
import { HOME } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { getPrescriptionFromIDB } from 'helpers/idbHelpers';
import { isObjectEmpty } from 'helpers/objectHelpers';
import { useCart, useAccount, useVoucherify } from 'frontastic';
import Image from 'frontastic/lib/image';
import { PaymentHandler } from '../../../nerivio-ui/checkout/utils/PaymentHandler';
import Loader from '../loader';
import DetailsSummary from '../order-summary/details-summary';
import ThankYouItem from './thankYouItem';
const ThankYou = () => {
    const { checkout, data, getOrder } = useCart();
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const [orderDetails, setOrderDetails] = useState(null);
    const router = useRouter();
    const [error, setError] = useState();
    const [errorWithOrder, setErrorWithOrder] = useState(null);
    const [processing, setProcessing] = useState(true);
    const { account } = useAccount();
    const { redeemVoucher } = useVoucherify();
    const handleLoading = (enable = true) => {
        setProcessing(enable);
    };
    const handlePurchase = (order) => {
        gtag('event', ORDER_PLACED, order.orderId);
        gtag('event', 'purchase', {
            transaction_id: order.orderId,
            value: order.sum.centAmount / 100,
            tax: (order.taxed?.taxPortions?.[0]?.amount?.centAmount || 0) / 100,
            shipping: 0,
            currency: order.sum.currencyCode,
            coupon: order.custom?.fields?.discount_codes?.[0] || '',
            items: order.lineItems.map((item, index) => {
                return {
                    item_id: item.variant.sku,
                    item_name: item.name,
                    index,
                    price: item.price.centAmount / 100,
                    quantity: item.count,
                };
            })
        });
    };
    const handleOrderError = (error) => {
        // At the moment we only show the error related to prescription upload
        if (error?.code && error.code === 'prescription_upload_failed_after_payment') {
            const errorMessage = formatErrorMessage({ id: `${error?.code}`, defaultMessage: '' });
            setErrorWithOrder(errorMessage);
        }
    };
    useEffect(() => {
        const payment = data?.payments?.find((payment) => PaymentHandler.isPaymentSettled(payment));
        const state = data?.orderState || 'Open';
        if (router.query.orderNumber) {
            getOrder(router.query.orderNumber)
                .then((order) => {
                if (order?.orderId) {
                    setOrderDetails(order);
                }
                else {
                    router.push(HOME);
                }
            })
                .catch(() => {
                router.push(HOME);
            });
        }
        else if (payment) {
            if (state.toLowerCase() === 'open') {
                gtag('event', PRESCRIPTION_UPLOADED, '');
                getPrescriptionFromIDB().then((prescription) => {
                    if (!prescription) {
                        toast.error(formatErrorMessage({ id: 'upload.prescription.error' }));
                        setProcessing(false);
                        return;
                    }
                    checkout(prescription)
                        .then((order) => {
                        if (order?.orderId) {
                            router.push(`/thank-you?orderNumber=${order.orderId}`, null, {
                                shallow: true,
                            });
                            setOrderDetails(order);
                            if (order.custom?.fields?.discount_codes?.length > 0) {
                                redeemVoucher(order.custom.fields.discount_codes[0], account.accountId, order.orderId);
                            }
                            handlePurchase(order);
                        }
                        if (!isObjectEmpty(order?.debug?.error)) {
                            handleOrderError(order?.debug?.error);
                        }
                    })
                        .catch((checkoutError) => {
                        setError(checkoutError);
                    })
                        .finally(() => {
                        setProcessing(false);
                    });
                });
            }
        }
        else {
            router.push(HOME);
        }
    }, []);
    return (<div className="page-container thank-you-page">
      {error ? (<div className="flex w-full items-stretch justify-center pb-10">
          <p>{error.message}</p>
        </div>) : (<>
          {processing && !orderDetails && <Loader />}
          <div className="checkout-heading flex items-center gap-3 lg:gap-5">
            <h1>
              {formatCheckoutMessage({
                id: 'order.confirm',
                defaultMessage: 'Order confirmed',
            })}
            </h1>
            <div className="h-9 w-9 lg:h-12 lg:w-12">
              <Image src={'/icons/custom/success.svg'} alt="Order Confirmed" className="object-contain" loading="lazy"/>
            </div>
          </div>

          <h2 className="mb-10 font-normal" dangerouslySetInnerHTML={{
                __html: formatCheckoutMessage({
                    id: 'order.thanks',
                })
            }}/>

          <h2 className="mb-10 font-normal" dangerouslySetInnerHTML={{
                __html: formatCheckoutMessage({
                    id: 'order.showId',
                    values: {
                        orderId: orderDetails?.orderId ?? ''
                    }
                })
            }}/>
          {errorWithOrder && (<div className="field-item field-input mb-10 flex !w-fit !flex-row items-center gap-x-1 border border-system-error">
              <Image src={'/icons/custom/error.svg'} alt="" className="object-contain" loading="lazy"/>
              <h4 className="font-normal text-system-error">{errorWithOrder}</h4>
            </div>)}
          <div className="container--thank-you container-2-col container-2-col--7-5">
            <div className="col col--left">
              <ThankYouItem orderDetails={orderDetails} handleLoading={handleLoading}/>
            </div>
            <div className="col col--right">
              <DetailsSummary cart={orderDetails} showQuantity isCart={false}/>
              <div className="mt-5 text-right text-xl font-bold text-green-dark underline lg:mt-6">
                <Link href={'/'}>
                  {formatCheckoutMessage({
                id: 'back.to.home',
            })}
                </Link>
              </div>
            </div>
          </div>
        </>)}
    </div>);
};
export default ThankYou;
