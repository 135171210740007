import React from 'react';
function CancellationPolicy({ data }) {
    return (<>
      <div className="mt-10 text-center ">
        <h1 className="text-2xl font-medium text-[#000000] ">{data.cancellationSectionTitle}</h1>

        <hr className="mx-auto h-[3px] w-[103px] rounded border-0 bg-[#74AB7D] dark:bg-[#74AB7D]"/>

        <div className="mt-5 text-left lg:px-32 ">
          {data.Cancellation.map((faqSectionDescription, index) => (<>
              <div className="mb-3 text-2xl font-medium leading-8 text-[#3B3B3B]">
                {faqSectionDescription.faqSectionTitle}
              </div>
              <div className="mb-8 text-base font-normal leading-6 text-[#686868] ">
                {faqSectionDescription.faqSectionDescription}
              </div>
            </>))}
        </div>
      </div>
    </>);
}
export default CancellationPolicy;
