import classNames from "classnames";
import Image from "frontastic/lib/image";
const Guideline = ({ guidelineTitle, guidelineSteps }) => {
    return (<section className="bg-gradient-100 py-24 text-center">
      <h2 className="h1 text-[52px] font-bold text-[#004A45]">{guidelineTitle}</h2>
      <div className="relative mx-auto mt-24 grid w-3/4 grid-cols-3">
        {guidelineSteps.map((step, index) => (<div key={`step-${step.number}`} className={classNames('relative', {
                "step-with-dashed": index !== guidelineSteps.length - 1,
                'pr-16': index === 0,
                'pl-16': index === guidelineSteps.length - 1,
                'px-16': index !== 0 && index !== guidelineSteps.length - 1
            })}>
            <div className="mx-auto flex h-14 w-14 flex-col items-center justify-center rounded-full bg-[#2ECC71] text-white">
              <div>
                <p>{step.number}</p>
                <p>Step</p>
              </div>
            </div>
          </div>))}
      </div>
      <div className="mx-auto mt-7 grid w-3/4 grid-cols-3">
        {guidelineSteps.map((step, index) => (<div key={step.label} className={classNames("flex flex-col gap-y-7", {
                'pr-16': index === 0,
                'pl-16': index === guidelineSteps.length - 1,
                'px-16': index !== 0 && index !== guidelineSteps.length - 1
            })}>
            <div key={index} className="flex flex-col items-center">
              <div className="flex h-52 w-52 items-center justify-center rounded-full bg-white">
                <div className="relative h-24 w-24">
                  <Image loader={(options) => options.src} src={step.image} alt="" layout="fill" objectFit="contain"/>
                </div>
              </div>
              <h3 className="mt-7 text-xl font-bold text-[#004A45]">{step.label}</h3>
              <p className="mt-2.5 text-base text-[#004A45]">{step.description}</p>
            </div>
          </div>))}
      </div>
    </section>);
};
export default Guideline;
