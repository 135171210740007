export const PATTERN_VALIDATE_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PATTERN_VALIDATE_PASSWORD = /^.{8,}$/;
export const PATTERN_VALIDATE_THREE_DIGIT_NUMBER = /^[0-9]{3}$/;
export const PATTERN_VALIDATE_FIVE_DIGIT_NUMBER = /^[0-9]{5}$/;
export const PATTERN_VALIDATE_FOUR_DIGIT_NUMBER = /^[0-9]{4}$/;
export const PATTERN_VALIDATE_SIX_TO_TEN_DIGIT = /^[0-9]{6,10}$/;
export const PATTERN_VALIDATE_UP_TO_50_CHARS = /^.{0,50}$/;
export const PATTERN_VALIDATE_UP_TO_10_CHARS = /^.{0,10}$/;
export const PATTERN_PRODUCT_URL = /\/([^\/]+)\/([a-zA-Z0-9\-]+)-cp([a-zA-Z0-9]+)/;
export const PATTERN_SIZE_SUFFIX_REGEX = /(US|EU|UK)\b/g;
export const PATTERN_URL_GENERAL = /^(http|https):\/\/([a-z0-9-]+\.)+[a-z0-9]+(\/.*)*$/i;
export const PATTERN_PRICE_IN_EUR = /(^-?\d*\,\d*$)|(^-?\d*$)/;
export const PATTERN_COMMA_TO_PERIOD = /,/g;
export const PATTERN_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
export const PATTERN_VALIDATE_CHARACTER_NOT_NUMBER_OR_ALPHABET = /[^a-zA-Z0-9]/g;
export const PATTERN_TRUST_BADGE_CONTAINER_ID = /^trustbadge-container/;
export const PATTERN_VALIDATE_PHONE_NUMBER = /^\d{8,20}$/;
