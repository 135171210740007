import Homepage from "components/homepage"
import { HomepageProps } from "types/home"

interface Props {
  data: HomepageProps
}

const HomepageTastic: React.FC<Props> = ({ data }) => {
  return (
    <div className="fixed-screen-width flex flex-col items-center">
      <Homepage {...data} />
    </div>
  )
}

export default HomepageTastic
