import React from 'react';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
function ReturnPolicy({ data }) {
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className='page-container'>
      <div className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /
        <span className="mx-3 cursor-pointer text-sm font-medium text-[#000]">
          {formatMessage({ id: 'return.refund', defaultMessage: 'Return and Refund Policy' })}
        </span>
      </div>
      <h1 className="page-heading page-heading-margin-bottom">{data.cancellationSectionTitle}</h1>

      <div className="container !p-0">
        {data.Cancellation.map((faqSectionDescription, index) => (<div key={index}>
            <h3>{faqSectionDescription.faqSectionTitle}</h3>
            <p className="mb-8 text-base">
              {faqSectionDescription.faqSectionDescription}
            </p>
          </div>))}
      </div>
    </div>);
}
export default ReturnPolicy;
