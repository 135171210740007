import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Amazon from '../../../public/icons/tailwind-icons/amazon.png';
import Star from '../../../public/icons/tailwind-icons/star.png';
import Image from 'next/image';

function TestimonialTastics({ data }) {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  // return (
  //   <div className="testimonialBody !min-w-[300px] md:-ml-36 lg:mx-auto mx-auto mt-12 md:my-32 text-center sm:w-full lg:w-3/4">
  //     <Slider {...settings}>
  //       <div className="testimonialOuter">
  //         <div className="testimonialBlock mx-5">
  //           <p className="testimonialParagraph mt-5 text-left">
  //             Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really
  //             saves me time and effort. Skilline is exactly what our business has been lacking.
  //           </p>
  //           <div className="testimonialBottom mb-5 mt-2 flex justify-between">
  //             <div className="flex flex-col justify-start gap-1 items-start">
  //               <p className="testimonialNameText">Gloria Rose</p>
  //               <Image src={Amazon} loader={(options) => options.src} alt="Amazon"/>
  //             </div>
  //             <div className="mt-4 flex flex-col justify-start gap-1 items-end">
  //               <Image loader={(options) => options.src} src={Star} alt="" />
  //               <p className='testimoniaReviewCount'>100 reviews</p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div>
  //         <h3>2</h3>
  //       </div>
  //       <div>
  //         <h3>3</h3>
  //       </div>
  //       <div>
  //         <h3>4</h3>
  //       </div>
  //       <div>
  //         <h3>5</h3>
  //       </div>
  //       <div>
  //         <h3>6</h3>
  //       </div>
  //     </Slider>
  //   </div>
  // );

  return null
}

export default TestimonialTastics;
