/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { PATTERN_VALIDATE_EMAIL, PATTERN_VALIDATE_FIVE_DIGIT_NUMBER, PATTERN_VALIDATE_PASSWORD, PATTERN_VALIDATE_PHONE_NUMBER, PATTERN_VALIDATE_SIX_TO_TEN_DIGIT, PATTERN_VALIDATE_THREE_DIGIT_NUMBER, PATTERN_VALIDATE_UP_TO_10_CHARS, PATTERN_VALIDATE_UP_TO_50_CHARS } from './patterns';
const useValidationOption = (validationInputType) => {
    const [inputOptions, setInputOptions] = useState({});
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    if (!validationInputType || validationInputType === 'label-required') {
        return {
            required: null,
            pattern: null
        };
    }
    const requiredRule = {
        required: formatErrorMessage({ id: 'required.field', defaultMessage: 'This field is required' }),
    };
    const requiredRuleForTel = {
        required: formatErrorMessage({ id: 'required.tel', defaultMessage: 'Purchase of medical devices requires a contact number to be associated with it.' }),
    };
    const emailValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_EMAIL,
            message: formatErrorMessage({
                id: 'email.invalid',
                defaultMessage: 'do you know how email looks like?',
            }),
        },
    };
    // const confirmEmailValidateRule: RegisterOptions = {
    //   validate: { isValidConfirmEmail: (confirmEmail) => isValidConfirmEmail(confirmEmail, getValues()[fieldToCompare]) || formatErrorMessage({ id: 'email.duplicated' }) },
    // }
    const passwordValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_PASSWORD,
            message: formatErrorMessage({ id: 'password.strengthen', defaultMessage: 'Password is too weak' }),
        },
    };
    const telValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_PHONE_NUMBER,
            message: formatErrorMessage({ id: 'phone.invalid', defaultMessage: 'Please enter a valid phone number from 8 to 20 digits' }),
        }
    };
    // const confirmPasswordValidateRule: RegisterOptions = {
    //   validate: { isValidConfirmPassword: (confirmPassword) => isValidConfirmPassword(confirmPassword, getValues()[fieldToCompare]) || formatErrorMessage({ id: 'password.noMatch' }) },
    // }
    const postNumberValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_SIX_TO_TEN_DIGIT,
            message: formatErrorMessage({ id: 'phone.invalid', defaultMessage: 'Please enter a valid phone number with 10 digits' }),
        },
    };
    const postalCodeValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_FIVE_DIGIT_NUMBER,
            message: formatErrorMessage({ id: "invalid.postalCode", defaultMessage: 'Please enter a valid postal code' }),
        },
    };
    const maximum50CharsValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_UP_TO_50_CHARS,
            message: formatErrorMessage({ id: "invalid.length", defaultMessage: 'This field only allows up to {number} characters', values: { number: 50 } }),
        },
    };
    const maximum10CharsValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_UP_TO_10_CHARS,
            message: formatErrorMessage({ id: "invalid.length", defaultMessage: 'This field only allows up to {number} characters', values: { number: 10 } }),
        },
    };
    const dhlPostNumberValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_SIX_TO_TEN_DIGIT,
            message: formatErrorMessage({ id: "invalid.length.postNumber", defaultMessage: "The post number must be a 6 to 10 digit number" }),
        },
    };
    const dhlPackstationNumberValidateRule = {
        pattern: {
            value: PATTERN_VALIDATE_THREE_DIGIT_NUMBER,
            message: formatErrorMessage({ id: "invalid.length.numberOfPackStation", defaultMessage: "Packstation number must be 3 digits" }),
        },
    };
    const validationRulesByInputType = {
        email: {
            ...requiredRule,
            ...emailValidateRule,
        },
        // confirmEmail: {
        //   ...requiredRule,
        //   ...emailValidateRule,
        //   ...confirmEmailValidateRule,
        // },
        required: {
            ...requiredRule,
        },
        password: {
            ...requiredRule,
            ...passwordValidateRule,
        },
        // confirmPassword: {
        //   ...requiredRule,
        //   ...passwordValidateRule,
        //   ...confirmPasswordValidateRule,
        // },
        postalCode: {
            ...requiredRule,
            ...postalCodeValidateRule
        },
        postNumber: {
            ...requiredRule,
            ...postNumberValidateRule,
        },
        tel: {
            ...requiredRuleForTel,
            ...telValidateRule
        },
        streetNumber: {
            ...requiredRule,
            ...maximum10CharsValidateRule,
        },
        streetName: {
            ...requiredRule,
            ...maximum50CharsValidateRule,
        },
        city: {
            ...requiredRule,
            ...maximum50CharsValidateRule,
        },
        dhlPostNumber: {
            ...requiredRule,
            ...dhlPostNumberValidateRule
        },
        dhlPackstationNumber: {
            ...requiredRule,
            ...dhlPackstationNumberValidateRule
        }
    };
    // const watchFieldToCompare = watch && fieldToCompare && watch(fieldToCompare)
    // useEffect(() => {
    //   if (validationInputType === 'postalCode' && watchFieldToCompare) {
    //     const currentCountryCode = watchFieldToCompare?.value || watchFieldToCompare
    //     const patternToValidatePostalCode: RegExp = getPostalCodePatternToValidate(currentCountryCode)
    //     const newInputOptions = {
    //       ...formInputOptions,
    //       ...{
    //         ...requiredRule,
    //         ...{
    //           pattern: {
    //             value: patternToValidatePostalCode,
    //             message: formatErrorMessage({ id: 'invalid.postalCode' }),
    //           },
    //         },
    //       },
    //     }
    //     setInputOptions(newInputOptions)
    //   }
    // }, [validationInputType, watchFieldToCompare])
    // useEffect(() => {
    //   const getFormOptions = (): RegisterOptions => {
    //     // when input disabled then return original options without validation options
    //     if (extendValidationOptions?.disabled) {
    //       return formInputOptions
    //     }
    //     return { ...formInputOptions, ...validationRulesByInputType[validationInputType] }
    //   }
    //   const formOptions = getFormOptions()
    //   if (extendValidationOptions?.formUnregister) {
    //     extendValidationOptions.formUnregister(extendValidationOptions?.fieldName)
    //   }
    //   setInputOptions(formOptions)
    // }, [extendValidationOptions?.disabled, extendValidationOptions?.formUnregister])
    return { ...validationRulesByInputType?.[validationInputType] };
};
export default useValidationOption;
