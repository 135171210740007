import React from 'react';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
import classNames from 'classnames';
function PrivacyPolicy({ data }) {
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className='page-container'>
      <div className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /
        <span className="mr-3 ml-3 cursor-pointer text-sm font-medium text-[#000]">
          {formatMessage({ id: 'privacy.policy', defaultMessage: 'Privacy Policy' })}
        </span>
      </div>
      <h1 className="page-heading">
        {/* Privacy Policy */}
        {data.cancellationSectionTitle}
      </h1>

      <div className="container !p-0">
        {data.Cancellation.map((faqSectionDescription, index) => {
            return (<div key={`policy-section-${index}`} className={classNames('page-heading-margin-bottom', {
                    'text-center': index === 0,
                    'text-left': index !== 0
                })}>
              <h3>{faqSectionDescription.faqSectionTitle}</h3>
              {index !== 0 && (<p>
                  {faqSectionDescription.faqSectionDescription}
                </p>)}
            </div>);
        })}
      </div>
    </div>);
}
export default PrivacyPolicy;
