import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader';
import { STATIC_ORDER_RETURN, STATIC_ORDER_RETURN_CITIES, STATIC_ORDER_RETURN_REASONS, STATIC_ORDER_RETURN_STORES, } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import axios from 'axios';
import { BEARERTOKEN_BASE_URL, BEARERTOKEN_PASSWORD, BEARERTOKEN_USERNAME } from 'helpers/constants/envVariables';
import { backendAPIClient, commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import Modal from 'components/commercetools-ui/modal';
function ReturnOrReplace() {
    const [order, setAccountOrders] = useState();
    const [loading, setLoading] = useState(true);
    const [isRefund, setIsRefund] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const [returnReasons, setReturnReasons] = useState(null);
    const [returnCities, setReturnCities] = useState(null);
    const [returnStores, setReturnStores] = useState(null);
    const [returnReasonSelected, setReturnReasonSelected] = useState(null);
    const [returnCitySelected, setReturnCitySelected] = useState(null);
    const [returnStoreSelected, setReturnStoreSelected] = useState(null);
    const router = useRouter();
    const orderId = router?.query?.['orderId'];
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { orderHistory } = useCart();
    useEffect(() => {
        if (orderHistory && orderId) {
            orderHistory().then((data) => {
                setAccountOrders(data.filter((el) => el.orderId == orderId)[0]);
                // setLoading(false);
            });
        }
    }, [orderHistory, orderId]);
    useEffect(() => {
        if (order?.custom?.fields?.['product-serial-number']) {
            setSerialNumber(order?.custom?.fields?.['product-serial-number']);
        }
    }, [order]);
    const handleReturnRequest = async (e) => {
        // setLoading(true);
        e.preventDefault();
        const reqBody = {
            orderId: order?.cartId,
            orderNumber: order?.orderId,
            orderVersion: order?.orderVersion,
            status: isRefund ? 'return-requested' : 'replace-requested',
            productSerialNumber: serialNumber,
            orderReturnReason: returnReasonSelected,
            orderReturnComments: additionalComments,
            orderReturnDropCity: returnCitySelected,
            orderReturnLocation: returnStoreSelected,
        };
        try {
            const response = await backendAPIClient.post(STATIC_ORDER_RETURN, reqBody);
            if (response.status >= 200 && response.status < 300) {
                const jsonResponse = await response.data;
                setShowModal(true);
                console.log('API Response:', jsonResponse);
            }
            else {
                console.log('API Error:', response.status);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    const getApi = async () => {
        try {
            const responseReasons = await commercetoolsAPIClient.get(STATIC_ORDER_RETURN_REASONS, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const responseCity = await commercetoolsAPIClient.get(STATIC_ORDER_RETURN_CITIES, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (responseCity) {
                const returnCityData = responseCity.data;
                const returnCities = returnCityData?.results[0]?.value['en-GB'];
                setReturnCities(returnCities);
                // setReturnCitySelected(returnCities[Object.keys(returnCities)[0]]);
                setLoading(false);
            }
            if (responseReasons) {
                const returnReasonsData = responseReasons.data;
                const returnReasons = router.locale === 'de'
                    ? returnReasonsData?.results[0]?.value['de-DE']
                    : returnReasonsData?.results[0]?.value['en-GB'];
                setReturnReasons(returnReasons);
                setReturnReasonSelected(returnReasons[Object.keys(returnReasons)[0]].type);
                setLoading(false);
            }
        }
        catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };
    const getStoreLocations = async () => {
        try {
            const responseStores = await commercetoolsAPIClient.get(STATIC_ORDER_RETURN_STORES + `"store-${returnCitySelected}"`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (responseStores) {
                const returnStoresData = responseStores.data;
                const returnStores = returnStoresData?.results;
                setReturnStores(returnStores);
                setLoading(false);
            }
        }
        catch (error) {
            setLoading(false);
        }
    };
    const getAccessToken = () => {
        axios
            .post(BEARERTOKEN_BASE_URL, {}, {
            auth: {
                username: BEARERTOKEN_USERNAME,
                password: BEARERTOKEN_PASSWORD,
            },
        })
            .then((response) => {
            setAccessToken(response.data['access_token']);
        });
    };
    useEffect(() => {
        getAccessToken();
    }, []);
    useEffect(() => {
        if (accessToken) {
            getApi();
        }
    }, [accessToken]);
    useEffect(() => {
        if (returnCitySelected) {
            getStoreLocations();
        }
    }, [returnCitySelected]);
    return (<>
      {loading && <Loader />}
      {showModal && (<Modal>
          <p className="text-center">
            We have placed your {isRefund ? 'Return' : 'Replacement'} request successfully and expect you to drop the
            product on the selected location within 48 hours
          </p>
        </Modal>)}
      <main className="mx-auto max-w-2xl px-2 pb-24 sm:px-4 sm:pt-20 md:px-8 md:pt-8 lg:max-w-7xl">
        <div className="breadcrumb">
          <Link href="/">
            <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
              {formatMessage({ id: 'home', defaultMessage: 'Home' })}
            </span>
          </Link>
          /{' '}
          <Link href="/account#orders">
            <span className="mr-3 ml-2 cursor-pointer text-sm font-light text-gray-400">
              {formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' })}
            </span>
          </Link>
          /{' '}
          <span className="mr-3 ml-2 cursor-pointer text-sm font-medium text-[#000]">
            {formatProductMessage({ id: 'return.refund', defaultMessage: 'Return or Replace' })}
          </span>
        </div>
        <h1 className="cancel-order-title">
          {' '}
          {formatProductMessage({ id: 'return.refund', defaultMessage: 'Return or Replace' })}
        </h1>
        <div className="flex flex-col justify-center gap-14 md:flex-row md:gap-20">
          <div className="mt-4 flex-1 md:mt-0">
            <div className="mb-5 flex flex-col rounded border bg-[#E7EDE8] px-4 py-10 sm:mt-5 md:flex-row" key={order?.orderId}>
              {/* Left side (Image and Title) */}
              {order?.lineItems.map((product) => (<div className="flex flex-row " key={product.productId}>
                  <div className="flex shrink-0">
                    <img src={product.variant.images[0]} alt={product?.name} className="w-16 lg:w-28"/>
                  </div>
                  <div className="ml-5 mt-2">
                    <h2 className="text-lg font-semibold">{product?.name}</h2>
                    <p>
                      <span className="mr-1">{product.count}</span>
                      {formatProductMessage({
                id: 'unit',
                defaultMessage: 'Unit',
            })}
                      <span className="mx-1">|</span>
                      <span>
                        {formatProductMessage({
                id: 'order.date',
                defaultMessage: 'Order Date:',
            })}
                        <span className="ml-1">{order.createdAt.toString().substring(0, 10)} </span>
                      </span>
                    </p>
                  </div>
                </div>))}
            </div>
            <p className="order-cancel-policy">
              {formatMessage({
            id: 'policy.return',
            defaultMessage: 'Please note that you should drop the item at store with in 2 days after successful submission of the return request.',
        })}
            </p>
            <div className="mt-2 flex justify-end">
              <button className="back-to-login-btn">
                <Link href={'/return-policy'}>VIEW RETURN & REPLACEMENT POLICIES</Link>
              </button>
            </div>
          </div>
          <form onSubmit={handleReturnRequest} className="flex-1">
            <div className="mb-6 flex gap-10">
              <span className="flex cursor-pointer items-center justify-center gap-2" onClick={() => setIsRefund(true)}>
                <input type="radio" name="return" checked={isRefund} className="cursor-pointer"/>
                <label className="text-black cursor-pointer text-sm leading-tight" htmlFor="return">
                  <span>{formatMessage({ id: 'return.refund', defaultMessage: 'Return & Refund' })}</span>
                </label>
              </span>
              <span className="flex cursor-pointer items-center justify-center gap-2" onClick={() => setIsRefund(false)}>
                <input type="radio" name="return" checked={!isRefund} className="cursor-pointer"/>
                <label className="text-black cursor-pointer text-sm leading-tight" htmlFor="return">
                  <span>{formatMessage({ id: 'replace', defaultMessage: 'Replace' })}</span>
                </label>
              </span>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <label className="text-black block text-sm" htmlFor="productSerial">
                  <span>{formatMessage({ id: 'product.serial', defaultMessage: 'Product Serial Number' })}</span>{' '}
                  *
                </label>
                <input className=" mt-1 w-full appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow placeholder:text-sm focus:outline-none" id="product-serial" name="productSerial" type="text" placeholder="Product Serial Number" required value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)}/>
              </div>
              <div>
                <label className="text-black text-sm leading-tight" htmlFor="shipping-country">
                  <span>
                    {isRefund
            ? formatMessage({ id: 'reason.return', defaultMessage: 'Reason to Return' })
            : formatMessage({ id: 'reason.replace', defaultMessage: 'Reason to Replace' })}
                  </span>{' '}
                  *
                </label>
                <select className="mt-1 w-full appearance-none rounded border border-gray-300 py-2 px-3 text-sm leading-tight text-gray-600 shadow focus:outline-none" onChange={(e) => setReturnReasonSelected(e.target.value)}>
                  {returnReasons &&
            Object.keys(returnReasons).map((reason, index) => (<option key={index} className="text-sm" value={returnReasons[reason].type}>
                        {formatMessage({ id: reason, defaultMessage: returnReasons[reason].type })}
                      </option>))}
                </select>
              </div>
              <div>
                <label className="text-black block text-sm" htmlFor="productSerial">
                  <span>{formatMessage({ id: 'description', defaultMessage: 'Description  (Optional)' })}</span>
                </label>
                <input className=" mt-1 w-full appearance-none rounded border border-gray-300 py-2 px-3 leading-tight text-gray-700 shadow placeholder:text-sm focus:outline-none" id="description" name="description" type="text" placeholder="Enter Your Message" onChange={(e) => setAdditionalComments(e.target.value)}/>
              </div>
              <div>
                <label className="text-black text-sm leading-tight" htmlFor="shipping-country">
                  <span>{formatMessage({ id: 'city.return', defaultMessage: 'Select Your City' })}</span> *
                </label>
                <select className="mt-1 w-full appearance-none rounded border border-gray-300 py-2 px-3 text-sm leading-tight text-gray-600 shadow focus:outline-none" onChange={(e) => setReturnCitySelected(e.target.value)}>
                  {returnCities && (<>
                      <option hidden value="1">
                        Select city
                      </option>
                      {Object.keys(returnCities).map((city, index) => (<option key={index} className="text-sm" value={returnCities[city]}>
                          {formatMessage({ id: city, defaultMessage: returnCities[city] })}
                        </option>))}
                    </>)}
                </select>
              </div>
              <div>
                <label className="text-black text-sm leading-tight" htmlFor="shipping-country">
                  <span>{formatMessage({ id: 'store.return', defaultMessage: 'Locate a Store' })}</span> *
                </label>
                <select className="mt-1 w-full appearance-none rounded border border-gray-300 py-2 px-3 text-sm leading-tight text-gray-600 shadow focus:outline-none" onChange={(e) => setReturnStoreSelected(e.target.value)}>
                  {returnStores?.length > 0 ? (<>
                      <option hidden value="1">
                        Select Store
                      </option>
                      {returnStores.map((store, index) => (<option key={index} className="text-sm" value={store?.value?.address?.streetName +
                    ', ' +
                    store?.value?.address?.buildingNumber +
                    ', ' +
                    store?.value?.address?.postalCode +
                    ', ' +
                    store?.value?.address?.city}>
                          {formatMessage({
                    id: store?.value?.address?.streetName,
                    defaultMessage: store?.value?.address?.streetName +
                        ', ' +
                        store?.value?.address?.buildingNumber +
                        ', ' +
                        store?.value?.address?.postalCode +
                        ', ' +
                        store?.value?.address?.city,
                })}
                        </option>))}
                    </>) : (<option>No Stores Available</option>)}
                </select>
              </div>
              <button type="submit" className="login-submit mt-3 flex w-full justify-center rounded-sm border border-transparent p-2 text-lg font-bold text-white shadow-sm transition-colors duration-200 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200 ">
                {formatMessage({ id: 'submit', defaultMessage: 'Submit' })}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>);
}
export default ReturnOrReplace;
