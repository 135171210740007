import { openDB } from 'idb';
const DB_KEY = 'nerivio-idb';
const STORE_KEY = 'nerivio-prescription';
const getIDB = () => {
    try {
        return openDB(DB_KEY, 1, {
            upgrade(db) {
                db.createObjectStore(STORE_KEY);
            }
        });
    }
    catch (e) {
        //
    }
};
export const uploadPrescriptionToIDB = async (value) => {
    await (await getIDB()).put(STORE_KEY, value, STORE_KEY);
};
export const getPrescriptionFromIDB = async () => {
    return (await getIDB()).get(STORE_KEY, STORE_KEY).then((data) => {
        if (!data)
            return null;
        const dataSegment = data.split('|');
        return {
            cartId: dataSegment[0],
            name: dataSegment[1],
            content: dataSegment[2]
        };
    });
};
export const deletePrescription = async () => {
    return (await getIDB()).delete(STORE_KEY, STORE_KEY);
};
export const closeIDB = async () => {
    (await getIDB()).close();
};
