import { useEffect } from 'react';
import Checkbox from 'components/form/checkbox';
import InputForm from 'components/form/inputForm';
import PhoneNumberForm from 'components/form/phoneNumberForm';
import { extractCustomerPhoneNumber } from 'helpers/customerInfoHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useCart } from 'frontastic';
const PatientDetails = ({ patientDetailsTitle, orderForSomeoneElseLabel, countryPhoneCodes, prescriptionUploadForm, legalReason, }) => {
    const { account, isValidating: isAccountLoading } = useAccount();
    const { data: cart, isValidating } = useCart();
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { formState, register, watch } = prescriptionUploadForm;
    const watchOrderForSomeoneElse = watch('forSomeone');
    useEffect(() => {
        if (!isValidating && !isAccountLoading) {
            prescriptionUploadForm.setValue('patientFirstName', watchOrderForSomeoneElse
                ? cart?.custom?.fields?.PatientFirstName ?? ''
                : account?.firstName ?? '');
            prescriptionUploadForm.setValue('patientLastName', watchOrderForSomeoneElse
                ? cart?.custom?.fields?.PatientLastName ?? ''
                : account?.lastName ?? '');
            const patientPhone = watchOrderForSomeoneElse
                ? cart?.custom?.fields?.PatientPhone ?? ''
                : account?.custom?.fields?.customerPhone ?? '';
            const [countryCode, phoneNumber] = extractCustomerPhoneNumber(patientPhone);
            prescriptionUploadForm.setValue('patientCountryPhoneCode', countryCode);
            prescriptionUploadForm.setValue('patientPhone', phoneNumber);
        }
    }, [watchOrderForSomeoneElse, isValidating, isAccountLoading]);
    return (<div className="rounded-box-container bg-gray-light-75 h-fit w-full lg:max-w-[500px]">
      <div className="flex items-center justify-between">
        <h2>{patientDetailsTitle}</h2>
      </div>
      <div className="mt-6 flex flex-col gap-3">
        <Checkbox label={orderForSomeoneElseLabel} name="forSomeone" formRegister={register}/>
        <div className="info-wrapper !pl-7 lg:ml-1.5 lg:!pl-8">
          <span>{legalReason}</span>
        </div>
      </div>
      <div className="mt-10 w-full">
        <div className="form-wrapper mt-3">
          <InputForm label={formatCommonMessage({ id: 'Recipient.firstName', defaultMessage: 'First name' })} name={'patientFirstName'} hookForm={prescriptionUploadForm} inputType="text" validationType={'required'}/>
          <InputForm label={formatCommonMessage({ id: 'Recipient.lastName', defaultMessage: 'Last name' })} name={'patientLastName'} hookForm={prescriptionUploadForm} inputType="text" validationType={'required'}/>
          <PhoneNumberForm containerClassName="w-full" label={formatCommonMessage({ id: 'phone', defaultMessage: 'Mobile number' })} name={"patientPhone"} countryPhoneCodeName="patientCountryPhoneCode" hookForm={prescriptionUploadForm} phoneMapping={countryPhoneCodes} validationType='tel'/>
        </div>
      </div>
    </div>);
};
export default PatientDetails;
