import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
const Spacer = ({ customDesktop, customMobile, variant = 'md' }) => {
    //responsiveness
    const [isLargerThanDesktop] = useMediaQuery(screenSizes.desktop);
    //pre defined variants
    const variants = {
        sm: isLargerThanDesktop ? 24 : 16,
        md: isLargerThanDesktop ? 32 : 24,
        lg: isLargerThanDesktop ? 56 : 32,
    };
    //if custom px specified
    const custom = isLargerThanDesktop ? customDesktop : customMobile;
    //applied styles
    const margin = !!custom ? custom : variants[variant];
    return <div style={{ marginTop: `${margin}px` }}/>;
};
export default Spacer;
