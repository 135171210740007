// Doc Examples
import AboutUs from 'components/commercetools-ui/about-us'
import CancelSuccess from 'components/commercetools-ui/cancel-success'
import CancellationPolicy from 'components/commercetools-ui/cancellation-policy'
import ContactUs from 'components/commercetools-ui/contact-us'
import FAQuestions from 'components/commercetools-ui/faq'
import CancelOrder from 'components/commercetools-ui/orders/cancel-order'
import Ewaste from 'components/commercetools-ui/orders/e-waste'
import ReturnOrReplace from 'components/commercetools-ui/orders/return-replace'
import PaymentCancel from 'components/commercetools-ui/payment-cancel'
import PrivacyPolicy from 'components/commercetools-ui/privacy-policy'
import ReturnPolicy from 'components/commercetools-ui/return-policy'
import ShippingDelivery from 'components/commercetools-ui/shipping-delivery'
import TermsOfUse from 'components/commercetools-ui/terms-of-use'
import AccountDetails from './account/details'
import AccountLogin from './account/login'
import LoginOktaTastic from './account/login-okta'
import AccountOrdersHistory from './account/orders'
import AccountRegister from './account/register'
import ResetPassword from './account/reset-password'
import AccountTest from './account/test'
import Cart from './cart'
import Checkout from './checkout'
import ThankYou from './checkout/thank-you'
import BlogTastic from './content/amplience-blog'
import BlogListTastic from './content/amplience-blog-list'
import CategoryTeasers from './content/category-teasers'
import ContentstackBlogTastic from './content/contentstack-blog'
import ContentstackBlogListTastic from './content/contentstack-blog-list'
import Markdown from './content/markdown'
import Spacer from './content/spacer'
import Tile from './content/tile'
import HelloWorld from './doc-examples/hello-world'
import SimpleButton from './doc-examples/simple-button'
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter'
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search'
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies'
//import ContentfulBlog from './doc-examples/contentful/blog';

import FrequentlyAskedQuestions from './Faq'
import Footer from './footer'
import Header from './header'
import Hero from './hero'
import HomepageTastic from './homepage'
import NewsCardTastic from './news-card'
import Newsletter from './newsletter'
import NotFound from './not-found'
import OrderSummaryTastic from './order-summary'
import ProductInformation from './productInformation'
import ProductDetails from './products/details'
import ProductList from './products/product-list'
import SimilarProducts from './products/similar-products'
import ProductSlider from './products/slider'
import Showcase from './showcase'
import StyleGuideTastic from './style-guide'
import TestimonialTastics from './Testimonial'
import Wishlist from './wishlist'

export const tastics = {
  // Doc Examples
  // 'example/simple-button': SimpleButton,
  // 'example/hello-world': HelloWorld,
  // 'example/star-wars/movie': StarWarsOpeningCrawl,
  // 'example/star-wars/character-search': StarWarsCharacterSearch,
  // 'example/star-wars/character-filter': StarWarsCharacterFilter,
  // 'contentful/blog': ContentfulBlog,
  'commercetools/ui/order-summary': OrderSummaryTastic,
  'commercetools/ui/payment-cancel': PaymentCancel,
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/cancel-success': CancelSuccess,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/amplience-blog': BlogTastic,
  'commercetools/ui/content/amplience-blog-list': BlogListTastic,
  'commercetools/ui/content/blog': BlogTastic,
  'commercetools/ui/content/blog-list': BlogListTastic,
  'commercetools/ui/content/contentstack-blog': ContentstackBlogTastic,
  'commercetools/ui/content/contentstack-blog-list': ContentstackBlogListTastic,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,
  'Product-Information': ProductInformation,
  'orders/cancel-order': CancelOrder,
  'orders/return-or-replace': ReturnOrReplace,
  'orders/e-waste': Ewaste,
  'commercetools/ui/cancellation-policy': CancellationPolicy,
  'commercetools/ui/privacy-policy': PrivacyPolicy,
  'commercetools/ui/fa-questions': FAQuestions,
  'commercetools/ui/shipping-delivery': ShippingDelivery,
  'commercetools/ui/terms-of-use': TermsOfUse,
  'commercetools/ui/contact-us': ContactUs,
  'commercetools/ui/about-us': AboutUs,
  'commercetools/ui/return-refund': ReturnPolicy,
  testimonial: TestimonialTastics,
  Faq: FrequentlyAskedQuestions,
  'tastic/homepage': HomepageTastic,
  'tastic/styleguide': StyleGuideTastic,
  default: NotFound,
  'tastic/login-okta': LoginOktaTastic
}
