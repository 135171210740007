import React, { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Menu, Transition } from '@headlessui/react';
import { redirectLogoutUrl, redirectLoginUrl } from 'helpers/auth0Helper';
import { useFormat } from 'helpers/hooks/useFormat';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import Link from 'next/link';
import Loader from '../loader';
const AccountButton = ({ accountLink, account }) => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const clickOnLink = () => {
        setLoading(true);
    };
    return (<>
      {loading && <Loader />}
      <div className="relative flex items-center">
        {account ? (<Menu>
            <div className="relative flex space-x-8">
              <Menu.Button className="flex">
                <span className="sr-only">Account</span>
                <Image src="/icons/custom/user.svg" alt="account" width="40" height="40" loading="lazy"/>
              </Menu.Button>
              {/* <div className="loggedin-user absolute -right-[1px] -bottom-[2px] h-[9px] w-[9px] rounded-md bg-green-700"></div> */}
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="account-flyout dark:bg-primary-400 absolute right-0 top-6 z-50 mt-2 w-fit origin-top-right overflow-hidden rounded-md bg-white shadow-sm ring-1 ring-primary focus:outline-none dark:shadow-3xl">

                <div className="py-0">
                  <Menu.Item as="button">
                    <ReferenceLink target={accountLink} className={`block w-max cursor-pointer py-2 px-4 ${account ? 'text-left' : 'text-center'}  text-primary-400 dark:bg-primary-400 text-sm hover:bg-gray-100  dark:text-light-100`}>
                      {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
                    </ReferenceLink>
                  </Menu.Item>
                  {account && (<Menu.Item as='button' className='w-full'>
                      <Link href={redirectLogoutUrl(router.locale)} locale={false}>
                        <a onClick={clickOnLink} className="dark:bg-primary-400 block w-full cursor-pointer py-2 px-4 text-left text-sm hover:bg-gray-100 hover:underline dark:text-light-100">
                          {formatAccountMessage({ id: 'signout', defaultMessage: 'Logout' })}
                        </a>
                      </Link>
                    </Menu.Item>)}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>) : (<div className="flex space-x-8">
            <div className="flex" onClick={clickOnLink}>
              <Link href={redirectLoginUrl(router?.asPath, router.locale)} locale={false}>
                <span className="text-primary-400 hover:text-primary-500 cursor-pointer dark:text-light-100">
                  <span className="sr-only">Account</span>
                  <Image src="/icons/custom/user.svg" alt="account" width="40" height="40" loading="lazy"/>
                </span>
              </Link>
            </div>
          </div>)}
      </div>
    </>);
};
export default AccountButton;
