import React, { useState } from 'react';
import Link from 'next/link';
import { STATIC_CONTACT_US } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import Loader from '../loader';
import Modal from '../modal';
function ContactUs({ data }) {
    const [formData, setFormData] = useState({
        countryCode: '+1',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    const { formatMessage: formatAccountMessage } = useFormat();
    const [phoneNumberPlaceholder, setPhoneNumberPlaceholder] = useState('(555) 000-0000');
    const [validPhoneNumber, setValidPhoneNumber] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            phoneNumberValidation(value);
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const phoneNumberValidation = (phoneNumber) => {
        const regex = /^\d{10}$/;
        const valid = regex.test(phoneNumber);
        if (valid) {
            setValidPhoneNumber(null);
        }
        else {
            setValidPhoneNumber('Must enter a valid phone number with 10 digits');
        }
    };
    // handle country code change
    const handleCountryCodeChange = (e) => {
        const value = e.target.value;
        switch (value) {
            case '+1':
                setPhoneNumberPlaceholder('(555) 000-0000');
                break;
            case '+44':
                setPhoneNumberPlaceholder(' 7700 900123');
                break;
            case '+92':
                setPhoneNumberPlaceholder(' 300 1234567');
                break;
            case '+86':
                setPhoneNumberPlaceholder(' 139 1234 5678');
                break;
            case '+91':
                setPhoneNumberPlaceholder(' 98765 43210');
                break;
            case '+61':
                setPhoneNumberPlaceholder(' 491 570 156');
                break;
            case '+33':
                setPhoneNumberPlaceholder(' 6 12 34 56 78');
                break;
            case '+49':
                setPhoneNumberPlaceholder(' 1512 3456789');
                break;
            case '+81':
                setPhoneNumberPlaceholder(' 90-1234-5678');
                break;
            // add more cases for other country codes if needed
            default:
                setPhoneNumberPlaceholder(' (555) 000-0000');
                break;
        }
    };
    const validate = () => {
        return !validPhoneNumber;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate data
        if (validate()) {
            setLoading(true);
            try {
                const response = await backendAPIClient.post(STATIC_CONTACT_US, formData);
                if (response.status >= 200 && response.status < 300) {
                    const responseData = await response.data;
                    setLoading(false);
                    setShowModal(true);
                }
                else {
                    setLoading(false);
                    console.error('Form submission failed with status:', response.status);
                }
            }
            catch (error) {
                setLoading(false);
                console.error('An error occurred while submitting the form:', error);
            }
            // Reset the form after submission
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                message: '',
                phone: '',
                countryCode: '+1',
            });
        }
        else {
            // Form validation failed, you can display an error message or take other actions here
            console.error('Form validation failed. Please check your input.');
        }
    };
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className='page-container'>
      {loading && <Loader />}
      {showModal && (<Modal>
          <p className="text-center">
            {formatAccountMessage({
                id: 'query.submitted',
                defaultMessage: 'Thank You For Reaching Out, We shall Respond To Your Query Shortly',
            })}
          </p>
        </Modal>)}
      <div className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /
        <span className="mx-3 cursor-pointer text-sm font-medium text-[#000]">
          {formatMessage({ id: 'contact.us', defaultMessage: 'Contact Us' })}
        </span>
      </div>
      <h1 className="page-heading page-heading-margin-bottom">{data.ContactUsTitle}</h1>

      <div className="md:gap-2-col container !p-0 md:grid md:grid-cols-2">
        <div className="text-left">
          {data.welcome.map((welcome, index) => (<div key={index}>
              <h4 className="">{welcome.welcomeTitle}</h4>
              <div className="mb-8 text-base  leading-6 text-[#686868]">{welcome.welcome}</div>
            </div>))}
          <div className="">
            {data.Reach.map((Reach, index) => (<div key={index}>
                <h4 className="mb-3">{Reach.ReachTitle}</h4>
                <div className="mb-2">
                  <span className="mb-8 text-lg font-normal leading-6 text-[#444444]">
                    {Reach.Reach?.split(':')[0]}:
                  </span>
                  <a className='text-secondary-dark' title="email" href={`mailto:${Reach.Reach?.split(':')[1]}`} target="_self">
                    {Reach.Reach?.split(':')[1]}
                  </a>
                </div>
                <div className="mb-2">
                  <span className="mb-8 text-lg font-normal leading-6 text-[#444444]">
                    {Reach.Technical?.split(':')[0]}:
                  </span>
                  <a className='text-secondary-dark' title="email" href={`mailto:${Reach.Technical?.split(':')[1]}`} target="_self">
                    {Reach.Technical?.split(':')[1]}
                  </a>
                </div>

                <div className="mb-2">
                  <span className="mb-8 text-lg font-normal leading-6 text-[#444444]">
                    {Reach.Order?.split(':')[0]}:
                  </span>
                  <a className='text-secondary-dark' title="email" href={`mailto:${Reach.Order?.split(':')[1]}`} target="_self">
                    {Reach.Order?.split(':')[1]}
                  </a>
                </div>
              </div>))}
          </div>

          <div className="pb-3">
            {data.Phone.map((Phone, index) => (<div key={index}>
                <div className="mb-1 text-base font-medium leading-8 text-[#444444]">
                  {Phone.PhoneTitle}
                  <a className='text-secondary-dark' href={`tel:${Phone.Phone}`} title={Phone.PhoneTitle}>{Phone.Phone}</a>
                </div>
              </div>))}
          </div>

          {data.service.map((service, index) => (<div key={index}>
              <h4 className="mb-3">{service.serviceTitle}</h4>
              <div className="mb-3 text-base font-normal leading-6 text-[#444444] opacity-80">{service.monTofri}</div>
              <div className="mb-8 text-base font-normal leading-6 text-[#444444] opacity-80">{service.satTosun}</div>
            </div>))}

          {data.Address.map((Address, index) => (<div key={index}>
              <h4 className="mb-3">{Address.AddressTitle}</h4>
              <div className="mb-3 text-base font-normal leading-6 text-[#444444] opacity-80">{Address.Address}</div>
            </div>))}
        </div>

        <div className='mt-10 md:mt-0'>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="h-[50px] w-full">
              <input type="text" id="firstName" name="firstName" placeholder="First Name *" value={formData.firstName} onChange={handleChange} className="input-form h-[50px] w-full" required/>
            </div>
            <div>
              <input type="text" id="lastName" name="lastName" placeholder="Last Name *" value={formData.lastName} onChange={handleChange} className="input-form h-[50px] w-full" required/>
            </div>
            <div>
              <input type="email" id="email" name="email" placeholder="E-mail *" value={formData.email} onChange={handleChange} className="input-form h-[50px] w-full" required/>
            </div>

            <div className="col-md-6 relative mb-2 mt-1 sm:mt-0 " data-te-input-wrapper-init>
              <div className=" flex ">
                <select onChange={handleCountryCodeChange} className="h-13 bg-gray-light mt-3 rounded-l-xl !border-[#6b7280] outline-none !ring-transparent">
                  <option value="+1">+1</option>
                  <option value="+44">+44</option>
                  <option value="+92">+92</option>
                  <option value="+86">+86</option>
                  <option value="+91">+91</option>
                  <option value="+61">+61</option>
                  <option value="+33">+33</option>
                  <option value="+49">+49</option>
                  <option value="+81">+81</option>
                </select>
                <fieldset className="h-15 text-gray-dark mb-0 w-full border-[#6b7280] border-l-transparent !rounded-r-xl border pl-2">
                  <legend className="font-bold">Phone</legend>

                  <input id="phone" name="phone" value={formData.phone} autoComplete="phoneNumber" placeholder={formatAccountMessage({
            id: 'phoneNumber.enter',
            defaultMessage: `${phoneNumberPlaceholder}`,
        })} onChange={handleChange} className="w-full border-none outline-none"/>
                </fieldset>
              </div>
              {validPhoneNumber && <p className="text-system-error errorMessage">{validPhoneNumber}</p>}
            </div>

            <div>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} placeholder="Message" className="input-form h-full w-full  p-2 text-left"></textarea>
            </div>
            <div>
              <button type="submit" className="btn btn-primary w-full">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>);
}
export default ContactUs;
