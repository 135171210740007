import { useFormat } from 'helpers/hooks/useFormat';
import { useCustomerName, useShippingAddress } from 'helpers/orderSummary';
import Item from './item';
const ItemList = ({ cart, shippingSettings, editItemQuantity, goToProductPage, removeItem, packageDescription, isCartPage = true, shippingCountryMessage, shippingCountryTooltip }) => {
    //i18n messages
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage } = useFormat({ name: 'common' });
    return (<section className="col col--left" aria-labelledby="cart-heading">
      <h2 id="cart-heading" className="sr-only">
        {formatCartMessage({ id: 'cart.shopping.items' })}
      </h2>

      <ul className="grid md:gap-5" role="list">
        {cart?.lineItems && cart?.lineItems.map((lineItem, i) => (<li key={lineItem?.lineItemId}>
            <Item shippingSettings={shippingSettings} packageDescription={packageDescription} lineItem={lineItem} editItemQuantity={editItemQuantity} goToProductPage={goToProductPage} removeItem={removeItem} isCartPage={isCartPage} shippingCountryMessage={shippingCountryMessage} shippingCountryTooltip={shippingCountryTooltip}/>
          </li>))}
      </ul>
      {!isCartPage && (<div className="mt-5 gap-5 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-10">
            <div className="rounded-box-container flex flex-col gap-3 bg-green-light-50">
              <h3>{formatMessage({ id: 'delivery.address' })}</h3>
              <p className="lg:text-xl">
                {useCustomerName(cart)}
              </p>
              <p className="lg:text-xl">
                {useShippingAddress(cart)}
              </p>
            </div>
            <div className="rounded-box-container mt-5 flex flex-col gap-3 bg-green-light-50 lg:mt-0">
              <h3>{formatMessage({ id: 'billing.address' })}</h3>
              <p className="lg:text-xl">
                {useCustomerName(cart, true)}
              </p>
              <p className="lg:text-xl">
                {useShippingAddress(cart, true)}
              </p>
            </div>
          </div>)}

    </section>);
};
export default ItemList;
