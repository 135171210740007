import React from 'react';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
function AboutUs({ data }) {
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className='page-container'>
      <div className="breadcrumb">
        <Link href="/">
          <span className="mr-3 cursor-pointer text-sm font-light text-gray-400  hover:text-gray-600">
            {formatMessage({ id: 'home', defaultMessage: 'Home' })}
          </span>
        </Link>
        /
        <span className="mr-3 ml-3 cursor-pointer text-sm font-medium text-[#000]">
          {formatMessage({ id: 'about.us', defaultMessage: 'About Us' })}
        </span>
      </div>
      <h1 className="page-heading">{data.aboutUsTitle}</h1>
      <div className="page-heading-margin-bottom text-center">
        <p className="about-us-subheading">{data?.Cancellation[0]?.aboutUsSectionTitle}</p>
        <p className="about-us-text !text-center">{data.Cancellation[0].aboutUsSectionDescription}</p>
      </div>
      <div className="container !p-0">
        <div className="mt-10 flex flex-col-reverse items-center justify-center gap-10 text-left md:flex-row">
          <span className="flex-1">
            <p className="about-us-text">{data.Cancellation[1].aboutUsSectionDescription}</p>
          </span>
          <span className="flex-1">
            <Image loader={({ src, width }) => {
            return src + '?w=' + width;
        }} unoptimized={true} src={'/images/About-us-1.png'} alt="about us image 1" loading="lazy" className='w-full'/>
          </span>
        </div>
        <div className="mt-10 flex flex-col items-center justify-center gap-10 text-left md:flex-row">
          <span className="flex-1">
            <Image loader={({ src, width }) => {
            return src + '?w=' + width;
        }} unoptimized={true} src={'/images/About-us-2.png'} alt="about us image 2" loading="lazy" className='w-full'/>
          </span>
          <span className="w-full flex-1">
            <p className="about-us-title">{data.Cancellation[2].aboutUsSectionTitle}</p>
            <p className="about-us-text">{data.Cancellation[2].aboutUsSectionDescription}</p>
          </span>
        </div>
      </div>
    </div>);
}
export default AboutUs;
